// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interfaces {
  height: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.interfaces:hover {
  background-color: #023154;
}`, "",{"version":3,"sources":["webpack://./views/NetworkPanel/components/Interfaces/Interfaces.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".interfaces {\n  height: 33%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.interfaces:hover {\n  background-color: #023154;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
