// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-status {
  color: #ffffff;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.store-status:hover {
  background-color: #023154;
}

.store-status .general-stores {
  flex: 1;
  padding: 12px;
}

.store-status .general-stores .label-header {
  font-size: 16px;
  letter-spacing: -2.5%;
  font-weight: 300;
}

.store-status .general-stores .devices-wrapper {
  display: flex;
  height: calc(100% - 30px);
  margin: 16px 0;
}

.store-status .general-cd_bo_adm {
  flex: 1;
  padding: 12px;
}

.store-status .general-cd_bo_adm .label-header {
  font-size: 16px;
  letter-spacing: -2.5%;
  font-weight: 300;
}

.store-status .general-cd_bo_adm .devices-wrapper {
  display: flex;
  height: calc(100% - 30px);
  margin: 16px 0;
}
`, "",{"version":3,"sources":["webpack://./views/NetworkPanel/components/StoreCdStatus/StoreCdStatus.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,OAAO;EACP,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,OAAO;EACP,aAAa;AACf;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,OAAO;EACP,aAAa;AACf;;AAEA;EACE,eAAe;EACf,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".store-status {\n  color: #ffffff;\n  flex: 1;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.store-status:hover {\n  background-color: #023154;\n}\n\n.store-status .general-stores {\n  flex: 1;\n  padding: 12px;\n}\n\n.store-status .general-stores .label-header {\n  font-size: 16px;\n  letter-spacing: -2.5%;\n  font-weight: 300;\n}\n\n.store-status .general-stores .devices-wrapper {\n  display: flex;\n  height: calc(100% - 30px);\n  margin: 16px 0;\n}\n\n.store-status .general-cd_bo_adm {\n  flex: 1;\n  padding: 12px;\n}\n\n.store-status .general-cd_bo_adm .label-header {\n  font-size: 16px;\n  letter-spacing: -2.5%;\n  font-weight: 300;\n}\n\n.store-status .general-cd_bo_adm .devices-wrapper {\n  display: flex;\n  height: calc(100% - 30px);\n  margin: 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
