import React, { useState } from 'react';

import CSVHocComponent from 'components/CSVHocComponent/CSVHocComponent';
import CardInfoRedes from 'components/CardInfoRedes/CardInfoRedes';
import EyeOpen from '../../../../img/eyeOpen.svg';
import EyeClose from '../../../../img/eyeClose.svg';

import './Suppliers.css';

function Suppliers({ providerLinksbyCountry, selectedCountries, onSelectSupplier }) {
  const [showOnlyProblems, setShowOnlyProblems] = useState(false);

  function getDataToDownload() {
    const arrayServiceBySupplier = [];
    const arrayProviders = Object.entries(providerLinksbyCountry[selectedCountries[0].country.toUpperCase()]);
    arrayProviders.forEach(([supplier, services], index) => {
      services.forEach((srv) => {
        arrayServiceBySupplier.push({
          provider: supplier,
          hostname: srv.host_name,
          service: srv.description,
          serviceState: srv.state,
          serviceOutput: srv.plugin_output,
          acknowledged: srv.acknowledged,
        });
      });
    });
    return arrayServiceBySupplier;
  }

  function handleClickShowProblems() {
    if (!showOnlyProblems) {
      onSelectSupplier('all');
    } else {
      onSelectSupplier('');
    }
    setShowOnlyProblems(!showOnlyProblems);
  }

  function getSuppliers() {
    const arraySuppliers = [];
    const arrayProviders = Object.entries(providerLinksbyCountry[selectedCountries[0].country.toUpperCase()]);
    arrayProviders.sort(([supplierA], [supplierB]) => supplierA.localeCompare(supplierB));
    arrayProviders.forEach(([supplier, services], index) => {
      const problems = services.filter((service) => service.state !== 0);
      let acknowledged = 0;
      let status = 0;
      problems.forEach((problem) => {
        if (problem.acknowledged) {
          acknowledged += 1;
          if (status < problem.state) {
            status = problem.state;
          }
        } else if (status < problem.state) {
          status = problem.state;
        }
      });

      const services_amount = { sv_total: services.length, pr_total: problems.length, ack_total: acknowledged };
      if (showOnlyProblems) {
        if (services_amount.pr_total) {
          arraySuppliers.push(
            <div key={`supplier-${index}`} className="each-supplier" onClick={() => onSelectSupplier(supplier)}>
              <CardInfoRedes
                status={status}
                name={supplier.toUpperCase()}
                label={'Enlaces Caídos'}
                bodyLabel={'Atendidos / Pendientes'}
                info={`LALALA`}
                totals={services_amount}
                problems={problems}
              />
            </div>
          );
        }
      } else {
        arraySuppliers.push(
          <div key={`supplier-${index}`} className="each-supplier" onClick={() => onSelectSupplier(supplier)}>
            <CardInfoRedes
              status={status}
              name={supplier.toUpperCase()}
              label={'Enlaces Caídos'}
              bodyLabel={'Atendidos / Pendientes'}
              info={`LALALA`}
              totals={services_amount}
              problems={problems}
            />
          </div>
        );
      }
    });
    return arraySuppliers;
  }

  return (
    <CSVHocComponent personalizedClass={'supplier-wrap'} data={getDataToDownload()}>
      <>
        <div className="title-supplier-wrap">
          <div>{'Proveedores'}</div>
          <div className="icon-eye-problems" onClick={handleClickShowProblems}>
            {showOnlyProblems ? <img src={EyeOpen} /> : <img src={EyeClose} />}
          </div>
        </div>
        {getSuppliers()}
      </>
    </CSVHocComponent>
  );
}

export default Suppliers;
