import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import CSVHocComponent from 'components/CSVHocComponent/CSVHocComponent';

import { getStatusColorByNumber, getStatusByNumber } from '../../../../utils/color';
import { dictNames } from '../../utils/database';

import './Interfaces.css';

function Interfaces({ selectedCountries, storeInterfaces }) {
  const [filteredInterfaces, setFilteredInterfaces] = useState({ brand: [], code: [], status: [] });
  const [interfaces, setInterfaces] = useState([]);

  function cleanText(texto) {
    texto = texto
      .replace(/\[|\]/g, '')
      .replace(/Ethernet/g, 'Eth ')
      .replace(/gabit/gi, '');

    if (texto.includes('TRUNK')) {
      let index = texto.indexOf('/');
      if (index !== -1) {
        texto = texto.substring(index + 1);
      }
    }
    return texto.trim();
  }

  useEffect(() => {
    const linksTable = [];
    selectedCountries.forEach((country) => {
      storeInterfaces.sort((a, b) => {
        const aValue = a.data?.errors ?? 0;
        const bValue = b.data?.errors ?? 0;
        return bValue - aValue;
      });
      storeInterfaces.forEach((link) => {
        if (
          link.host.tags.SITE &&
          (link.host.tags.SITE.toLowerCase() === country.country.toLowerCase() ||
            dictNames[link.host.tags.SITE.toLowerCase()] === country.country.toLowerCase())
        ) {
          if (link.host.labels['cmk/device_type'] === 'firewall') {
            const interfaceName = link.description.split(' ');
            link.interfaceName = interfaceName[1];
          } else {
            let [interfaceName] = link.plugin_output.split(',');
            if (interfaceName === 'Item not found in monitoring data') {
              interfaceName = '---';
            }
            link.interfaceName = cleanText(interfaceName);
          }
          linksTable.push(link);
        }
      });
    });
    setInterfaces(linksTable);
  }, [selectedCountries, storeInterfaces]);

  function getDataToDownload() {
    const dataToDownload = [];
    interfaces.forEach((interf) => {
      dataToDownload.push({
        enlace: interf.prov,
        hostname: interf.host_name,
        service: interf.description,
        plugin_output: interf.plugin_output,
        ip: interf.host.address,
        crcPercentage: interf.data?.errors,
      });
    });
    return dataToDownload;
  }

  function getLinksTable() {
    const simpleArrayFilterBrand = [];
    const simpleArrayFilterCode = [];
    const simpleArrayFilterStatus = [];

    filteredInterfaces['code'].forEach((filter) => {
      simpleArrayFilterCode.push(filter.value);
    });
    filteredInterfaces['brand'].forEach((filter) => {
      simpleArrayFilterBrand.push(filter.value);
    });
    filteredInterfaces['status'].forEach((filter) => {
      simpleArrayFilterStatus.push(filter.value);
    });
    const arrayInterfaces = interfaces.filter((flt) => {
      if (
        (simpleArrayFilterBrand.some((filter) => filter === flt.host.tags.TIENDA) || !simpleArrayFilterBrand.length) &&
        (simpleArrayFilterCode.some((filter) => filter === flt.host.tags.CODIGO_DE_TIENDA) ||
          !simpleArrayFilterCode.length) &&
        (simpleArrayFilterStatus.some((filter) => filter === getStatusByNumber(flt.state)) ||
          !simpleArrayFilterStatus.length)
      ) {
        return true;
      }
    });
    const linksTable = [];
    arrayInterfaces.forEach((link, index) => {
      if (link.data?.errors) {
        linksTable.push(
          <tr key={`${index}-item`}>
            <td> {link.host?.tags?.TIENDA}</td>
            <td> {link.host?.tags?.CODIGO_DE_TIENDA}</td>
            <td> {link.host_name}</td>
            <td> {link.interfaceName}</td>
            <td style={{ color: getStatusColorByNumber(link.state) }}> {link.data?.errors?.toFixed(2) || '0.00'}%</td>
          </tr>
        );
      }
    });
    return linksTable;
  }

  function getFilter(filterName) {
    const statePerformanceStores = {
      brand: [],
      code: [],
      status: [],
    };
    interfaces.forEach((filter) => {
      if (!statePerformanceStores['brand'].some((br) => br.value === filter.host.tags.TIENDA)) {
        statePerformanceStores.brand.push({ value: filter.host.tags.TIENDA, label: filter.host.tags.TIENDA });
      }
      if (!statePerformanceStores['code'].some((br) => br.value === filter.host.tags.CODIGO_DE_TIENDA)) {
        statePerformanceStores.code.push({
          value: filter.host.tags.CODIGO_DE_TIENDA,
          label: filter.host.tags.CODIGO_DE_TIENDA,
        });
      }
      if (!statePerformanceStores['status'].some((br) => br.value === getStatusByNumber(filter.state))) {
        statePerformanceStores.status.push({
          value: getStatusByNumber(filter.state),
          label: getStatusByNumber(filter.state),
        });
      }
    });
    return statePerformanceStores[filterName];
  }

  return (
    <CSVHocComponent personalizedClass={'interfaces'} data={getDataToDownload()}>
      <div className="title-active-alerts-wrap">
        <span>Interfaces (Enlaces / Uplink)</span>
        <div className="filters-alert">
          <div className="filter-alert">
            <span className="filter-title">BRAND - UNIDAD DE NEGOCIO</span>
            <div className="filter-input">
              <Select
                options={getFilter('brand')}
                closeMenuOnSelect={false}
                isClearable
                isMulti
                onChange={(info) => {
                  const copyFilter = { ...filteredInterfaces };
                  copyFilter['brand'] = info;
                  setFilteredInterfaces(copyFilter);
                }}
              />
            </div>
          </div>
          <div className="filter-alert">
            <span className="filter-title">STORE - CÓDIGO</span>
            <div className="filter-input">
              <Select
                options={getFilter('code')}
                closeMenuOnSelect={false}
                isClearable
                isMulti
                onChange={(info) => {
                  const copyFilter = { ...filteredInterfaces };
                  copyFilter['code'] = info;
                  setFilteredInterfaces(copyFilter);
                }}
              />
            </div>
          </div>
          <div className="filter-alert">
            <span className="filter-title">ESTADO</span>
            <div className="filter-input">
              <Select
                options={getFilter('status')}
                closeMenuOnSelect={false}
                isClearable
                isMulti
                onChange={(info) => {
                  const copyFilter = { ...filteredInterfaces };
                  copyFilter['status'] = info;
                  setFilteredInterfaces(copyFilter);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="active-problems" style={{ height: '200px' }}>
        <table className="store-problems-table">
          <thead>
            <tr>
              <th style={{ width: '13%' }}>Brand</th>
              <th style={{ width: '13%' }}>Código</th>
              <th style={{ width: '35%' }}>Hostname</th>
              <th style={{ width: '24%' }}>Intf. Name</th>
              <th style={{ width: '15%' }}>CRC</th>
            </tr>
          </thead>
          <tbody>{getLinksTable()}</tbody>
        </table>
      </div>
    </CSVHocComponent>
  );
}

export default Interfaces;
