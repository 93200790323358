import React from 'react';

import './Sidebar.css';
import argentina from '../../img/argentina.svg';
import brasil from '../../img/brasil.svg';
import chile from '../../img/chile.svg';
import colombia from '../../img/colombia.svg';
import peru from '../../img/peru.svg';

import { getStatusColorByNumber } from 'utils/color';

type Props = {
  countryInfo: any;
  setCountryFn: any;
};

const Sidebar: React.FC<Props> = ({ countryInfo, setCountryFn }) => {
  function getFlagImage(country: any) {
    if (country === 'chile') {
      return chile;
    } else if (country === 'argentina') {
      return argentina;
    } else if (country === 'brasil') {
      return brasil;
    } else if (country === 'peru') {
      return peru;
    } else if (country === 'colombia') {
      return colombia;
    } else {
      return chile;
    }
  }

  function getCountry() {
    return countryInfo.map((country: any, index: any) => {
      return (
        <div
          key={`${index}-sidebar`}
          className="country-status"
          onClick={() => {
            setCountryFn(country.site);
          }}
        >
          <img className={country.selected ? 'logo active' : 'logo'} src={getFlagImage(country.country)} />
          <div className="icon-status" style={{ backgroundColor: getStatusColorByNumber(country.generalStatus) }} />
        </div>
      );
    });
  }
  return <section className="sidebar">{getCountry()}</section>;
};

export default Sidebar;
