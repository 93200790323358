import React from 'react';
import EnlaceTooltip from 'components/EnlaceTooltip/EnlaceTooltip';
//@ts-ignore
import { getStatusColorByType, getFlags, getStatusColorByNumber } from '../../utils/color';
//@ts-ignore
import { buildHostUrl } from 'utils/common.js';

import './Enlace.css';

type Props = {
  from: any;
  fromStatus: any;
  to: any;
  toStatus: any;
  status: any;
  label: any;
  problems: any;
  speed: any;
  siteUrl: any;
  siteName: any;
  fromIp: any;
  spd: any,
  usein: any,
  useout: any,
};

const Enlace: React.FC<Props> = ({
  from,
  fromStatus,
  to,
  toStatus,
  status,
  label,
  speed,
  problems,
  siteUrl,
  siteName,
  fromIp,
  spd,
  usein,
  useout
}) => {
  const [fromCountry] = from.split('-');
  const [toCountry] = to.split('-');
  // console.log(fromCountry, toCountry);
  
  const url_from_checkmk = buildHostUrl(from, siteName, siteUrl);
  const url_to_checkmk = buildHostUrl(to, siteName, siteUrl);

  return (
    <section className="enlace-cmpt">
      <div className="line-dashboard">
        <div className="enlace-general-status" style={{ backgroundColor: getStatusColorByNumber(status) }}>
          <span className="tooltiptext">
            <EnlaceTooltip problems={problems} code={label} speed={spd} usein={usein} useout={useout} />
          </span>
        </div>
        <div className="fromCountry">
          <a href={url_from_checkmk} target="_blank" rel="noreferrer">
            <img src={getFlags(fromCountry)} />
          </a>
        </div>
        <div className="toCountry">
          <a href={url_to_checkmk} target="_blank" rel="noreferrer">
            <img src={getFlags(toCountry)} />
          </a>
        </div>
        <div className="statusFromCountry">
          <div className="status">
            <div className="from-general-status" style={{ backgroundColor: getStatusColorByNumber(fromStatus) }}></div>
            <div className="from-general-title">{from}</div>
          </div>
          <div className="ipaddress">{fromIp}</div>
        </div>
        <div className="statusToCountry">
          <div className="status">
            <div className="to-general-status" style={{ backgroundColor: getStatusColorByNumber(toStatus) }}></div>
            <div className="to-general-title">{to}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Enlace;
