import React from 'react';

import './ServiceProblemItem.css';

function ServiceProblemItem({ region, allStores, fallStores, allEnlaces, fallEnlaces, allDevices, fallDevices }) {
  return (
    <section className="service-problem-item-cmpt">
      <div className="info-section">
        <div className="store">{`${region}`}</div>
        <div className="fall-stores">
          <div className="label-spi">{`Tiendas caidas: `}</div>
          <div className="label-status">
            <div
              className="wrapper"
              style={{ backgroundColor: fallStores !== 0 ? '#ED4C5C' : '#10d884' }}
            >{`${fallStores} / ${allStores}`}</div>
          </div>
        </div>
        <div className="fall-stores">
          <div className="label-spi">{`Enlaces caidos: `}</div>
          <div className="label-status">
            <div
              className="wrapper"
              style={{ backgroundColor: fallEnlaces !== 0 ? '#ED4C5C' : '#10d884' }}
            >{`${fallEnlaces} / ${allEnlaces}`}</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ServiceProblemItem;
