import React from 'react';

import DatacenterTableItem from '../DatacenterTableItem/DatacenterTableItem';

import './DatacenterTable.css';

function DatacenterTable({ items, headers = {} }) {
  const defaultHeaders = {
    first: 'DC',
    second: 'PROV',
    third: 'TRONCAL',
    fourth: 'IP HOST',
    fifth: 'SAT',
    sixth: 'CRC',
  };
  let tempHeaders = { ...defaultHeaders, ...headers };
  function getItems() {
    return items.map((item, index) => (
      <DatacenterTableItem
        key={`${index}-item-dc`}
        name={item.name}
        status={item.status}
        ip={item.ip}
        saturation={item.saturation}
        crc={item.crc}
        prov={item.prov}
        type={item.type}
        hostname={item.hostname}
        codigoServicio={item.codigoServicio}
      />
    ));
  }
  return (
    <section className="datacenter-table-cmpt">
      <div className="header">
        <div className="name">{tempHeaders.first}</div>
        <div className="prov">{tempHeaders.second}</div>
        <div className="status">{tempHeaders.third}</div>
        <div className="ip">{tempHeaders.fourth}</div>
        <div className="saturation">{tempHeaders.fifth}</div>
        <div className="crc">{tempHeaders.sixth}</div>
      </div>
      <div className="table">{items && items.length ? getItems() : null}</div>
    </section>
  );
}

export default DatacenterTable;
