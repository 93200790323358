const buildHostUrl = (hostname, siteName, siteUrl) => {
  siteName = 'NREDES_REGIONAL';
  return (
    siteUrl +
    '/' +
    siteName +
    '/check_mk/index.py?start_url=' +
    encodeURIComponent('/' + siteName + '/check_mk/view.py?host=' + hostname + '&site=' + siteName + '&view_name=host')
  );
};

const buildUrlDatacenterDevice = (storeName, deviceType, siteUrl) => {
  const siteName = 'NREDES_REGIONAL';
  return (
    siteUrl[siteName] +
    '/' +
    siteName +
    '/check_mk/index.py?start_url=' +
    encodeURIComponent(
      '/' +
        siteName +
        '/check_mk/view.py?filled_in=filter&_active=wato_folder%3Bhost_tags&wato_folder=&host_tag_0_grp=CODIGO_DE_TIENDA&host_tag_0_op=is&host_tag_0_val=' +
        storeName +
        '&host_tag_1_grp=TIPO_DE_DISPOSITIVO&host_tag_1_op=is&host_tag_1_val=' +
        deviceType +
        '&host_tag_2_grp=TIENDA&host_tag_2_op=is&host_tag_2_val=DATACENTER&view_name=searchhost&_show_filter_form=1'
    )
  );
};

const buildUrlEnlaceCodigoServicio = (host, service, siteUrl) => {
  const siteName = 'NREDES_REGIONAL';
  return (
    siteUrl[siteName] +
    '/' +
    siteName +
    '/check_mk/index.py?start_url=' +
    '/' +
    siteName +
    `/check_mk%2Fview.py%3Ffilled_in%3Dfilter%26_active%3Dserviceregex%253Boutput%253Bservice_labels%253Bwato_folder%253Bhostregex%253Bhost_address%253Bhostalias%253Bhost_labels%253Bhost_tags%253Bsiteopt%26service_regex%3D${service}%26service_output%3D%26service_labels_count%3D1%26service_labels_indexof_1%3D1%26service_labels_1_bool%3Dand%26service_labels_1_vs_count%3D1%26service_labels_1_vs_indexof_1%3D1%26service_labels_1_vs_1_bool%3Dand%26wato_folder%3D%26host_regex%3D${host}%26host_address%3D%26host_address_prefix%3Dyes%26hostalias%3D%26host_labels_count%3D1%26host_labels_indexof_1%3D1%26host_labels_1_bool%3Dand%26host_labels_1_vs_count%3D1%26host_labels_1_vs_indexof_1%3D1%26host_labels_1_vs_1_bool%3Dand%26host_tag_0_op%3Dis%26host_tag_1_op%3Dis%26host_tag_2_op%3Dis%26view_name%3Dsearchsvc%26_show_filter_form%3D1`
  );
};

const buildUrlDatacenterEnlace = (storeName, siteUrl) => {
  const siteName = 'NREDES_REGIONAL';
  return (
    siteUrl[siteName] +
    '/' +
    siteName +
    '/check_mk/index.py?start_url=' +
    encodeURIComponent(
      '/' +
        siteName +
        '/check_mk/view.py?filled_in=filter&&_active=serviceregex%3Bhost_tags&service_regex=OSPF%7CBGP%7CENLACE&host_tag_0_grp=TIENDA&host_tag_0_op=is&host_tag_0_val=DATACENTER&host_tag_1_grp=CODIGO_DE_TIENDA&host_tag_1_op=is&host_tag_1_val=' +
        storeName +
        '&host_tag_2_op=is&view_name=searchsvc&_show_filter_form=0'
    )
  );
};

const buildUrlHostService = (host, service, siteUrl, site) => {
  const siteName = 'NREDES_REGIONAL';
  return (
    siteUrl[siteName] +
    '/' +
    siteName +
    '/check_mk/index.py?start_url=' +
    encodeURIComponent(
      '/' + siteName + '/check_mk/view.py?host=' + host + '&service=' + service + '&site=' + site + '&view_name=service'
    )
  );
};

const buildUrlStore = (store, store_code, siteUrl) => {
  const siteName = 'NREDES_REGIONAL';
  return (
    siteUrl[siteName] +
    '/' +
    siteName +
    '/check_mk/index.py?start_url=' +
    '/' +
    siteName +
    '/check_mk%2Fview.py%3Ffilled_in%3Dfilter%26_active%3Dwato_folder%253Bhostregex%253Bhost_address%253Bhostalias%253Bhost_labels%253Bhost_tags%253Bsiteopt%26wato_folder%3D%26host_address%3D%26host_address_prefix%3Dyes%26' +
    'hostalias%3D%26host_labels_count%3D1%26host_labels_indexof_1%3D1%26host_labels_1_bool%3Dand%26host_labels_1_vs_count%3D1%26host_labels_1_vs_indexof_1%3D1%26' +
    'host_labels_1_vs_1_bool%3Dand%26host_tag_0_grp%3DCODIGO_DE_TIENDA%26host_tag_0_op%3Dis%26host_tag_0_val%3D' +
    store_code +
    '%26host_tag_1_grp%3DTIENDA%26host_tag_1_op%3Dis%26host_tag_1_val%3D' +
    store +
    '%26host_tag_2_op%3Dis%26view_name%3Dsearchhost%26_show_filter_form%3D1'
  );
};

function buildUrlHostProblems(hosts, siteUrl) {
  const siteName = 'NREDES_REGIONAL';
  const hostRegex = hosts.map((host) => host.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')).join('|');

  const startUrlParams = new URLSearchParams({
    filled_in: 'filter',
    _active: 'wato_folder;hostregex;host_address;hostalias;host_labels;host_tags;siteopt',
    wato_folder: '',
    host_regex: hostRegex,
    host_address: '',
    host_address_prefix: 'yes',
    hostalias: '',
    host_labels_count: '1',
    host_labels_indexof_1: '1',
    host_labels_1_bool: 'and',
    host_labels_1_vs_count: '1',
    host_labels_1_vs_indexof_1: '1',
    host_labels_1_vs_1_bool: 'and',
    host_tag_0_op: 'is',
    host_tag_1_op: 'is',
    host_tag_2_op: 'is',
    view_name: 'searchhost',
    _show_filter_form: '1',
  });

  const encodedStartUrl = encodeURIComponent(`/${siteName}/check_mk/view.py?` + startUrlParams.toString());

  const finalUrl = `${siteUrl[siteName]}/${siteName}/check_mk/index.py?start_url=${encodedStartUrl}`;
  return finalUrl;
}

function buildUrlWithHostTags(siteUrl, store, store_code) {
  const siteName = 'NREDES_REGIONAL';
  const hostTagParams = [
    { group: 'TIENDA', value: store },
    { group: 'CODIGO_DE_TIENDA', value: store_code },
  ];
  const startUrlParams = new URLSearchParams({
    filled_in: 'filter',
    _active: 'wato_folder;hostregex;host_address;hostalias;host_labels;host_tags;siteopt',
    wato_folder: '',
    host_address: '',
    host_address_prefix: 'yes',
    hostalias: '',
    host_labels_count: '1',
    host_labels_indexof_1: '1',
    host_labels_1_bool: 'and',
    host_labels_1_vs_count: '1',
    host_labels_1_vs_indexof_1: '1',
    host_labels_1_vs_1_bool: 'and',
    view_name: 'searchhost',
    _show_filter_form: '1',
  });

  hostTagParams.forEach((tag, index) => {
    startUrlParams.set(`host_tag_${index}_grp`, tag.group);
    startUrlParams.set(`host_tag_${index}_op`, 'is');
    startUrlParams.set(`host_tag_${index}_val`, tag.value);
  });

  startUrlParams.set('host_tag_2_op', 'is');

  const encodedStartUrl = encodeURIComponent(`/${siteName}/check_mk/view.py?${startUrlParams.toString()}`);
  const finalUrl = `${siteUrl[siteName]}/${siteName}/check_mk/index.py?start_url=${encodedStartUrl}`;

  return finalUrl;
}

export {
  buildHostUrl,
  buildUrlDatacenterDevice,
  buildUrlDatacenterEnlace,
  buildUrlHostProblems,
  buildUrlWithHostTags,
  buildUrlHostService,
  buildUrlStore,
  buildUrlEnlaceCodigoServicio,
};
