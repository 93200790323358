import React, { useEffect, useState } from 'react';

import CSVHocComponent from 'components/CSVHocComponent/CSVHocComponent';
import CardNetworkDevice from 'components/CardNetworkDevice/CardNetworkDevice';

import { inBOCDADM } from '../../utils/common';
import { getHostGroupsByLabel } from '../../../../api/datasource';
import { dictNames } from '../../utils/database';

import './StoreCdStatus.css';

function StoreCdStatus({ selectedCountries, hosts, storesCd, siteUrl, onSelectGroup }) {
  const [deviceTypesStores, setDeviceTypesStores] = useState([]);
  const [deviceTypesBOCDADM, setDeviceTypesBOCDADM] = useState([]);
  const [downloadStores, setDownloadStores] = useState([]);
  const [downloadCDBOADM, setDownloadCDBOADM] = useState([]);

  useEffect(() => {
    const devicesStores = [];
    const devicesBOCDADM = [];
    selectedCountries.forEach((country) => {
      hosts.forEach((host) => {
        const isInBOCDADM = inBOCDADM(host);
        if (
          (host.tags.SITE.toLowerCase() === country.country.toLowerCase() ||
            dictNames[host.tags.SITE.toLowerCase()] === country.country.toLowerCase()) &&
            host.tags.TIENDA !== 'DATACENTER'
        ) {
          if (isInBOCDADM) {
            devicesBOCDADM.push(host);
          } else {
            devicesStores.push(host);
          }
        }
      });
    });
    const dataToDownloadStores = [];
    const dataToDownloadBOCDADM = [];
    const deviceTypesStores = getHostGroupsByLabel(devicesStores, 'cmk/device_type');
    const deviceTypesBOCDADM = getHostGroupsByLabel(devicesBOCDADM, 'cmk/device_type');
    devicesStores.forEach((dv) => {
      dataToDownloadStores.push({
        name: dv.name,
        state: dv.state,
        device_type: dv.labels && dv.labels['cmk/device_type'] ? dv.labels['cmk/device_type'] : '',
      });
    });
    devicesBOCDADM.forEach((dv) => {
      dataToDownloadBOCDADM.push({
        name: dv.name,
        state: dv.state,
        device_type: dv.labels && dv.labels['cmk/device_type'] ? dv.labels['cmk/device_type'] : '',
      });
    });
    setDeviceTypesStores(deviceTypesStores);
    setDeviceTypesBOCDADM(deviceTypesBOCDADM);
    setDownloadStores(dataToDownloadStores);
    setDownloadCDBOADM(dataToDownloadBOCDADM);
  }, [selectedCountries, hosts, storesCd]);

  function getCardNetworkDevice(deviceTypes, fieldStores, title) {
    const validTypes = ['router', 'switch', 'access_point', 'firewall'];

    const arrayDeviceByType = deviceTypes.filter((deviceType) => {
      return validTypes.some((validType) => deviceType.key.includes(validType));
    });

    const arrayNew = [];

    arrayDeviceByType.forEach((deviceType) => {
      let types = validTypes.find((type) => deviceType.key.includes(type));
      if (arrayNew.some((element) => element.key === types)) {
        let index = arrayNew.findIndex((element) => element.key === types);
        arrayNew[index].hostProblems += deviceType.hostProblems;
        arrayNew[index].hostTotal += deviceType.hostTotal;
        arrayNew[index].problems = [...arrayNew[index].problems, ...deviceType.problems];
        arrayNew[index].serviceProblems += deviceType.serviceProblems;
        arrayNew[index].serviceTotal += deviceType.serviceTotal;
        arrayNew[index].serviceTotalCritical += deviceType.serviceTotalCritical;
        arrayNew[index].serviceTotalWarning += deviceType.serviceTotalWarning;
      } else {
        let newObj = {
          key: types,
          hostProblems: deviceType.hostProblems,
          hostTotal: deviceType.hostTotal,
          problems: deviceType.problems,
          serviceProblems: deviceType.serviceProblems,
          serviceTotal: deviceType.serviceTotal,
          serviceTotalCritical: deviceType.serviceTotalCritical,
          serviceTotalWarning: deviceType.serviceTotalWarning,
        };
        arrayNew.push(newObj);
      }
    });
    arrayNew.sort((a, b) => a.key.localeCompare(b.key));
    const problemsStores = [];

    if (storesCd && storesCd[fieldStores]) {
      storesCd[fieldStores]['problems'].forEach((prSt) => {
        problemsStores.push({ name: prSt.name, codeStore: prSt.code });
      });
      arrayNew.unshift({
        key: title,
        hostProblems: storesCd[fieldStores]['down'],
        hostTotal: storesCd[fieldStores]['down'] + storesCd[fieldStores]['up'],
        problems: problemsStores,
      });
    }
    
    return arrayNew.map((deviceGroup, index) => (
      <CardNetworkDevice
        key={`${index}-device-type`}
        title={deviceGroup.key}
        label={'DOWN / UP'}
        down={deviceGroup.hostProblems}
        up={parseInt(`${deviceGroup.hostTotal - deviceGroup.hostProblems}`, 10)}
        color={deviceGroup.hostProblems === 0 ? '#10D884' : '#ED4C5C'}
        problems={deviceGroup.problems}
        siteUrl={siteUrl}
        onSelectGroup={onSelectGroup}
      />
    ));
  }
  return (
    <div className="store-status">
      <CSVHocComponent personalizedClass={'general-stores'} data={downloadStores}>
        <div className="label-header">{'Estado general de tiendas'}</div>
        <div className="devices-wrapper">{getCardNetworkDevice(deviceTypesStores, 'resumeStores', 'Tiendas')}</div>
      </CSVHocComponent>
      <CSVHocComponent personalizedClass={'general-cd_bo_adm'} data={downloadCDBOADM}>
        <div className="label-header">{'Estado general CD - BO - ADM'}</div>
        <div className="devices-wrapper">{getCardNetworkDevice(deviceTypesBOCDADM, 'resumeCD', 'CD/BO/ADM')}</div>
      </CSVHocComponent>
    </div>
  );
}

export default StoreCdStatus;
