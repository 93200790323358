import React from 'react';
import './Network2.css';
import { getFlags } from '../../utils/color';
import circulo from "../../img/circulo.png";

function CardNetwork({name, location, interfaces, positions, top_bottom}) {

    function getrow1(name, location){
        return (<div className='row1'>
            <div className='flag'>
                <img src={getFlags(location)} />
            </div>
            <div className='card-title'>{name}</div>
        </div>)
    }

    function getStatus(status, top_bottom){
        const statusDivs = [];
        let status_position = top_bottom == "top" ?"bottom": "top"; 
        if(status.length  == 1){
            statusDivs.push(<div className='status' style={{left: "14px", [status_position] : "0px", backgroundColor: status[0].status}}></div>);
        }
        if(status.length === 2 || status.length  == 3){
            statusDivs.push(<div className='status' style={{bottom: "14px", left: "-6px", backgroundColor: status[0].status}}></div>);
            statusDivs.push(<div className='status' style={{bottom: "14px", right: "-6px", backgroundColor: status[1].status}}></div>);
        }
        if(status.length === 3 ){
            statusDivs.push(<div className='status' style={{bottom: "14px", left: "-6px" , backgroundColor: status[0].status}}></div>);
            statusDivs.push(<div className='status' style={{left: "14px", [status_position] : "0px" , backgroundColor: status[1].status}}></div>);
            statusDivs.push(<div className='status' style={{bottom: "14px", right: "-6px", backgroundColor: status[2].status}}></div>);
        }
        return (<div className='statusWrap' style={top_bottom === "top" ?{bottom: "-6px"} : {top: "-6px"} }>{statusDivs}</div>);
    }

    function getInterfaces(interfaces, top_bottom){
        return interfaces.map(i => {
            return (
                <div className='interface'>
                    {top_bottom == "bottom" && <div className='empty'></div>}
                    <div className='interface-label'>
                        {i.name}
                    </div>
                    {top_bottom == "top" && <div className='empty'></div>}
                    <div className='interface-img'  style={top_bottom === "top" ? {bottom:"-20px"} : {top:"-20px"} }>
                        {getStatus(i.status, top_bottom)}
                        { <img src={circulo} style={{width: "40px"}}/> }
                    </div>
                </div>
            )
        })
    }

    return (
        <div className='card-network' style={positions}>
            {top_bottom === "top" && getrow1(name, location)}
            <div className='row2'>
                {getInterfaces(interfaces, top_bottom)}
            </div>
            {top_bottom === "bottom" && getrow1(name, location)}
        </div>
    )
}

export default CardNetwork;