const dictNames = {
  redes_co: 'colombia',
  redes_br: 'brasil',
  redes_pe: 'peru',
  chile: 'chile',
  argentina: 'argentina',
};

const dictComunas = {
  CLANTEA534: 'Antofagasta',
  CLANTEA614: 'Calama',
  CLANTJU534: 'Antofagasta',
  CLANTJU614: 'Calama',
  CLANTJU796: 'Antofagasta',
  CLANTJU817: 'Antofagasta',
  CLANTN797: 'Antofagasta',
  CLANTN798: 'Antofagasta',
  CLANTN820: 'Calama',
  CLANTPA577: 'Calama',
  CLANTPA588: 'Antofagasta',
  CLARIEA619: 'Arica',
  CLARIJU619: 'Arica',
  CLARIN729: 'Arica',
  CLARIN804: 'Arica',
  CLARIN812: 'Arica',
  CLARIPA804: 'Arica',
  CLATAJU760: 'Copiapo',
  CLATAJU844: 'Copiapo',
  CLATAN841: 'Copiapo',
  CLATAN901: 'Vallenar',
  CLATAN902: 'Huasco',
  CLATAPA760: 'Copiapo',
  CLCOQEA521: 'La Serena',
  CLCOQJU521: 'La Serena',
  CLCOQJU656: 'La Serena',
  CLCOQN551: 'Coquimbo',
  CLCOQN648: 'La Serena',
  CLCOQN658: 'Coquimbo',
  CLCOQN790: 'La Serena',
  CLCOQN827: 'La Serena',
  CLCOQN836: 'La Serena',
  CLCOQN845: 'Coquimbo',
  CLCOQN908: 'Ovalle',
  CLCOQN979: 'Ovalle',
  CLCOQNA730: 'La Serena',
  CLCOQPA571: 'La Serena',
  CLCOQPA960: 'Coquimbo',
  CLSGOPA979: 'Ovalle',
  CLARAN517: 'Temuco',
  CLARAN547: 'Temuco',
  CLARAN554: 'Temuco',
  CLARAN597: 'Temuco',
  CLARAN625: 'Padre Las Casas',
  CLARAN732: 'Temuco',
  CLARAN743: 'Temuco',
  CLARAN826: 'Temuco',
  CLARAN978: 'Temuco',
  CLARAN985: 'Victoria',
  CLARAPA506: 'Temuco',
  CLARAPA566: 'Temuco',
  CLSGOEA506: 'Temuco',
  CLSGOJU506: 'Temuco',
  CLTEMEA517: 'Temuco',
  CLTEMJU970: 'Temuco',
  CLARAEU002: 'Temuco',
  CLLRSN684: 'Valdivia',
  CLLRSN744: 'La Union',
  CLRIOEA744: 'La Union',
  CLRIOJU955: 'Valdivia',
  CLRION928: 'Valdivia',
  CLVADPA935: 'Valdivia',
  CLRIOEU020: 'Valdivia',
  CLBIOJU532: 'Chillan',
  CLBIOJU649: 'Chillan',
  CLBION794: 'Chillan',
  CLBION977: 'Chillan',
  CLBIOPA581: 'Chillan',
  CLNUBEA525: 'Chillan',
  CLNUBEU018: 'Chillan',
  CLTARJU660: 'Iquique',
  CLTARN590: 'Iquique',
  CLTARN811: 'Iquique',
  CLTARPA590: 'Iquique',
  CLLAGEA900: 'Los Andes',
  CLQUIEA646: 'Quillota',
  CLQUIJU646: 'Quillota',
  CLVALEA508: 'Viña del Mar',
  CLVALEA520: 'Valparaíso',
  CLVALEA781: 'Quilpué',
  CLVALJU508: 'Viña del Mar',
  CLVALJU520: 'Valparaíso',
  CLVALJU739: 'Concon',
  CLVALJU754: 'San Felipe',
  CLVALJU780: 'Los Andes',
  CLVALJU872: 'Puchuncavi',
  CLVALJU875: 'Viña del Mar',
  CLVALN536: 'Valparaíso',
  CLVALN537: 'Quilpué',
  CLVALN538: 'Quilpué',
  CLVALN539: 'Villa Alemana',
  CLVALN540: 'Quillota',
  CLVALN541: 'Viña del Mar',
  CLVALN544: 'La Calera',
  CLVALN545: 'Viña del Mar',
  CLVALN548: 'Viña del Mar',
  CLVALN552: 'Valparaíso',
  CLVALN561: 'Valparaíso',
  CLVALN572: 'Viña del Mar',
  CLVALN638: 'Villa Alemana',
  CLVALN657: 'Limache',
  CLVALN662: 'Viña del Mar',
  CLVALN664: 'San Felipe',
  CLVALN665: 'Los Andes',
  CLVALN674: 'Viña del Mar',
  CLVALN691: 'Limache',
  CLVALN693: 'Quilpué',
  CLVALN694: 'Concon',
  CLVALN711: 'Valparaíso',
  CLVALN722: 'Valparaíso',
  CLVALN731: 'San Felipe',
  CLVALN753: 'La Ligua',
  CLVALN756: 'San Antonio',
  CLVALN805: 'Valparaíso',
  CLVALN808: 'Viña del Mar',
  CLVALN813: 'Quilpué',
  CLVALN839: 'La Cruz',
  CLVALN840: 'Valparaíso',
  CLVALN842: 'Villa Alemana',
  CLVALN848: 'Quillota',
  CLVALN849: 'Casablanca',
  CLVALN852: 'Los Andes',
  CLVALN911: 'Valparaíso',
  CLVALN993: 'Valparaíso',
  CLVALNA542: 'Viña del Mar',
  CLVALNA543: 'Quilpué',
  CLVALNA549: 'La Calera',
  CLVALNA822: 'Quilpué',
  CLVALNA876: 'Olmue',
  CLVALPA520: 'Valparaíso',
  CLVALPA572: 'Viña del Mar',
  CLVALPA756: 'San Antonio',
  CLVALPA781: 'Quilpué',
  CLVALJU781: 'Quilpué',
  CLVALN781: 'Quilpué',
  CLVALX944: 'San Felipe',
  CLVALX968: 'Quillota',
  CLVINO491: 'Viña del Mar',
  CLVALEU003: 'Viña del Mar',
  CLBIOEA529: 'Los Angeles',
  CLBIOEA633: 'Hualpen',
  CLBIOEA983: 'Coronel',
  CLBIOEA990: 'Chiguayante',
  CLBIOJU633: 'Hualpen',
  CLBIOJU671: 'Talcahuano',
  CLBIOJU676: 'Concepcion',
  CLBIOJU695: 'Los Angeles',
  CLBIOJU809: 'Concepcion',
  CLBION569: 'Concepcion',
  CLBION596: 'San Pedro de la Paz',
  CLBION678: 'Concepcion',
  CLBION679: 'Coronel',
  CLBION680: 'Talcahuano',
  CLBION688: 'Concepcion',
  CLBION737: 'Concepcion',
  CLBION742: 'Los Angeles',
  CLBION761: 'Los Angeles',
  CLBION784: 'San Pedro de la Paz',
  CLBION835: 'Chiguayante',
  CLBION907: 'Chiguayante',
  CLBION927: 'Mulchen',
  CLBION983: 'Coronel',
  CLBIOX983: 'Coronel',
  CLBIONA778: 'San Pedro de la Paz',
  CLBIONA783: 'Concepcion',
  CLBIONA801: 'Concepcion',
  CLBIOPA568: 'Talcahuano',
  CLBIOPA569: 'Concepcion',
  CLBIOPA578: 'Los Angeles',
  CLBIOX680: 'Talcahuano',
  CLLAGNA529: 'Los Angeles',
  CLBIOEU009: 'Talcahuano',
  CLBIOEU017: 'Los Angeles',
  CLLINEA524: 'Linares',
  CLMAUEA591: 'Talca',
  CLMAUEA592: 'Curico',
  CLMAUJU591: 'Talca',
  CLMAUJU592: 'Curico',
  CLMAUN524: 'Linares',
  CLMAUN645: 'Curico',
  CLMAUN687: 'Curico',
  CLMAUN909: 'Curico',
  CLMAUN973: 'Molina',
  CLMAUNA828: 'Talca',
  CLMAUPA592: 'Curico',
  CLMAUPA709: 'Talca',
  CLTALJU957: 'Talca ',
  CLTALN741: 'Talca',
  CLTALEU015: 'Talca',
  CLMAUEU022: 'Curico',
  CLOHIEA504: 'Rancagua',
  CLOHIJU504: 'Rancagua',
  CLOHIJU825: 'Rancagua',
  CLOHIJU851: 'Santa Cruz',
  CLOHIN740: 'San Fernando',
  CLOHIN803: 'Rancagua',
  CLOHIN807: 'Rancagua',
  CLOHIN829: 'Rancagua',
  CLOHIN831: 'Doñihue',
  CLOHIN832: 'Mostazal',
  CLOHIN915: 'Rancagua',
  CLOHIN924: 'Machali',
  CLOHIPA504: 'Rancagua',
  CLOHIEU024: 'Rancagua',
  CLINDJU989: 'Independencia',
  CLSCLEA643: 'Pedro Aguirre Cerda',
  CLSCLJU512: 'Las Condes',
  CLSCLJU519: 'Peñalolen',
  CLSCLJU613: 'Puente Alto',
  CLSCLJU762: 'Lo Barnechea',
  CLSCLN553: 'Lo Barnechea',
  CLSCLN589: 'Pudahuel',
  CLSCLN593: 'Ñuñoa',
  CLSCLN600: 'Santiago',
  CLSCLN607: 'Santiago',
  CLSCLN609: 'Las Condes',
  CLSCLN626: 'La Reina',
  CLSCLN634: 'Quilicura',
  CLSCLN635: 'Las Condes',
  CLSCLN636: 'Quilicura',
  CLSCLN639: 'Las Condes',
  CLSCLN643: 'Pedro Aguirre Cerda',
  CLSCLN669: 'Providencia',
  CLSCLN682: 'Santiago',
  CLSCLN689: 'Quinta Normal',
  CLSCLN698: 'Peñalolen',
  CLSCLN699: 'La Granja',
  CLSCLN701: 'Vitacura',
  CLSCLN704: 'Providencia',
  CLSCLN707: 'La Reina',
  CLSCLN736: 'Peñalolen',
  CLSCLN755: 'Peñaflor',
  CLSCLN757: 'Maipú',
  CLSCLN758: 'Maipú',
  CLSCLN759: 'Quinta Normal',
  CLSCLN763: 'Santiago',
  CLSCLN764: 'La Florida',
  CLSCLN765: 'La Cisterna',
  CLSCLN766: 'La Florida',
  CLSCLN767: 'Santiago',
  CLSCLN769: 'Maipú',
  CLSCLN776: 'Las Condes',
  CLSCLN777: 'Las Condes',
  CLSCLN802: 'Independencia',
  CLSCLN806: 'Maipú',
  CLSCLN818: 'Curacavi',
  CLSCLN823: 'Recoleta',
  CLSCLN834: 'La Florida',
  CLSCLN847: 'San Bernardo ',
  CLSCLN914: 'Paine',
  CLSCLN918: 'Puente Alto',
  CLSCLN923: 'Isla de Maipo',
  CLSCLN926: 'San Bernardo ',
  CLSCLN991: 'Peñalolen',
  CLSGOAC: 'Las Condes',
  CLSGOADMF: 'La Florida',
  CLSGOBIMOL: 'Lampa',
  CLSGOBOFRI: 'Renca',
  CLSGOBSFX7: 'Pudahuel',
  CLSGOCDENE: 'Pudahuel',
  CLSGOCDTXD: 'Pudahuel',
  CLSGOCOS: 'Providencia',
  CLSGOEA502: 'Las Condes',
  CLSGOEA503: 'Maipú',
  CLSGOEA510: 'La Florida',
  CLSGOEA511: 'Providencia',
  CLSGOEA512: 'Las Condes',
  CLSGOEA513: 'San Miguel',
  CLSGOEA514: 'Lo Barnechea',
  CLSCLJU514: 'Lo Barnechea',
  CLSGOEA518: 'Peñalolen',
  CLSGOEA522: 'Maipú',
  CLSGOEA655: 'Quilicura',
  CLSGOEA659: 'Puente Alto',
  CLSGOEA775: 'Ñuñoa',
  CLSGOEA843: 'San Bernardo ',
  CLSGOEA874: 'La Florida',
  CLSGOEA988: 'Colina',
  CLSGOEU221: 'Las Condes',
  CLSGOEU222: 'Lo Barnechea',
  CLSGOEU224: 'Las Condes',
  CLSGOEU225: 'Providencia',
  CLSGOEU580: 'Quilicura',
  CLSGOFOTOG: 'Quilicura',
  CLSGOJU411: 'Providencia',
  CLSGOJU501: 'Las Condes',
  CLSGOJU502: 'Las Condes',
  CLSGOJU503: 'Maipú',
  CLSGOJU510: 'La Florida',
  CLSGOJU511: 'Providencia',
  CLSGOJU513: 'San Miguel',
  CLSGOJU599: 'Pudahuel',
  CLSGOJU624: 'Las Condes',
  CLSGOJU659: 'Puente Alto',
  CLSGOJU770: 'Maipú',
  CLSGOJU775: 'Ñuñoa',
  CLSGOJU810: 'Vitacura',
  CLSGOJU843: 'San Bernardo ',
  CLSGOJU958: 'Lo Barnechea',
  CLSGOJU988: 'Colina',
  CLSGOJU992: 'Colina',
  CLSGOLOGIN: 'Renca',
  CLSGOLS: 'Pudahuel',
  CLSGON630: 'Quilicura',
  CLSGON667: 'Melipilla',
  CLSGON706: 'Renca',
  CLSGON747: 'Calera de Tango',
  CLSGON877: 'Pirque',
  CLSGON878: 'Colina',
  CLSGON971: 'La Reina',
  CLSGON975: 'Talagante',
  CLSGON976: 'Padre Hurtado',
  CLSGON987: 'Maipú',
  CLSGONA518: 'Peñalolen',
  CLSGONA594: 'Pudahuel',
  CLSGONA595: 'Melipilla',
  CLSGONA601: 'La Cisterna',
  CLSGONA602: 'Santiago',
  CLSGONA603: 'Lo Prado',
  CLSGONA604: 'Maipú',
  CLSGONA605: 'La Pintana',
  CLSGONA606: 'Estacion Central',
  CLSGONA608: 'Santiago',
  CLSGONA610: 'La Granja',
  CLSGONA611: 'Independencia',
  CLSGONA612: 'Santiago',
  CLSGONA641: 'Pudahuel',
  CLSGONA651: 'Lampa',
  CLSGONA654: 'Maipú',
  CLSGONA672: 'Puente Alto',
  CLSGONA692: 'Huechuraba',
  CLSGONA700: 'Pedro Aguirre Cerda',
  CLSGONA702: 'El Bosque',
  CLSGONA703: 'Conchali',
  CLSGONA725: 'Pudahuel',
  CLSGONA771: 'Santiago',
  CLSGONA869: 'Til Til',
  CLSGOO415: 'Las Condes',
  CLSGOO437: 'Providencia',
  CLSGOO438: 'Lo Barnechea',
  CLSGOO439: 'Lo Barnechea',
  CLSGOO440: 'Las Condes',
  CLSGOO441: 'Las Condes',
  CLSGOO442: 'Vitacura',
  CLSGOO443: 'La Reina',
  CLSGOO444: 'Ñuñoa',
  CLSGOO474: 'Las Condes',
  CLSGOO476: 'Ñuñoa',
  CLSGOO477: 'Vitacura',
  CLSGOO478: 'Las Condes',
  CLSGOO479: 'Las Condes',
  CLSGOO480: 'Las Condes',
  CLSGOO481: 'Vitacura',
  CLSGOO482: 'Las Condes',
  CLSGOO484: 'Vitacura',
  CLSGOO485: 'Las Condes',
  CLSGOO486: 'Providencia',
  CLSGOO487: 'Las Condes',
  CLSGOO492: 'Providencia',
  CLSGOO493: 'Vitacura',
  CLSGOO495: 'Santiago',
  CLSGOO496: 'Las Condes',
  CLSGOO497: 'Providencia',
  CLSGOO498: 'Las Condes',
  CLSGOO714: 'Las Condes',
  CLSGOO716: 'Providencia',
  CLSGOO861: 'Las Condes',
  CLSGOO862: 'Ñuñoa',
  CLSGOO864: 'Las Condes',
  CLSGOO865: 'Las Condes',
  CLSGOO870: 'Providencia',
  CLSGOO871: 'Las Condes',
  CLSGOO873: 'Las Condes',
  CLSGOPA502: 'Las Condes',
  CLSGOPA503: 'Maipú',
  CLSGOPA510: 'La Florida',
  CLSGOPA511: 'Providencia',
  CLSGOPA514: 'Lo Barnechea',
  CLSGOPA518: 'Peñalolen',
  CLSGOPA563: 'Providencia',
  CLSGOPA564: 'La Florida',
  CLSGOPA565: 'Las Condes',
  CLSGOPA567: 'Cerrillos',
  CLSGOPA575: 'Huechuraba',
  CLSGOPA580: 'Renca',
  CLSGOPA600: 'Santiago',
  CLSGOPA627: 'San Bernardo ',
  CLSGOPA628: 'Estacion Central',
  CLSGOPA775: 'Ñuñoa',
  CLSGOPA920: 'Quilicura',
  CLSGOPA989: 'Independencia',
  CLSGOPM: 'Pudahuel',
  CLSGOX513: 'San Miguel',
  CLSGOX581: 'Quilicura',
  CLSGOX939: 'Melipilla',
  CLSGOX966: 'Puente Alto',
  CLVALN852A: 'Melipilla',
  CLSGOEU005: 'Las Condes',
  CLSGOEU008: 'Las Condes',
  CLSGOEU181: 'Lo Barnechea',
  CLSGOEU184: 'Providencia',
  CLSGOEU182: 'Las Condes',
  CLSGOEU187: 'Las Condes',
  CLSGOEU188: 'La Reina',
  CLSGOEU025: 'Providencia',
  CLLAGEA507: 'Puerto Montt',
  CLLAGEA748: 'Osorno',
  CLLAGJU507: 'Puerto Montt',
  CLLAGJU748: 'Osorno',
  CLLAGJU919: 'Puerto Varas',
  CLLAGN585: 'Osorno',
  CLLAGN733: 'Puerto Varas',
  CLLAGN734: 'Puerto Montt',
  CLLAGN824: 'Osorno',
  CLLAGN904: 'Puerto Montt',
  CLLAGN906: 'Puerto Varas',
  CLLAGNA587: 'Puerto Montt',
  CLLAGPA584: 'Puerto Montt',
  CLLAGPA748: 'Osorno',
  CLOSOEA585: 'Osorno',
  CLLAGEU026: 'Osorno',
  EA102: 'Martinez',
  JU202: 'Martinez',
  EA103: 'Villa Adelina',
  EA104: 'Llavallol',
  JU204: 'Llavallol',
  EA105: 'Villa Ballester',
  JU205: 'Villa Ballester',
  EA106: 'Capital Federal',
  JU206: 'Capital Federal',
  EA107: 'Quilmes Oeste',
  JU207: 'Quilmes Oeste',
  EA108: 'Morón',
  JU208: 'Morón',
  EA110: 'Ituzaingo',
  EA111: 'Pilar',
  JU211: 'Pilar',
  'Blaisten Pilar': 'Pilar',
  EA112: 'Avellaneda',
  EA114: 'Belen De Escobar',
  JU214: 'Belen De Escobar',
  'Blaisten Escobar': 'Belen De Escobar',
  EA117: 'Tablada',
  EA132: 'Capital Federal',
  EA135: 'San Miguel',
  EA137: 'Capital Federal',
  SM741: 'Capital Federal',
  EA139: 'Don Torcuato',
  EA140: 'Capital Federal',
  EA141: 'Avellaneda',
  EA142: 'San Justo',
  EA143: 'Capital Federal',
  EA147: 'Capital Federal',
  JU201: 'Capital Federal',
  EA149: 'Capital Federal',
  EA150: 'Caseros',
  EA152: 'Mar del Plata',
  EA156: 'Canning',
  SM740: 'Canning',
  EA158: 'Moreno',
  EA162: 'Lanús',
  SM692: 'Lanús',
  EA163: 'Bahía Blanca',
  SM122: 'Bahía Blanca',
  EA164: 'Capital Federal',
  EA165: 'Monte Grande',
  SM698: 'Monte Grande',
  EA169: 'Jose Clemente Paz',
  EA176: 'Quilmes',
  EA179: 'Tortuguitas',
  EA197: 'San Justo',
  EA198: 'Munro',
  JU034: 'Quilmes Centro',
  JU121: 'Capital Federal',
  JU138: 'Tigre',
  JU213: 'Capital Federal',
  JU231: 'Capital Federal',
  JU259: 'Capital Federal',
  JU260: 'Capital Federal',
  JU263: 'Capital Federal',
  JU275: 'Olivos',
  JU276: 'Capital Federal',
  JU299: 'Tortuguitas',
  'CD Tortuguitas': 'Tortuguitas',
  JU626: 'Pacheco',
  JU628: 'San Fernando',
  JU662: 'Pilar',
  JU961: 'Capital Federal',
  SM102: 'Martinez',
  SM001: 'San Isidro',
  SM002: 'Capital Federal',
  SM003: 'Capital Federal',
  SM004: 'Vicente López',
  SM008: 'Chivilcoy',
  SM009: 'Lomas de Zamora',
  SM010: 'Capital Federal',
  SM012: 'Capital Federal',
  SM014: 'Capital Federal',
  SM015: 'Capital Federal',
  SM018: 'Capital Federal',
  SM019: 'Capital Federal',
  SM020: 'Lanús',
  SM022: 'Capital Federal',
  SM023: 'Capital Federal',
  SM025: 'Capital Federal',
  SM030: 'Capital Federal',
  SM035: 'Capital Federal',
  SM036: 'La Plata',
  SM037: 'Adrogué',
  SM038: 'Capital Federal',
  SM039: 'Capital Federal',
  SM042: 'La Plata',
  SM045: 'Villa Gesell',
  SM046: 'Capital Federal',
  SM048: 'Capital Federal',
  SM049: 'Pinamar',
  SM050: 'Villa Gesell',
  SM051: 'Necochea',
  SM052: 'San Clemente del Tuyú',
  SM054: 'Capital Federal',
  SM056: 'Mar del Plata',
  SM057: 'Mar del Plata',
  SM058: 'Mar del Plata',
  SM059: 'Mar del Plata',
  SM061: 'Tortuguitas',
  SM062: 'La Plata',
  SM107: 'Capital Federal',
  SM111: 'City Bell',
  SM112: 'Banfield',
  SM119: 'Capital Federal',
  SM100: 'Capital Federal',
  SM127: 'Bahía Blanca',
  SM137: 'Capital Federal',
  SM150: 'Padua',
  SM152: 'Ramos Mejía',
  SM153: 'Morón',
  SM154: 'Morón',
  SM155: 'Castelar',
  SM156: 'Castelar',
  SM159: 'Merlo',
  SM160: 'Moreno',
  SM162: 'Luján',
  SM163: 'Bella Vista',
  SM164: 'Hurlingham',
  SM165: 'Mercedes',
  SM166: 'Ituzaingo',
  SM229: 'Ituzaingo',
  SM231: 'Mar del Plata',
  SM234: 'Caseros',
  SM235: 'La Horqueta',
  SM237: 'Capital Federal',
  SM240: 'Capital Federal',
  SM241: 'Mar del Plata',
  SM247: 'Buenos Aires',
  SM248: 'San Bernardo',
  SM631: 'San Pedro',
  SM633: 'La Plata',
  SM636: 'La Plata',
  SM640: 'La Plata',
  SM642: 'Mar del Plata',
  SM665: 'Capital Federal',
  SM668: 'Rojas',
  SM669: 'Pergamino',
  SM677: 'Saladillo',
  SM696: 'Capital Federal',
  SM697: 'Pacheco',
  SM700: 'Capital Federal',
  SM701: 'Capital Federal',
  SM702: 'Lobos',
  SM703: 'Cañuelas',
  SM704: 'Tres Arroyos',
  SM711: 'Tandil',
  SM767: 'Junín',
  SM912: 'Junín',
  SM916: 'Capital Federal',
  SM942: 'Mar del Plata',
  SM944: 'Buenos Aires',
  SM977: 'Capital Federal',
  SM983: 'Miramar',
  SM242: 'Catamarca',
  SM243: 'Catamarca',
  SM505: 'Catamarca',
  SM550: 'Catamarca',
  SM769: 'Catamarca',
  EA168: 'Resistencia',
  SM674: 'Resistencia',
  EA146: 'Trelew',
  SM752: 'Trelew',
  EA177: 'Comodoro Rivadavia',
  JU277: 'Comodoro Rivadavia',
  SM706: 'Puerto Madryn',
  EA115: 'Cordoba',
  EA120: 'Cordoba',
  EA121: 'Cordoba',
  EA173: 'Rio IV',
  JU451: 'Córdoba',
  SM028: 'Córdoba',
  SM029: 'Córdoba',
  SM032: 'Córdoba',
  SM033: 'Córdoba',
  SM113: 'Rio IV',
  SM114: 'Córdoba',
  SM195: 'Río IV',
  SM232: 'V. María',
  SM233: 'V.C.Paz',
  SM432: 'Córdoba',
  SM434: 'Córdoba',
  SM435: 'Córdoba',
  SM436: 'Córdoba',
  SM437: 'Córdoba',
  SM439: 'Córdoba',
  SM440: 'Córdoba',
  SM441: 'Córdoba',
  SM444: 'Córdoba',
  SM448: 'V.C.Paz',
  SM449: 'V.C.Paz',
  SM450: 'Cosquín',
  SM452: 'La Falda',
  SM453: 'Córdoba',
  SM455: 'Córdoba',
  SM456: 'J.María',
  SM460: 'Córdoba',
  SM464: 'San Fco.',
  SM466: 'Córdoba',
  SM468: 'Córdoba',
  SM472: 'Córdoba',
  SM473: 'Córdoba',
  SM475: 'Córdoba',
  SM476: 'Córdoba',
  SM478: 'Bell Ville',
  SM479: 'Río IV',
  SM485: 'La Calera',
  SM503: 'Marcos Juares',
  SM583: 'Villa Allende',
  SM690: 'Río III',
  SM709: 'Córdoba',
  SM725: 'Alta Gracia',
  SM842: 'Cruz del Eje',
  SM843: 'Río Cevallos',
  SM954: 'Alta Gracia',
  SM688: 'Goya',
  SM691: 'Paso de los Libres',
  SM685: 'Chajarí',
  SM686: 'Gualeguaychú',
  SM854: 'Concordia',
  SM943: 'Paraná',
  SM645: 'San Salvador de Jujuy',
  SM646: 'Güemes',
  SM647: 'San Salvador de Jujuy',
  SM729: 'Ledesma',
  EA154: 'La Rioja',
  SM705: 'La Rioja',
  SM708: 'La Rioja',
  EA122: 'Godoy Cruz',
  JU222: 'Godoy Cruz',
  EA129: 'Guaymallén',
  SM189: 'Guaymallén',
  JU225: 'Vista Pueblo',
  SM167: 'Mendoza',
  SM170: 'Godoy Cruz',
  SM171: 'Godoy Cruz',
  SM172: 'Guaymallén',
  SM174: 'Godoy Cruz',
  SM174: 'Panamericana Km 37.5 Ramal Pilar',
  SM175: 'Godoy Cruz',
  SM176: 'Godoy Cruz',
  SM179: 'Las Heras',
  SM180: 'Mendoza',
  SM181: 'Guaymallén',
  SM182: 'San Martín',
  SM183: 'San Martín',
  SM184: 'Rivadavia',
  SM185: 'Tunuyán',
  SM187: 'Maipú',
  SM188: 'Ciudad',
  SM190: 'Las Heras',
  SM191: 'San Rafael',
  SM192: 'Godoy Cruz',
  SM193: 'Desamparados',
  SM196: 'Guaymallén',
  SM220: 'Lujan de Cuyo',
  SM221: 'Villa Nueva De Guaymallen',
  'CD Cuyo': 'Villa Nueva De Guaymallen',
  SM398: 'Rodeo de la Cruz',
  SM399: 'San Martín',
  SM400: 'Guaymallén',
  SM401: 'Maipú',
  SM402: 'Ciudad',
  SM545: 'Tupungato',
  SM546: 'Mendoza',
  SM548: 'San Carlos',
  SM584: 'San Rafael',
  SM803: 'Gral. Alvear',
  SM855: 'Mendoza',
  SM856: 'Las Heras',
  SM857: 'Mendoza',
  SM868: 'San Rafael',
  SM914: 'Malargüe',
  SM177: 'Mendoza',
  EA116: 'Neuquen',
  JU216: 'Neuquen',
  SM902: 'Neuquen',
  EA155: 'General Roca',
  SM433: 'Cipolletti',
  SM901: 'General Roca',
  SM910: 'General Roca',
  EA151: 'Salta',
  JU251: 'Salta',
  JU245: 'San Lorenzo',
  SM040: 'Salta',
  SM067: 'Salta',
  SM110: 'Salta',
  SM140: 'Oran',
  SM655: 'Salta',
  SM656: 'Salta',
  SM657: 'Salta',
  SM658: 'Salta',
  SM731: 'Salta',
  SM980: 'Salta',
  EA123: 'San Juan',
  SM194: 'San Juan',
  SM408: 'San Juan',
  SM737: 'San Juan',
  SM410: 'Trinidad',
  SM413: 'San Juan',
  SM414: 'Villa Krause',
  SM415: 'Caucete',
  SM417: 'San Juan',
  SM544: 'San Juan',
  SM815: 'Rawson',
  EA145: 'San Luis',
  SM423: 'Villa Mercedes',
  SM853: 'San Luís',
  SM859: 'San Luis',
  SM860: 'San Luís',
  EA118: 'Rosario',
  JU218: 'Rosario',
  EA130: 'Rosario',
  SM430: 'Funes',
  SM953: 'Venado Tuerto',
  EA170: 'Santiago del Estero',
  SM013: 'Santiago del Estero',
  SM730: 'La Banda',
  SM887: 'Santiago del Estero',
  EA127: 'Yerba Buena',
  JU227: 'Yerba Buena',
  SM070: 'Yerba Buena',
  SM071: 'San Miguel de Tucumán',
  SM072: 'Tucuman',
  SM073: 'San Miguel de Tucumán',
  SM125: 'San Miguel de Tucumán',
  SM134: 'San Miguel de Tucumán',
  SM224: 'Cruz Alta',
  'CD Tucuman': 'Cruz Alta',
  SM987: 'Cruz Alta',
  SM660: 'Tucumán',
  SM727: 'Tucumán',
  SM728: 'Tafi Viejo',
  SM903: 'Monteros',
  SM904: 'Concepción',
  SM906: 'Alberdi',
  SM958: 'San Miguel de Tucumán',
};

const countryDataTmp = [
  {
    country: 'chile',
    site: 'chile',
    name: 'Chile',
    generalStatus: -1,
    selected: true,
    datacenters: {
      total: 0,
      problems: 0,
    },
    devices: {
      total: 0,
      problems: 0,
    },
    services: {
      total: 0,
      problems: 0,
    },
    links: {
      total: 0,
      problems: 0,
    },
    stores: {
      total: 0,
      problems: 0,
    },
  },
  {
    country: 'argentina',
    site: 'argentina',
    name: 'argentina',
    generalStatus: -1,
    datacenters: {
      total: 0,
      problems: 0,
    },
    devices: {
      total: 0,
      problems: 0,
    },
    services: {
      total: 0,
      problems: 0,
    },
    links: {
      total: 0,
      problems: 0,
    },
    stores: {
      total: 0,
      problems: 0,
    },
  },
  {
    country: 'brasil',
    site: 'redes_br',
    name: 'brasil',
    generalStatus: -1,
    datacenters: {
      total: 0,
      problems: 0,
    },
    devices: {
      total: 0,
      problems: 0,
    },
    services: {
      total: 0,
      problems: 0,
    },
    links: {
      total: 0,
      problems: 0,
    },
    stores: {
      total: 0,
      problems: 0,
    },
  },
  {
    country: 'peru',
    site: 'redes_pe',
    name: 'peru',
    generalStatus: -1,
    datacenters: {
      total: 0,
      problems: 0,
    },
    devices: {
      total: 0,
      problems: 0,
    },
    services: {
      total: 0,
      problems: 0,
    },
    links: {
      total: 0,
      problems: 0,
    },
    stores: {
      total: 0,
      problems: 0,
    },
  },
  {
    country: 'colombia',
    site: 'redes_co',
    name: 'colombia',
    generalStatus: -1,
    datacenters: {
      total: 0,
      problems: 0,
    },
    devices: {
      total: 0,
      problems: 0,
    },
    services: {
      total: 0,
      problems: 0,
    },
    links: {
      total: 0,
      problems: 0,
    },
    stores: {
      total: 0,
      problems: 0,
    },
  },
];

const enlacesTroncales = [
  'G500603RT005;172.18.208.5;BGP to CLARO C/S:02-25-0010333429 MPLS-TIENDA- CDLV;Claro;CDLV;',
  'G500603RT060;172.18.175.60;BGP to ENLACE MPLS Claro 1 Gbps Ref:02-250010175443;Claro;AMU;',
  'G500603RT002;172.18.208.2;Interface **ENLACE Movistar C/S:VPF2191728 MPLS-TIENDAS**;Movistar;CDLV;',
  'G500603RT050;172.18.175.50;BGP to ENLACE MPLS Movistar Amunategui;Movistar;AMU;',
  'G500603RT061;172.18.175.61;BGP to ENLACE MPLS Movistar 100 Mbps 172.26.219.66;Movistar;AMU;',
  'G500603RT004;172.18.208.4;Enlace OSPF GTD 172.26.14.1-Troncal AMU;Gtd;CDLV;',
  'G500603RT059;172.18.175.59;Enlace OSPF GTD 172.26.14.3-Troncal CDLV;Gtd;AMU;',
  'G500603SW000-1;172.18.176.5;Interface **ENLACE GTD_160794_1000Mbps - WAN Principal JOHNSON**;Gtd;AMU;',
  'G500603RT003;172.18.208.3;BGP to ENTEL OTT:72002739201 CS:10000553167 MPLS-CDLV;Entel;CDLV;',
  'G500603RT065;172.18.175.45;BGP to ENLACE MPLS Entel OTT:72002739223 C/S:10000157175 172.26.195.18;Entel;AMU;',
  'G500603SW000-1;172.18.176.5;Interface **ENLACE GTD_438286_100Mbps - PARIS_GTD_MONEDA_920 Gi1/21**;Gtd;AMU;',
];

const dictPriorityPercentageByState = {
  1: 95,
  2: 100,
  3: 50,
};

const dictServices = {
  fan: 'Fan',
  'Bgp Warning': 'Bgp Warning',
  cpu: 'Cpu',
  memory: 'Memory',
  temperature: 'Temperature',
  sla: 'Sla',
  'Interfaces Down': 'Interfaces Down',
  'Interfaces Bandwith': 'Interfaces Bandwith',
  psu: 'Psu',
};

export { countryDataTmp, dictComunas, dictNames, enlacesTroncales, dictPriorityPercentageByState, dictServices };
