import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import CSVHocComponent from 'components/CSVHocComponent/CSVHocComponent';
import DatacenterTable from 'components/DatacenterTable/DatacenterTable';

import { getSaturationForDatacenter } from '../../../../api/link';

import { getStatusByNumber } from '../../../../utils/color';

import { dictPriorityPercentageByState } from '../../utils/database';

import { parseToJSONInterface, extractMetrics } from '../../utils/common';

import './Datacenter.css';

function Datacenter({ servicesLinksTroncales, servicesLinksStores, selectedCountries }) {
  const jsonNames = {
    DC_CIUDAD_DE_LOS_VALLES_CHILE: 'CDLV',
    DC_AMUNATEGUI_CHILE: 'AMU',
  };

  const [filteredInterfaces, setFilteredInterfaces] = useState({ datacenter: [], status: [] });
  const [servicesTroncales, setServicesTroncales] = useState([]);

  function createLinkItem(slt, type) {
    const dc = jsonNames[slt.codigo_de_tienda];

    let extraDataLink = {};
    if (type === 'troncal') {
      extraDataLink = getSaturationForDatacenter(slt.plugin_output);
    } else {
      extraDataLink = extractMetrics(slt.plugin_output);
    }
    if (extraDataLink) {
      extraDataLink.errorsByState = dictPriorityPercentageByState[slt.state];
    }
    const slaInfo = parseToJSONInterface(slt.description);

    for (const key in slaInfo) {
      if (key.includes('TLCO')) {
        slt.prov = slaInfo[key];
        break;
      }
    }

    return {
      type,
      hostname: slt.host_name,
      description: slt.description,
      plugin_output: slt.plugin_output,
      name: dc,
      status: type === 'troncal' ? (slt.state === 0 ? 0 : 2) : slt.state === 3 ? 2 : slt.state,
      ip: slt.address || slt.ip,
      codigoServicio: slaInfo.CS,
      saturation:
        type === 'troncal'
          ? extraDataLink?.inPercentage
            ? `${extraDataLink?.inPercentage.toFixed(1)}% | ${extraDataLink?.outPercentage.toFixed(1)}%`
            : '-'
          : extraDataLink?.latency,
      crc: type === 'troncal' ? extraDataLink?.errors || 0 : extraDataLink?.packetLoss,
      prov: slt.prov,
      maxMetric: Math.max(
        extraDataLink?.inPercentage || 0,
        extraDataLink?.outPercentage || 0,
        extraDataLink?.errorsIn || 0,
        extraDataLink?.errorsByState || 0
      ),
      type,
    };
  }

  function getFilteredItems(allItems) {
    const simpleArrayFilterStatus = [];
    const simpleArrayFilterDatacenter = [];
    filteredInterfaces['status'].forEach((filter) => {
      simpleArrayFilterStatus.push(filter.value);
    });
    filteredInterfaces['datacenter'].forEach((filter) => {
      simpleArrayFilterDatacenter.push(filter.value);
    });
    const arrayItems = allItems.filter((itm) => {
      if (
        (simpleArrayFilterDatacenter.some((filter) => filter === itm.dc) || !simpleArrayFilterDatacenter.length) &&
        (simpleArrayFilterStatus.some((filter) => filter === getStatusByNumber(itm.state)) ||
          !simpleArrayFilterStatus.length)
      ) {
        return true;
      }
    });
    return arrayItems;
  }

  function processLinks(servicesLinks, country, type) {
    const items = [];
    const links = servicesLinks[country.toUpperCase()];
    if (links) {
      if (Array.isArray(links)) {
        const arrayFiltered = getFilteredItems(links);
        arrayFiltered.forEach((slt) => items.push(createLinkItem(slt, type)));
      } else {
        for (const key in links) {
          const linkGroup = links[key];
          if (Array.isArray(linkGroup)) {
            const arrayFiltered = getFilteredItems(linkGroup);
            arrayFiltered.forEach((slt) => items.push(createLinkItem({ ...slt, prov: key }, type)));
          }
        }
      }
    }
    items.sort((a, b) => b.maxMetric - a.maxMetric);
    return items;
  }

  function getTroncalesItem() {
    const key = selectedCountries[0].country.toUpperCase();
    return processLinks({ [key]: servicesTroncales }, selectedCountries[0].country, 'troncal');
  }

  function getInternetItem() {
    return processLinks(servicesLinksStores, selectedCountries[0].country, 'internet');
  }

  function getFilter(filterName) {
    const arrayFilter = [];
    const itemsFilter = [];
    const country = selectedCountries[0].country.toUpperCase();
    if (servicesLinksTroncales[country]) {
      servicesLinksTroncales[country].forEach((slt) => itemsFilter.push(slt));
    }
    for (const key in servicesLinksStores[country]) {
      const linkGroup = servicesLinksStores[country][key];
      if (Array.isArray(linkGroup)) {
        linkGroup.forEach((slt) => itemsFilter.push(slt));
      }
    }
    itemsFilter.forEach((item) => {
      if (filterName === 'datacenter') {
        if (!arrayFilter.some((flt) => flt.value === item.dc)) {
          arrayFilter.push({ value: item.dc, label: item.dc });
        }
      } else if (filterName === 'status') {
        if (!arrayFilter.some((flt) => flt.value === getStatusByNumber(item.state))) {
          arrayFilter.push({ value: getStatusByNumber(item.state), label: getStatusByNumber(item.state) });
        }
      }
    });
    return arrayFilter;
  }

  useEffect(() => {
    const interfacesDict = {};
    const bgpDict = {};
    servicesLinksTroncales.forEach((eachLink) => {
      if (eachLink.host.tags.TIENDA === 'DATACENTER') {
        let source;
        if (eachLink.description.startsWith('Interface') && eachLink.description.includes('CS:')) {
          source = interfacesDict;
        } else if (eachLink.description.startsWith('BGP') && eachLink.description.includes('CS:')) {
          source = bgpDict;
        }
        if (
          eachLink.description.includes('CS:') &&
          (eachLink.description.startsWith('BGP') || eachLink.description.startsWith('Interface')) &&
          (eachLink.host.labels['cmk/device_type'] === 'switch' || eachLink.host.labels['cmk/device_type'] === 'router')
        ) {
          let strWithInfoEnlace = eachLink.description;
          const jsonName = parseToJSONInterface(strWithInfoEnlace);
          if (jsonName && jsonName['CS']) {
            if (!source[jsonName['CS']]) {
              source[jsonName['CS']] = eachLink;
            }
          }
        }
      }
    });
    const tempArrayEnlaces = [];
    for (const bgpCs in bgpDict) {
      const bgpInfo = bgpDict[bgpCs];
      if (interfacesDict[bgpCs]) {
        tempArrayEnlaces.push({ ...interfacesDict[bgpCs], state: bgpInfo.state });
      }
    }

    setServicesTroncales(tempArrayEnlaces);
  }, [servicesLinksTroncales, selectedCountries]);

  return (
    <CSVHocComponent personalizedClass={'datacenter-country'} data={[...getTroncalesItem(), ...getInternetItem()]}>
      <div className="label-header">{'Datacenter país'}</div>
      <div className="filters-alert">
        <div className="filter-alert">
          <span className="filter-title">DATACENTER</span>
          <div className="filter-input">
            <Select
              options={getFilter('datacenter')}
              closeMenuOnSelect={false}
              isClearable
              isMulti
              onChange={(info) => {
                const copyFilter = { ...filteredInterfaces };
                copyFilter['datacenter'] = info;
                setFilteredInterfaces(copyFilter);
              }}
            />
          </div>
        </div>
        <div className="filter-alert">
          <span className="filter-title">ESTADO</span>
          <div className="filter-input">
            <Select
              options={getFilter('status')}
              closeMenuOnSelect={false}
              isClearable
              isMulti
              onChange={(info) => {
                const copyFilter = { ...filteredInterfaces };
                copyFilter['status'] = info;
                setFilteredInterfaces(copyFilter);
              }}
            />
          </div>
        </div>
      </div>
      <div className="tables">
        <div className="troncal">
          <DatacenterTable items={getTroncalesItem()} />
        </div>
        <div className="enlaces">
          <DatacenterTable
            headers={{ third: 'SLA', fourth: 'IP HOST', fifth: 'Latency', sixth: 'PacketLoss' }}
            items={getInternetItem()}
          />
        </div>
      </div>
    </CSVHocComponent>
  );
}

export default Datacenter;
