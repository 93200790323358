// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vital-check {
  height: 34%;
}

.vital-check:hover {
  background-color: #023154;
}

.active-problems-services {
  background: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  height: 100%;
  overflow: hidden;
}

.active-problems-services .header-active-problems {
  display: flex;
  align-items: start;
  justify-content: space-between;
  height: 30px;
}

.active-problems-services .header-active-problems .label-ap {
  font-size: 16px;
  font-weight: 700;
}

.active-problems-services .header-active-problems .icon-ap {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.active-problems-services .header-active-problems .icon-ap img {
  width: 100%;
  height: auto;
}

.active-problems-services .wrapper-table-vitality-checks {
  width: 100%;
  height: calc(100% - 24px);
  overflow-y: auto;
}

.store-problems-table .wrapper-sort {
  /* position: absolute;
  right: 0; */
  margin-left: 6px;
}

.store-problems-table .wrapper-sort .up {
  font-size: 12px;
}

.store-problems-table .wrapper-sort .down,
.store-problems-table .wrapper-sort .up {
  cursor: pointer;
}

.store-problems-table .wrapper-sort .down:hover,
.store-problems-table .wrapper-sort .up:hover {
  color: black;
}

.store-problems-table .wrapper-sort .down {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./views/NetworkPanel/components/ChequeosVitales/ChequeosVitales.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE;aACW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".vital-check {\n  height: 34%;\n}\n\n.vital-check:hover {\n  background-color: #023154;\n}\n\n.active-problems-services {\n  background: white;\n  border-radius: 10px;\n  padding: 10px;\n  margin: 10px;\n  height: 100%;\n  overflow: hidden;\n}\n\n.active-problems-services .header-active-problems {\n  display: flex;\n  align-items: start;\n  justify-content: space-between;\n  height: 30px;\n}\n\n.active-problems-services .header-active-problems .label-ap {\n  font-size: 16px;\n  font-weight: 700;\n}\n\n.active-problems-services .header-active-problems .icon-ap {\n  width: 28px;\n  height: 28px;\n  cursor: pointer;\n}\n\n.active-problems-services .header-active-problems .icon-ap img {\n  width: 100%;\n  height: auto;\n}\n\n.active-problems-services .wrapper-table-vitality-checks {\n  width: 100%;\n  height: calc(100% - 24px);\n  overflow-y: auto;\n}\n\n.store-problems-table .wrapper-sort {\n  /* position: absolute;\n  right: 0; */\n  margin-left: 6px;\n}\n\n.store-problems-table .wrapper-sort .up {\n  font-size: 12px;\n}\n\n.store-problems-table .wrapper-sort .down,\n.store-problems-table .wrapper-sort .up {\n  cursor: pointer;\n}\n\n.store-problems-table .wrapper-sort .down:hover,\n.store-problems-table .wrapper-sort .up:hover {\n  color: black;\n}\n\n.store-problems-table .wrapper-sort .down {\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
