import React, { useState, useEffect } from 'react';

import Card from 'components/Card/Card';
import CardTitle from 'components/Card/CardTitle';
import CardStates from 'components/Card/CardStates';
import TableActiveAlerts from 'components/TableActiveAlerts/TableActiveAlerts';

import { getStores } from '../../api/store';
import { getStatusColorByNumber, getStatusColorByThreshold } from '../../utils/color';
import './NocDetails.css';

type Props = {
  country: any;
  hosts: any;
  onBack: any;
};

const countryExample = {
  name: 'Chile',
  alerts: 12,
};

const tableColumns = [
  {
    label: 'ESTADO',
    field: 'type',
    sort: 'disabled',
    width: 80,
  },
  {
    label: 'REGIÓN',
    field: 'region',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'TIENDA',
    field: 'store',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'CÓDIGO',
    field: 'code',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'SERVICIOS CON PROBLEMAS',
    field: 'active_alarms',
    sort: 'disabled',
    width: 100,
  },
  {
    label: '% SATURACION',
    field: 'saturation',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'DISPOSITIVOS CON PROBLEMAS',
    field: 'devices_state',
    sort: 'disabled',
    width: 100,
  },
  {
    label: 'ROUTERS CON PROBLEMAS',
    field: 'routers_state',
    sort: 'disabled',
    width: 100,
  },
  {
    label: 'SWITCHS CON PROBLEMAS',
    field: 'switchs_state',
    sort: 'disabled',
    width: 100,
  },
  {
    label: 'CON PROBLEMAS DE UPTIME(<30m)',
    field: 'uptime_state',
    sort: 'disabled',
    width: 100,
  },
  {
    label: 'VER TIENDA',
    field: 'see_store',
    sort: 'disabled',
    width: 100,
  },
];

const NocDetailsCD: React.FC<Props> = ({ country = countryExample, hosts, onBack }) => {
  const [stores, setStores] = useState<any[]>([]);
  const [resume, setResume] = useState<any>({ totalStoreProblems: 0, storeStates: [], state: 'OK' });
  const [storesWithProblems, setStoresWithProblems] = useState<any[]>([]);

  useEffect(() => {
    const country = {
      country: 'chile',
      name: 'chile',
    };
    const hostsFiltered = hosts.filter((host: any) => {
      return host.tags.SITE === country.country.toUpperCase();
    });
    const allStores = getStores(hostsFiltered);

    const stores = allStores.filter(
      (store: any) => store.name === 'ADM' || store.name === 'BODEGA' || store.name === 'BO' || store.name === 'CD'
    );
    let storesWithProblems = stores.filter((store: any) => {
      return store.criticity > 0;
    });
    storesWithProblems = storesWithProblems.sort((a: any, b: any) => {
      return b.criticity - a.criticity;
    });
    storesWithProblems = storesWithProblems.sort((a: any, b: any) => {
      return b.state - a.state;
    });
    const storesDown = stores.filter((store: any) => {
      return store.state === 2;
    });
    const storesUptime = stores.filter((store: any) => {
      return store.hasUptimeProblems;
    });
    const storeStates = [
      { value: `${storesDown.length}/${stores.length - storesDown.length}`, name: 'down / up' },
      { value: `${storesUptime.length}/${stores.length - storesUptime.length}`, name: 'uptime' },
    ];
    const resume = {
      name: 'CD/BO/ADM',
      totalStoreProblems: storesWithProblems.length,
      storeStates,
      state: storesDown.length > 0 ? 'CRITICAL' : 'OK',
    };
    setStores(stores);
    setStoresWithProblems(storesWithProblems);
    setResume(resume);
  }, [hosts]);
  function getStoresTable() {
    const alertRows: any[] = [];
    storesWithProblems.forEach((store) => {
      alertRows.push({
        type: <div className="icon-status" style={{ backgroundColor: getStatusColorByNumber(store.state) }}></div>,
        store: store.name,
        code: store.code,
        region: store.region,
        // saturation: `${store.saturation.toFixed(1)}%`,
        saturation: (
          <div
            style={{
              padding: '4px 8px',
              borderRadius: '4px',
              backgroundColor: '#000000',
              color: '#ffffff',
              width: '45px',
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 'bold',
            }}
          >
            {store.saturation.toFixed(1)}
          </div>
        ),
        devices_state: (
          <span style={{ color: getStatusColorByThreshold(store.hostProblemsPercent, [50, 100]) }}>
            {store.devices.problems} / {store.devices.total}
          </span>
        ),
        routers_state: (
          <span style={{ color: getStatusColorByThreshold(store.routersProblemsPercent, [50, 100]) }}>
            {store.routers.problems} / {store.routers.total}
          </span>
        ),
        switchs_state: (
          <span style={{ color: getStatusColorByThreshold(store.switchsProblemsPercent, [50, 100]) }}>
            {store.switchs.problems} / {store.switchs.total}
          </span>
        ),
        // uptime_state: `${store.hosts.withUptimeProblems} / ${store.hosts.withUptime}`,
        uptime_state: (
          <div
            style={{
              padding: '4px 8px',
              borderRadius: '4px',
              backgroundColor: '#000000',
              color: '#ffffff',
              width: '70px',
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 'bold',
            }}
          >
            {store.hosts.withUptimeProblems} / {store.hosts.withUptime}
          </div>
        ),
        active_alarms: `${store.serviceProblems} / ${store.serviceTotal}`,
        see_store: 'Ver',
      });
    });

    return {
      columns: tableColumns,
      rows: alertRows,
    };
  }
  // function changeOrderByType(type: string) {
  //   console.log(type);
  // }
  function getGroupItems() {
    const groupIndicators: any [] = [];
    const cd = {
      name: 'CD',
      total: 0,
      down: [],
      withUptimeProblems: [],
      withProblems: 0,
    };
    const bo = {
      name: 'BO',
      total: 0,
      down: [],
      withUptimeProblems: [],
      withProblems: 0,
    };
    const adm = {
      name: 'ADM',
      total: 0,
      down: [],
      withUptimeProblems: [],
      withProblems: 0,
    };
    stores.forEach((store: any) => {
      let source: any;
      switch (store.name) {
        case 'ADM':
          source = adm;
          break;
        case 'BODEGA':
          source = bo;
          break;
        case 'BO':
          source = bo;
          break;
        case 'CD':
          source = cd;
          break;
        default:
          source = null;
          break;
      }
      if (source) {
        source.total += 1;
        if (store.state === 2) {
          source.down.push(store);
        }
      }
    });

    [cd, bo, adm].forEach((group) => {
      const resume = {
        name: group.name,
        status: group.total === 0 ? 'UNKNOWN' : group.down.length > 0 ? 'CRITICAL' : 'OK',
        states: [
          { value: `${group.down.length}/${group.total - group.down.length}`, name: 'down / up' },
          {
            value: `${group.withUptimeProblems.length}/${group.total - group.withUptimeProblems.length}`,
            name: 'uptime',
          },
        ],
      };
      groupIndicators.push(
        <div className="category-item">
          <Card status={resume.status} secondary>
            <CardTitle title={resume.name} icon="cd_bo_adm" info="" secondary />
            <CardStates states={resume.states} />
          </Card>
        </div>
      );
    });

    return groupIndicators;
  }
  return (
    <section className="noc-details">
      <div className="title-wrap">
        <div className="title">{country.name}</div>
        <div className="title-alerts">{`${resume.totalStoreProblems} ALERTAS ACTIVAS TOTALES`}</div>
      </div>
      <div className="noc-details-body">
        <div className="noc-details-side">
          <div className="label-message">RESUMEN | ALERTAS ACTIVAS</div>
          <div className="resume-item">
            <Card status={resume.state}>
              <CardTitle title={resume.name} icon="cd_bo_adm" info={resume.totalStoreProblems} status={resume.state} />
              <CardStates states={resume.storeStates} />
            </Card>
          </div>
          <div>
            {/* <button onClick={onBack}>VOLVER</button> */}
            <div style={{ fontSize: '16px', cursor: 'pointer' }} onClick={onBack}>
              &larr; Volver
            </div>
          </div>
        </div>
        <div className="noc-details-content">
          <div className="category-items">{getGroupItems()}</div>
          <div className="active-alerts-noc">
            <TableActiveAlerts data={getStoresTable()} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NocDetailsCD;
