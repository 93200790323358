import React from 'react';

import TooltipProblems from '../TooltipProblems/TooltipProblems';
import { buildUrlHostProblems } from '../../utils/common.js';

import './CardNetworkDevice.css';

function CardNetworkDevice({
  problems = [],
  title = 'Tiendas down',
  label = 'DOWN / UP',
  down = 0,
  up = 0,
  color = '#ED4C5C',
  siteUrl,
  onSelectGroup = () => {},
}) {
  function handleClickCmpt() {
    onSelectGroup({ group: title, problems: problems });
  }

  const labelStatus = `${down} / ${up}`;
  let problemsSect = null;
  if (problems && problems.length) {
    problemsSect = <div className="problems">{<TooltipProblems items={problems} siteUrl={siteUrl} />}</div>;
  }
  return (
    <section
      className="card-network-device-cmpt"
      onClick={handleClickCmpt}
      style={{ cursor: problems.length ? 'pointer' : 'default' }}
    >
      <div className="header">
        <div className="title-card">{title}</div>
        <div className="label-card">{label}</div>
      </div>
      <div className="information">
        <div className="status" style={{ backgroundColor: color }}>
          {labelStatus}
          {problemsSect}
        </div>
      </div>
    </section>
  );
}

export default CardNetworkDevice;
