// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datacenter .lines{
    width: 23%;
    position: relative;
}

.datacenter .lines .line {
    position: absolute;
    background-color: #60b2e7;
    height: 3px;
}

.datacenter .lines .redline {
    position: absolute;
    background-color: #e76560;
    height: 3px;
}

.datacenter .lines .direct-connect-line {
    position: absolute;
    background-color: darkcyan;
    height: 6px;
}

.datacenter .lines .sdwan-image {
    position: absolute;
    top: 398px;
    left: 35%;
    width: 30%;
    border-radius: 10px;
}

.datacenter .lines .direct-connect-top {
    rotate: 270deg;
    top: 590px;
    border-radius: 10px;
    left: 580px;
    position: absolute;
    width: 25px;
}

.datacenter .lines .direct-connect-bottom {
    top: 933px;
    border-radius: 10px;
    left: 76px;
    position: absolute;
    width: 25px;
}

.server-cards {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
}`, "",{"version":3,"sources":["webpack://./components/CloudNetwork/CloudNetwork.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;IAC1B,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,UAAU;IACV,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,UAAU;IACV,mBAAmB;IACnB,UAAU;IACV,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,kBAAkB;AACtB","sourcesContent":[".datacenter .lines{\n    width: 23%;\n    position: relative;\n}\n\n.datacenter .lines .line {\n    position: absolute;\n    background-color: #60b2e7;\n    height: 3px;\n}\n\n.datacenter .lines .redline {\n    position: absolute;\n    background-color: #e76560;\n    height: 3px;\n}\n\n.datacenter .lines .direct-connect-line {\n    position: absolute;\n    background-color: darkcyan;\n    height: 6px;\n}\n\n.datacenter .lines .sdwan-image {\n    position: absolute;\n    top: 398px;\n    left: 35%;\n    width: 30%;\n    border-radius: 10px;\n}\n\n.datacenter .lines .direct-connect-top {\n    rotate: 270deg;\n    top: 590px;\n    border-radius: 10px;\n    left: 580px;\n    position: absolute;\n    width: 25px;\n}\n\n.datacenter .lines .direct-connect-bottom {\n    top: 933px;\n    border-radius: 10px;\n    left: 76px;\n    position: absolute;\n    width: 25px;\n}\n\n.server-cards {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n    align-items: start;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
