import React, { useState, useEffect } from 'react';

import Sidebar from 'components/Sidebar/Sidebar';
//@ts-ignore
import Suppliers from './components/Suppliers/Suppliers';
//@ts-ignore
import Geomap from './components/Geomap/Geomap';
//@ts-ignore
import Datacenter from './components/Datacenter/Datacenter';
//@ts-ignore
import StoreCdStatus from './components/StoreCdStatus/StoreCdStatus';
//@ts-ignore
import PerformanceStores from './components/PerformanceStores/PerformanceStores';
//@ts-ignore
import Interfaces from './components/Interfaces/Interfaces';
//@ts-ignore
import ChequeosVitales from './components/ChequeosVitales/ChequeosVitales';
//@ts-ignore
import TableAlertEnlace from 'components/TableAlertEnlace/TableAlertEnlace';
//@ts-ignore
import DatacenterTable from 'components/DatacenterTable/DatacenterTable';
//@ts-ignore
import CSVHocComponent from 'components/CSVHocComponent/CSVHocComponent';
//@ts-ignore
import CardNetworkDevice from 'components/CardNetworkDevice/CardNetworkDevice';
//@ts-ignore
import CardServiceCritical from 'components/CardServiceCritical/CardServiceCritical';

import {
  getSniProblems,
  getCriticalServices,
  //@ts-ignore
} from '../../rest/checkmk.js';

import {
  getLinksByProviders,
  getSlaByProviders,
  getRegionStatusByStores,
  getRegionStatusByStoresForGraphic,
  //@ts-ignore
} from './utils/data.js';
import {
  dictComunas,
  countryDataTmp,
  // enlacesTroncales,
  //@ts-ignore
} from './utils/database.js';

import { getSaturationForDatacenter } from '../../api/link';
//@ts-ignore
import { inBOCDADM, getStoresForGraphic } from './utils/common.js';

import './NetworkPanel.css';
import { isArray } from 'lodash';

type Props = {
  hosts: any;
  siteUrl: any;
  responseServiceLinks: any;
  hostServices: any;
  allServices: any;
  baseUrl: String;
  hostsNetwork: any;
};

const NetworkPanel: React.FC<Props> = ({
  hosts = [],
  siteUrl,
  responseServiceLinks = [],
  hostServices = [],
  allServices = [],
  hostsNetwork = {},
  baseUrl,
}) => {
  const [countryData, setCountryData] = useState<any[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<any[]>([countryDataTmp[0]]);
  const [arrayRegionStatus, setArrayRegionStatus] = useState<any[]>([]);
  const [storeLinks, setStoreLinks] = useState<any[]>([]);
  const [storeInterfaces, setStoreInterfaces] = useState<any[]>([]);
  const [storesCd, setStoresCd] = useState<any>(null);
  const [showSectionStoreStatus, setShowSectionStoreStatus] = useState<Boolean>(false);
  const [regionSelected, setRegionSelected] = useState<String>('');

  // Migrated States
  const [servicesLinks, setServicesLinks] = useState<any[]>([]);
  const [servicesLinksStartsTlco, setServicesLinksStartsTlco] = useState<any[]>([]);
  const [interfaces, setInterfaces] = useState<any[]>([]);
  const [providerLinksbyCountry, setProviderLinksbyCountry] = useState<any>({ CHILE: {} });
  const [servicesLinksTroncales, setServicesLinksTroncales] = useState<any>([]);
  const [servicesLinksStores, setServicesLinksStores] = useState<any>({ CHILE: [] });
  const [loadFirstTime, setLoadFirstTime] = useState(false);
  const [supplierSelected, setSupplierSelected] = useState('');
  const [selectedGroupProblem, setSelectedGroupProblem] = useState<any>(null);

  // Sni problems
  const [enlacesProblems, setEnlacesProblems] = useState<any[]>([]);
  const [storesProblems, setStoresProblems] = useState<any[]>([]);

  // Critical services
  const [criticalServices, setCriticalServices] = useState<any[]>([]);

  function loadDashboard(responseServiceLinks: any[]) {
    const services_links_filtered: any[] = [];
    const services_starts_tlco: any[] = [];
    const services_sla_store: any[] = [];
    const services_interfaces: any[] = [];

    responseServiceLinks.forEach((sl: any) => {
      if (sl.description.includes('Interface')) {
        services_interfaces.push({ ...sl });
        if (
          (sl.description.includes('CS:') && sl.description.includes('TLCO:')) ||
          (sl.plugin_output.includes('CS:') && sl.plugin_output.includes('TLCO:'))
        ) {
          services_links_filtered.push({ ...sl });
        }
      } else if (sl.description.startsWith('SLA:Net-WAN')) {
        services_sla_store.push({ ...sl });
      }
      if (sl.description.includes('TLCO:')) {
        if (sl.description.includes('OSPF') || sl.description.includes('BGP:')) {
          services_links_filtered.push({ ...sl });
        }
        if (sl.description.startsWith('TLCO:')) {
          services_starts_tlco.push({ ...sl });
        }
      }
    });
    setServicesLinksStartsTlco(services_starts_tlco);

    const linksStore = getSlaByProviders(hostsNetwork['allHosts'], services_sla_store);

    setServicesLinksStores(linksStore.CHILE ? linksStore : { CHILE: {} });

    const providersByCountry = getLinksByProviders(hostsNetwork['allHosts'], services_links_filtered, enlacesProblems);

    setProviderLinksbyCountry(providersByCountry.CHILE ? providersByCountry : { CHILE: {}, ARGENTINA: {} });
    setServicesLinks(services_links_filtered);
    setInterfaces(services_interfaces);
  }

  function configureData() {
    let hostsFiltered: any[] = [];
    let hostsFilteredByDatacenter: any[] = [];
    const selectedCountryName = selectedCountries[0].country.toLowerCase();
    if (hostsNetwork && hostsNetwork[selectedCountryName]) {
      hostsFiltered = hostsNetwork[selectedCountryName]['stores'];
      hostsFilteredByDatacenter = hostsNetwork[selectedCountryName]['datacenter'];
    }

    const dictHost: any = {};
    hostsFiltered.forEach((eachHost: any) => {
      if (!dictHost[eachHost.name]) {
        dictHost[eachHost.name] = eachHost;
      }
    });

    const dictHostDatacenter: any = {};
    hostsFilteredByDatacenter.forEach((eachHost: any) => {
      if (!dictHostDatacenter[eachHost.name]) {
        dictHostDatacenter[eachHost.name] = eachHost;
      }
    });
    const copyServicesLinksByDatacenter = [...JSON.parse(JSON.stringify(servicesLinks)), ...servicesLinksStartsTlco];
    copyServicesLinksByDatacenter.forEach((link: any) => {
      if (dictHostDatacenter[link.host_name]) {
        link.host = dictHostDatacenter[link.host_name];
      }
      link.data = getSaturationForDatacenter(link.plugin_output);
    });

    servicesLinks.forEach((link: any) => {
      if (dictHost[link.host_name]) {
        link.host = dictHost[link.host_name];
      }
      link.data = getSaturationForDatacenter(link.plugin_output);
    });
    interfaces.forEach((link: any) => {
      if (dictHost[link.host_name]) {
        link.host = dictHost[link.host_name];
      }
      link.data = getSaturationForDatacenter(link.plugin_output);
    });

    const storeEnlaces = servicesLinks.filter((link: any) => {
      return link.host;
    });

    const datacenterEnlaces = copyServicesLinksByDatacenter.filter((link: any) => {
      return link.host;
    });
    setServicesLinksTroncales(datacenterEnlaces);

    const interfacesWithProblems = interfaces.filter((link: any) => {
      return link.host && link.host.tags.TIENDA !== 'DATACENTER';
    });

    interfacesWithProblems.sort((a: any, b: any) => {
      return b.priority - a.priority;
    });

    const stores = getStoresForGraphic(hostsFiltered);

    const regionsGraphic = getRegionStatusByStoresForGraphic(
      stores,
      enlacesProblems,
      storesProblems,
      responseServiceLinks,
      hostsNetwork['allHosts']
    );
    setArrayRegionStatus(regionsGraphic);
    const regions = getRegionStatusByStores(stores);
    let globalStatus = 0;
    regions.forEach((region: any) => {
      globalStatus = globalStatus < region.status ? region.status : globalStatus;
    });
    countryDataTmp[0].generalStatus = globalStatus;

    setStoreLinks(storeEnlaces);
    setStoreInterfaces(interfacesWithProblems);
    setCountryData(countryDataTmp);
  }

  function setCountry(country: string) {
    const countryDataTmp = countryData.map((countryItem: any) => {
      if (countryItem.site === country) {
        countryItem.selected = true;
      } else {
        countryItem.selected = false;
      }
      return countryItem;
    });
    const selected = countryDataTmp.filter((countryItem: any) => countryItem.selected);
    setCountryData(countryDataTmp);
    setSelectedCountries(selected);
  }

  function getResumeItems() {
    const resumeCD = {
      up: 0,
      down: 0,
      problems: [],
    };
    const resumeStores = {
      up: 0,
      down: 0,
      problems: [],
    };
    selectedCountries.forEach((country: any, index: any) => {
      const hostsFiltered = hosts.filter((host: any) => {
        return (
          (host.tags.SITE === country.country.toUpperCase() || host.tags.SITE === country.site.toUpperCase()) &&
          host.tags.TIENDA !== 'DATACENTER'
        );
      });
      const allStores = getStoresForGraphic(hostsFiltered);
      const stores = {
        total: 0,
        down: [],
        withProblems: [],
      };
      const cd = {
        total: 0,
        down: [],
        withProblems: [],
      };
      allStores.forEach((store: any) => {
        let source: any = stores;

        if (store.bocdadm) {
          source = cd;
        }
        if (storesProblems.some((storePrb: any) => storePrb.brand === store.name && storePrb.code === store.code)) {
          source.down.push(store);
          source.withProblems.push(store);
        }
        source.total += 1;
      });
      resumeCD.down += cd.down.length;
      resumeCD.up += cd.total - cd.down.length;
      resumeCD.problems = cd.withProblems;
      resumeStores.down += stores.down.length;
      resumeStores.up += stores.total - stores.down.length;
      resumeStores.problems = stores.withProblems;
    });
    return { resumeCD, resumeStores };
  }

  function handleShowSectionStoreStatus(region: String) {
    setRegionSelected(region);
    setShowSectionStoreStatus(true);
  }

  function getHourWithFormate(last_state_change: number) {
    let hour;
    const currentDate = new Date(last_state_change * 1000);
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let seconds = currentDate.getSeconds();
    let formattedDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    let formattedTime =
      (hours < 10 ? '0' : '') +
      hours +
      ':' +
      (minutes < 10 ? '0' : '') +
      minutes +
      ':' +
      (seconds < 10 ? '0' : '') +
      seconds;
    hour = `${formattedDate} ${formattedTime}`;
    return hour;
  }

  function alertsForGraphicInfo() {
    const arrayProblems: any[] = [];
    const storeProblems: any = {};
    let arrayRegionInfo = arrayRegionStatus.find((reg: any) => reg.region === regionSelected);

    if (arrayRegionInfo) {
      for (const key in arrayRegionInfo['serviceArrayProblems']) {
        const [store, codeStore] = key.split('-');
        const problemsByStore = arrayRegionInfo['serviceArrayProblems'][key];
        problemsByStore.forEach((prByStore: any) => {
          const com = getComuna(prByStore.host_name);
          const alertObject = {
            status: prByStore.state,
            lastChange: prByStore.last_state_change ? prByStore.last_state_change.split('.')[0] : '',
            brand: store,
            code: codeStore,
            location: com,
            hostname: prByStore.host_name,
            ip: prByStore.ip,
            service: prByStore.description,
            details: prByStore.plugin_output,
            acknowledged: prByStore.acknowledged === 1 ? true : false,
            site: prByStore.site,
            typeProblem: prByStore.typeProblem ? prByStore.typeProblem : '',
            storeColor: prByStore.storeColor ? prByStore.storeColor : '',
          };
          if (prByStore.description === 'Ping') {
            if (prByStore.typeProblem === 'store') {
              if (!storeProblems[`${store}-${codeStore}`]) {
                storeProblems[`${store}-${codeStore}`] = {
                  ...alertObject,
                  hostname: '',
                  ip: '',
                  service: 'Tienda caida',
                  description: 'Tienda caida',
                };
              }
            }
          } else {
            arrayProblems.push(alertObject);
          }
        });
      }
      for (const store in storeProblems) {
        arrayProblems.push(storeProblems[store]);
      }
      return arrayProblems;
    } else {
      return [];
    }
  }

  function alertasForSuppliersInfo() {
    const arrayProblems: any[] = [];
    const arrayProviders = Object.entries(providerLinksbyCountry[selectedCountries[0].country.toUpperCase()]);
    arrayProviders.forEach(([supplier, services]: [any, any], index) => {
      if (supplier === supplierSelected) {
        const problems = services.filter((service: any) => service.state !== 0);
        problems.forEach((problem: any) => {
          arrayProblems.push({
            status: problem.state,
            // lastChange: getHourWithFormate(problem.last_state_change),
            lastChange: problem.last_state_change ? problem.last_state_change.split('.')[0] : '',
            brand: problem.tienda,
            code: problem.codigo_de_tienda,
            location: getComuna(problem.host_name),
            hostname: problem.host_name,
            ip: problem.address,
            service: problem.description,
            details: problem.plugin_output,
            acknowledged: problem.acknowledged === 1 ? true : false,
            site: problem.site,
            typeProblem: problem.typeProblem ? problem.typeProblem : '',
            storeColor: problem.storeColor ? problem.storeColor : '',
          });
        });
      }
    });
    return arrayProblems;
  }

  function alertasForAllSuppliersInfo() {
    const arrayProblems: any[] = [];
    const arrayProviders = Object.entries(providerLinksbyCountry[selectedCountries[0].country.toUpperCase()]);
    const allServicesWithProblems: any[] = [];
    arrayProviders.forEach(([supplier, services]: [any, any]) => {
      const problems = services.filter((service: any) => service.state !== 0);
      problems.forEach((problem: any) => {
        allServicesWithProblems.push({ ...problem, supplier: supplier });
      });
    });
    allServicesWithProblems.forEach((problem: any) => {
      arrayProblems.push({
        status: problem.state,
        lastChange: problem.last_state_change ? problem.last_state_change.split('.')[0] : '',
        brand: problem.tienda,
        code: problem.host?.tags?.CODIGO_DE_TIENDA,
        location: getComuna(problem.host_name),
        hostname: problem.host_name,
        ip: problem.address,
        service: problem.description,
        details: problem.plugin_output,
        acknowledged: problem.acknowledged === 1 ? true : false,
        site: problem.site,
        typeProblem: problem.typeProblem ? problem.typeProblem : '',
        storeColor: problem.storeColor ? problem.storeColor : '',
        supplier: problem.supplier,
      });
    });
    return arrayProblems;
  }

  function alertSupplierFilterInfo() {
    let brand: any[] = [];
    let comuna: any[] = [];
    const arrayProviders = Object.entries(providerLinksbyCountry[selectedCountries[0].country.toUpperCase()]);
    arrayProviders.forEach(([supplier, services]: [any, any], index) => {
      if (supplier === supplierSelected) {
        const problems = services.filter((service: any) => service.state !== 0);
        problems.forEach((problem: any) => {
          const com = getComuna(problem.host_name);
          if (!brand.some((br: any) => br.value === problem.tienda)) {
            brand.push({ label: problem.tienda, value: problem.tienda });
          }
          if (!comuna.some((br: any) => br.value === com)) {
            comuna.push({ label: com, value: com });
          }
        });
      }
    });
    brand.push({ label: 'Todos', value: 'Todos' });
    comuna.push({ label: 'Todos', value: 'Todos' });
    return [
      {
        filter: 'brand',
        placeholder: 'Tipo de tienda',
        options: brand,
      },
      {
        filter: 'location',
        placeholder: 'Comuna',
        options: comuna,
      },
    ];
  }

  function alertAllSupplierFilterInfo() {
    let brand: any[] = [];
    let comuna: any[] = [];
    let arraySupplier: any[] = [];
    const arrayProviders = Object.entries(providerLinksbyCountry[selectedCountries[0].country.toUpperCase()]);
    arrayProviders.forEach(([supplier, services]: [any, any], index) => {
      const problems = services.filter((service: any) => service.state !== 0);
      problems.forEach((problem: any) => {
        const com = getComuna(problem.host_name);
        if (!brand.some((br: any) => br.value === problem.tienda)) {
          brand.push({ label: problem.tienda, value: problem.tienda });
        }
        if (!comuna.some((br: any) => br.value === com)) {
          comuna.push({ label: com, value: com });
        }
        if (!arraySupplier.some((br: any) => br.value === supplier)) {
          arraySupplier.push({ label: supplier, value: supplier });
        }
      });
    });
    brand.push({ label: 'Todos', value: 'Todos' });
    comuna.push({ label: 'Todos', value: 'Todos' });
    arraySupplier.push({ label: 'Todos', value: 'Todos' });
    return [
      {
        filter: 'brand',
        placeholder: 'Tipo de tienda',
        options: brand,
      },
      {
        filter: 'location',
        placeholder: 'Comuna',
        options: comuna,
      },
      {
        filter: 'supplier',
        placeholder: 'Proveedor',
        options: arraySupplier,
      },
    ];
  }

  function alertasForGroupInfo() {
    const arrayProblems: any[] = [];
    selectedGroupProblem.problems.forEach((problem: any) => {
      arrayProblems.push({
        status: problem.state !== 0 ? 2 : 0,
        lastChange: getHourWithFormate(problem.last_state_change),
        brand: problem.brand,
        code: problem.codeStore,
        location: getComuna(problem.name),
        hostname: problem.name,
        ip: problem.ip,
        service: problem.service,
        acknowledged: problem.acknowledged === 1 ? true : false,
        site: problem.site,
      });
    });
    return arrayProblems;
  }

  function alertGroupFilterInfo() {
    let brand: any[] = [];
    let comuna: any[] = [];
    selectedGroupProblem.problems.forEach((problem: any) => {
      const com = getComuna(problem.name);
      if (!brand.some((br: any) => br.value === problem.brand)) {
        brand.push({ label: problem.brand, value: problem.brand });
      }
      if (!comuna.some((br: any) => br.value === com)) {
        comuna.push({ label: com, value: com });
      }
    });
    brand.push({ label: 'Todos', value: 'Todos' });
    comuna.push({ label: 'Todos', value: 'Todos' });
    return [
      {
        filter: 'brand',
        placeholder: 'Tipo de tienda',
        options: brand,
      },
      {
        filter: 'location',
        placeholder: 'Comuna',
        options: comuna,
      },
    ];
  }

  function getComuna(hostname: String) {
    for (const key of Object.keys(dictComunas)) {
      if (hostname.includes(key)) {
        return dictComunas[key];
      }
    }
    return null;
  }

  function getDataForCriticalServices() {
    let fallServices = 0;
    let totalServices = 0;
    const serviceName = 'Imolog';
    let lastFall = '--';
    let status = -1;
    let timestampLastFall = 0;

    criticalServices.forEach((srvCrit: any) => {
      totalServices++;
      let tempStatus = 3;
      let timestampCurrentSrv = new Date(srvCrit.svc_state_age).getTime();

      if (srvCrit.service_state !== 'OK') {
        fallServices++;
        if (srvCrit.service_state === 'WARN' || srvCrit.service_state === 'WARNING') {
          tempStatus = 1;
          if (timestampLastFall < timestampCurrentSrv) {
            timestampLastFall = timestampCurrentSrv;
            lastFall = srvCrit.svc_state_age;
          }
          lastFall = srvCrit.svc_state_age;
        } else if (srvCrit.service_state === 'CRIT' || srvCrit.service_state === 'CRITICAL') {
          tempStatus = 2;
          if (timestampLastFall < timestampCurrentSrv) {
            timestampLastFall = timestampCurrentSrv;
            lastFall = srvCrit.svc_state_age;
          }
        }
      } else {
        tempStatus = 0;
      }
      if (tempStatus > status) {
        status = tempStatus;
      }
    });
    return {
      CHILE: (
        <CardServiceCritical
          serviceName={serviceName}
          up={totalServices - fallServices}
          down={fallServices}
          status={status}
          alertas={fallServices}
          lastFall={lastFall}
        />
      ),
    } as any;
  }

  function alertEnlaceFilterInfo() {
    let brand: any[] = [];
    let comuna: any[] = [];
    let alertType: any[] = [
      { label: 'Tienda', value: 'Tienda' },
      { label: 'Enlace', value: 'Enlace' },
      { label: 'Todos', value: 'Todos' },
    ];
    let arrayRegionInfo = arrayRegionStatus.find((reg: any) => reg.region === regionSelected);
    if (arrayRegionInfo) {
      for (const key in arrayRegionInfo['serviceArrayProblems']) {
        const [store] = key.split('-');
        const problemsByStore = arrayRegionInfo['serviceArrayProblems'][key];
        problemsByStore.forEach((prByStore: any) => {
          const com = getComuna(prByStore.host_name);
          if (!brand.some((br: any) => br.value === store)) {
            brand.push({ label: store, value: store });
          }
          if (!comuna.some((br: any) => br.value === com)) {
            comuna.push({ label: com, value: com });
          }
        });
      }
      brand.push({ label: 'Todos', value: 'Todos' });
      comuna.push({ label: 'Todos', value: 'Todos' });
      return [
        {
          filter: 'brand',
          placeholder: 'Tipo de tienda',
          options: brand,
        },
        {
          filter: 'location',
          placeholder: 'Comuna',
          options: comuna,
        },
        {
          filter: 'alertType',
          placeholder: 'Tipo de alerta',
          options: alertType,
        },
      ];
    } else {
      return [];
    }
  }

  async function loadStoresAndEnlaceProblems() {
    const responseProblems = await getSniProblems(baseUrl);
    const arrayBgpProblems: any = [];
    const arrayStoreProblems: any = [];
    
    if (responseProblems && isArray(responseProblems)) {
      responseProblems.forEach((problem) => {
        const extraData = JSON.parse(problem.custom_data.value);
        const hostname = extraData.host.replace(/^\[multivariable\] /, "");
        
        const objectToAdd = {
          brand: hostsNetwork['allHosts'][hostname].tags.TIENDA,
          code: hostsNetwork['allHosts'][hostname].tags.CODIGO_DE_TIENDA,
          host: hostname,
          ip: extraData.address,
          last_state_change: problem.created,
          country: hostsNetwork['allHosts'][hostname].tags.SITE.toLowerCase(),
        };
        if (problem.service_name.toLowerCase().includes('bgp')) {
          arrayBgpProblems.push({
            ...objectToAdd,
            service: extraData.service_displayname,
            service_code: extraData.enlace_codigo_servicio,
          });
        } else {
          arrayStoreProblems.push({ ...objectToAdd, service: 'Tienda caida' });
        }
      });
    }
    setEnlacesProblems(arrayBgpProblems);
    setStoresProblems(arrayStoreProblems);
  }

  useEffect(() => {
    if (hosts && hosts.length && hostsNetwork) {
      loadStoresAndEnlaceProblems();
    }
    //eslint-disable-next-line
  }, [hosts, hostsNetwork, allServices]);

  useEffect(() => {
    loadDashboard(responseServiceLinks);
    // eslint-disable-next-line
  }, [responseServiceLinks, hostsNetwork, enlacesProblems]);

  useEffect(() => {
    if (!loadFirstTime) {
      setLoadFirstTime(true);
      setSelectedCountries([countryDataTmp[0]]);
    } else {
      const selected = countryDataTmp.find((countryInfo: any) => countryInfo.country === selectedCountries[0].country);
      setSelectedCountries([selected]);
    }
    //eslint-disable-next-line
  }, [hosts, hostsNetwork]);

  useEffect(() => {
    if (selectedCountries && selectedCountries.length) {
      const { resumeCD, resumeStores } = getResumeItems();
      setStoresCd({ resumeCD, resumeStores });
    }
    //eslint-disable-next-line
  }, [selectedCountries, enlacesProblems, storesProblems]);

  async function obtainGetCriticalServices() {
    if (baseUrl) {
      const criticalServices = await getCriticalServices(baseUrl);
      setCriticalServices(criticalServices);
    }
  }

  useEffect(() => {
    obtainGetCriticalServices();
    // eslint-disable-next-line
  }, [hosts, hostsNetwork, allServices]);

  useEffect(() => {
    configureData();
    // eslint-disable-next-line
  }, [selectedCountries, hosts, enlacesProblems, storesProblems, hostsNetwork]);

  function handleOnCloseAlertTable() {
    setShowSectionStoreStatus(false);
    setSupplierSelected('');
    setSelectedGroupProblem(null);
  }

  function getTableAlertEnlaceCmpt() {
    if (supplierSelected) {
      if (supplierSelected === 'all') {
        return (
          <TableAlertEnlace
            title={`Estado de enlaces ${supplierSelected.toUpperCase()}`}
            arrayAlerts={alertasForAllSuppliersInfo()}
            arrayFilters={alertAllSupplierFilterInfo()}
            onClose={handleOnCloseAlertTable}
            siteUrl={siteUrl}
            style={{ width: '1592px' }}
          />
        );
      } else {
        return (
          <TableAlertEnlace
            title={`Estado de enlaces ${supplierSelected.toUpperCase()}`}
            arrayAlerts={alertasForSuppliersInfo()}
            arrayFilters={alertSupplierFilterInfo()}
            onClose={handleOnCloseAlertTable}
            siteUrl={siteUrl}
            style={{ width: '1592px' }}
          />
        );
      }
    } else if (showSectionStoreStatus) {
      return (
        <TableAlertEnlace
          title={'Estado General de las tiendas'}
          arrayAlerts={alertsForGraphicInfo()}
          arrayFilters={alertEnlaceFilterInfo()}
          onClose={handleOnCloseAlertTable}
          siteUrl={siteUrl}
          style={{ width: '1192px' }}
        />
      );
    } else {
      return (
        <TableAlertEnlace
          title={`Estado de ${selectedGroupProblem.group.toUpperCase()}`}
          arrayAlerts={alertasForGroupInfo()}
          arrayFilters={alertGroupFilterInfo()}
          onClose={handleOnCloseAlertTable}
          siteUrl={siteUrl}
          style={{ width: '1143px' }}
        />
      );
    }
  }

  return (
    <section className="network-panel-cmpt">
      <div className="sidebar-network-panel">
        <Sidebar countryInfo={countryData} setCountryFn={setCountry} />
      </div>
      {selectedGroupProblem ? (
        <>
          {getTableAlertEnlaceCmpt()}
          <>
            <div className="network-status-wrap">
              <StoreCdStatus
                selectedCountries={selectedCountries}
                hosts={hosts}
                storesCd={storesCd}
                siteUrl={siteUrl}
                onSelectGroup={setSelectedGroupProblem}
              />
              <Datacenter
                servicesLinksTroncales={servicesLinksTroncales}
                servicesLinksStores={servicesLinksStores}
                selectedCountries={selectedCountries}
              />
              <div className="services-country">
                <div className="title-services-country">{'Servicios criticos pais'}</div>
                <div className="first-line">
                  {getDataForCriticalServices()[selectedCountries[0].country.toUpperCase()]
                    ? getDataForCriticalServices()[selectedCountries[0].country.toUpperCase()]
                    : null}
                </div>
              </div>
            </div>
          </>
        </>
      ) : (
        <>
          <Suppliers
            providerLinksbyCountry={providerLinksbyCountry}
            selectedCountries={selectedCountries}
            onSelectSupplier={setSupplierSelected}
          />
          {supplierSelected ? (
            <>{getTableAlertEnlaceCmpt()}</>
          ) : (
            <>
              <Geomap
                storesCd={storesCd}
                arrayRegionStatus={arrayRegionStatus}
                selectedCountries={selectedCountries}
                handleShowSectionStoreStatus={handleShowSectionStoreStatus}
              />
              {showSectionStoreStatus ? (
                getTableAlertEnlaceCmpt()
              ) : (
                <>
                  <div className="active-alerts-wrap">
                    <PerformanceStores selectedCountries={selectedCountries} storeLinks={storeLinks} />
                    <Interfaces selectedCountries={selectedCountries} storeInterfaces={storeInterfaces} />
                    <ChequeosVitales
                      allServices={allServices}
                      allHosts={hostsNetwork['allHosts']}
                      selectedCountries={selectedCountries[0].site}
                    />
                  </div>
                  <div className="network-status-wrap">
                    <StoreCdStatus
                      selectedCountries={selectedCountries}
                      hosts={hosts}
                      storesCd={storesCd}
                      siteUrl={siteUrl}
                      onSelectGroup={setSelectedGroupProblem}
                    />
                    <Datacenter
                      servicesLinksTroncales={servicesLinksTroncales}
                      servicesLinksStores={servicesLinksStores}
                      selectedCountries={selectedCountries}
                    />
                    <div className="services-country">
                      <div className="title-services-country">{'Servicios criticos pais'}</div>
                      <div className="first-line">
                        {getDataForCriticalServices()[selectedCountries[0].country.toUpperCase()]
                          ? getDataForCriticalServices()[selectedCountries[0].country.toUpperCase()]
                          : null}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default NetworkPanel;
