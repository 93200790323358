import {
  region_dictionary,
  region_dictionary_brasil,
  region_dictionary_colombia,
} from '../constants/country_map_info.ts';
import { getSaturationFromServiceOutput, getSaturationForDatacenter } from './link.ts';
import { dictNamesBySite } from './constants/common.js';

function extractRegionFromHostname(hostname) {
  let city = hostname.substring(2, 5);
  return region_dictionary[city] ? city : '';
}

export function getHostsServicesMap(services) {
  const hostsServices = {};
  services.forEach((service) => {
    const key = service.host_name;
    if (key) {
      if (!hostsServices[key]) {
        hostsServices[key] = [];
      }
      hostsServices[key].add(service);
    }
  });
  return hostsServices;
}

export function getHostsServicesMapCloud(services, hosts) {
  const hostsServices = {};
  services.forEach((service) => {
    const key = service.host_name;
    const hostSelected = hosts.find((ht) => ht.name === key);
    if (hostSelected) {
      service.address = hostSelected.address;
      service.site = hostSelected.tags.site;
      service.country = hostSelected.tags.SITE;
      if (key) {
        if (!hostsServices[key]) {
          hostsServices[key] = [];
        }
        hostsServices[key].add(service);
      }
    }
  });
  return hostsServices;
}

export function getGraphicInfo(hosts, links, graphicInfo) {
  const infoGrapchic = {
    'PER-PE-02': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'PER-PE-01': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'COL-PE-01': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'COL-PE-02': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'BRA-PE-02': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'BRA-PE-01': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'ARG-PE-02': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'ARG-PE-01': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'CHL-PE-01': {
      status: 3,
      type: 'side',
      connections: [],
    },
    'CHL-PE-02': {
      status: 3,
      type: 'side',
      connections: [],
    },
  };

  const arrayToGrap = [];
  const arrayToList = [];
  const dictStatus = { OK: 0, WARN: 1, CRIT: 2, UNK: 3 };

  try {
    function getHostState(hostState) {
      if (hostState || hostState === 0) {
        if (hostState === 1 || hostState === 2) {
          return 2;
        }
        return 0;
      } else {
        return 3;
      }
    }
    for (const ht in graphicInfo) {
      const infoEnlace = graphicInfo[ht];

      const enlaceType = infoEnlace.type;
      const connectionsEnlace = infoEnlace.conections;
      const hostForStatus = hosts.find((host) => host.name === ht);
      const statusDevice = hostForStatus ? getHostState(hostForStatus.state) : 3;
      const ipDevice = hostForStatus ? hostForStatus.address : 'null';
      infoGrapchic[ht]['status'] = statusDevice;
      infoGrapchic[ht]['ip'] = ipDevice;
      infoGrapchic[ht]['type'] = enlaceType;
      connectionsEnlace.forEach((cnEn) => {
        const speed = cnEn.speed;
        const to = cnEn.to;
        const label = cnEn.label;
        let spd = '';
        let usein = '';
        let useout = '';
        let enlaceState = 3;
        let problems = 0;
        if (cnEn.interf && cnEn.neighbor) {
          const serviceInterf = links.find((link) => link.service_description === cnEn.interf);
          if (serviceInterf) {
            const interfaceStatus = dictStatus[serviceInterf.service_state];
            const valuesIntf = getSaturationForDatacenter(serviceInterf.svc_plugin_output);
            if (valuesIntf) {
              infoGrapchic[ht]['output'] = valuesIntf;
              spd = valuesIntf.speed;
              usein = `${valuesIntf.in} (${valuesIntf.inPercentage}%)`;
              useout = `${valuesIntf.out} (${valuesIntf.outPercentage}%)`;
            }
            if (interfaceStatus !== 0) {
              problems += 1;
            }
          }
          const serviceNeighbor = links.find((link) => link.service_description === cnEn.neighbor);
          if (serviceNeighbor) {
            const neighborStatus = dictStatus[serviceNeighbor.service_state];
            enlaceState = neighborStatus;
            if (neighborStatus !== 0) {
              problems += 1;
            }
          }
        }

        infoGrapchic[ht]['connections'].push({
          to,
          speed,
          label,
          state: enlaceState,
          problems,
          site: hostForStatus && hostForStatus.tags ? hostForStatus.tags.site : 'NREDES_CL2',
          spd,
          usein,
          useout,
        });
      });
    }
    for (const ht in infoGrapchic) {
      let stringInfo = '';
      const host = infoGrapchic[ht];
      host['name'] = ht;
      stringInfo = `${host.type};${ht};${host.status};`;
      if (host.output) {
        stringInfo = stringInfo + `${host.output.in};${host.output.out};`;
      }
      host.connections.forEach((eachCon) => {
        const hostToStatus = hosts.find((hostParameter) => eachCon.to === hostParameter.name);
        stringInfo = stringInfo + `${eachCon.to}|${eachCon.speed}|${eachCon.state}|${eachCon.site},`;
        const newItemToList = {
          from: ht,
          fromStatus: getHostState(host.status),
          fromIp: host.ip,
          to: eachCon.to,
          label: eachCon.label,
          toStatus: hostToStatus ? getHostState(hostToStatus.state) : 3,
          status: eachCon.state,
          speed: eachCon.speed,
          problems: eachCon.problems,
          site: eachCon.site,
          spd: eachCon.spd,
          usein: eachCon.usein,
          useout: eachCon.useout,
        };
        newItemToList[`${host.name}`] = getHostState(host.status);
        newItemToList[`${hostToStatus.name}`] = getHostState(hostToStatus.state);
        arrayToList.push(newItemToList);
      });
      arrayToGrap.push(stringInfo);
    }
  } catch (e) {
    console.log(e);
    console.log('Error getting infro from datasource');
  }
  return { arrayToGraphic: arrayToGrap, arrayToList };
}

export function getHostDetailDatacenter(hosts, hostServices, linksServices, links, arrayInterfaces, arrayNeighbors) {
  const alertsByCountry = {};
  const hostWithDetailsDatacenter = [];
  let problemsWithEnlaces = [];
  let allProblemsServ = [];
  const arrayCountryEnlaces = [
    'CHL-PE-01',
    'CHL-PE-02',
    'PER-PE-01',
    'PER-PE-02',
    'COL-PE-01',
    'COL-PE-02',
    'BRA-PE-01',
    'BRA-PE-02',
    'ARG-PE-01',
    'ARG-PE-02',
    // 'CHL-RR-01',
    // 'ARG-RR-01',
  ];

  hosts.forEach((host) => {
    if (
      host.tags.TIENDA === 'DATACENTER' &&
      (host['tags']['SITE'] === 'CHILE' ||
        host['tags']['SITE'] === 'REDES_BR' ||
        host['tags']['SITE'] === 'ARGENTINA' ||
        host['tags']['SITE'] === 'REDES_PE' ||
        host['tags']['SITE'] === 'REDES_CO')
    ) {
      hostWithDetailsDatacenter.push(host);
    }
  });
  const hostDetails = addHostDetailDatacenter(hostWithDetailsDatacenter, hostServices);
  const linkDetails = getEnlacesInfo(hostWithDetailsDatacenter, links, arrayInterfaces, arrayNeighbors);

  hostDetails.forEach((host) => {
    if (!alertsByCountry[host.tags['SITE']]) {
      alertsByCountry[host.tags['SITE']] = {};
    }
    if (!alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']] = {
        switch: [],
        router: [],
        firewall: [],
        wireless: [],
        telefonia: [],
        enlaces: [],
        others: [],
        all_hosts: {},
      };
    }
    alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['all_hosts'][host.name] = host;
    if (host.tags.TIPO_DE_DISPOSITIVO.includes('SWITCH')) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['switch'].push(host);
    } else if (host.tags.TIPO_DE_DISPOSITIVO.includes('ROUTER')) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['router'].push(host);
    } else if (host.tags.TIPO_DE_DISPOSITIVO.includes('FIREWALL')) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['firewall'].push(host);
    } else if (host.tags.TIPO_DE_DISPOSITIVO.includes('WIRELESS')) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['wireless'].push(host);
    } else if (host.tags.TIPO_DE_DISPOSITIVO.includes('TELEFONIA')) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['telefonia'].push(host);
    } else if (host.tags.TIPO_DE_DISPOSITIVO.includes('ENLACE')) {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['enlaces'].push(host);
    } else {
      alertsByCountry[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]['others'].push(host);
    }
  });
  const arrayInfoDatacentersByCountry = [];
  for (const country in alertsByCountry) {
    const countryInfo = alertsByCountry[country];
    for (const dataCenter in countryInfo) {
      const infoToDatacenter = { name: country, totalAlerts: 0, arrayDatacenter: [] };
      const infoDatacenterForArray = {
        name: dataCenter,
        status: 0,
        alertsDt: 0,
        switch: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          okServices: 0,
          warningServices: 0,
          criticalServices: 0,
          unknownServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
        router: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          okServices: 0,
          warningServices: 0,
          criticalServices: 0,
          unknownServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
        firewall: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          okServices: 0,
          warningServices: 0,
          criticalServices: 0,
          unknownServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
        wireless: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          okServices: 0,
          warningServices: 0,
          criticalServices: 0,
          unknownServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
        enlaces: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          okServices: 0,
          warningServices: 0,
          criticalServices: 0,
          unknownServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
        telefonia: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          okServices: 0,
          warningServices: 0,
          criticalServices: 0,
          unknownServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
        others: {
          total: 0,
          ok: 0,
          label: 'UP / TOTAL',
          status: 0,
          totalServices: 0,
          okServices: 0,
          warningServices: 0,
          criticalServices: 0,
          unknownServices: 0,
          problemServices: 0,
          arrayServiceProblems: [],
        },
      };

      const devices = countryInfo[dataCenter];
      for (const deviceType in devices) {
        let isEnlaceCritical = false;
        if (deviceType !== 'all_hosts') {
          const arrayDevices = devices[deviceType];
          arrayDevices.forEach((dv) => {
            if (deviceType !== 'enlaces') {
              let isHostEnlace = arrayCountryEnlaces.some((countryEnlace) => countryEnlace === dv.name);
              if (dv.state === 0) {
                infoDatacenterForArray[deviceType]['ok'] += 1;
              } else {
                if (arrayCountryEnlaces.includes(dv.name)) {
                  problemsWithEnlaces.push({
                    host: dv.name,
                    service_description: 'Ping',
                    service_state: 2,
                    svc_plugin_output: 'No hay conexion',
                    ip: dv.address,
                  });
                }
              }
              infoDatacenterForArray[deviceType]['total'] += 1;
              infoDatacenterForArray[deviceType]['totalServices'] += dv.num_services;
              infoDatacenterForArray[deviceType]['problemServices'] += dv.serviceProblems;
              infoDatacenterForArray[deviceType]['warningServices'] += dv.warningServices;
              infoDatacenterForArray[deviceType]['criticalServices'] += dv.criticalServices;
              infoDatacenterForArray[deviceType]['unknownServices'] += dv.unknownServices;
              infoDatacenterForArray[deviceType]['okServices'] += dv.okServices;
              infoDatacenterForArray[deviceType]['arrayServiceProblems'].concat(dv.arrayServiceProblems);
              for (let i = 0; i < dv.arrayServiceProblems.length; i++) {
                dv.arrayServiceProblems[i].deviceType = deviceType;
              }
              dv.arrayServiceProblems.forEach((srvPrb) => {
                if (srvPrb.toActiveAlert) {
                  allProblemsServ.push(srvPrb);
                }
              });

              if (isHostEnlace) {
                dv.arrayServiceProblems.forEach((problem) => {
                  problemsWithEnlaces.push({
                    host: problem.host_name,
                    service_description: problem.description,
                    service_state: problem.state,
                    svc_plugin_output: problem.plugin_output,
                    ip: problem.ip,
                  });
                });
              }

              if (dv.arrayServiceProblems.length && !isEnlaceCritical) {
                infoDatacenterForArray['status'] = 1;
              }
              infoToDatacenter.totalAlerts += dv.serviceProblems;
              infoDatacenterForArray['alertsDt'] += dv.serviceProblems;
              if (dv.state !== 0) {
                infoDatacenterForArray[deviceType]['status'] = 2;
              }
              if (infoDatacenterForArray[deviceType]['status'] !== 2 && dv.arrayServiceProblems.length) {
                infoDatacenterForArray[deviceType]['status'] = 1;
              }
            } else {
              if (dv.state !== 0 || infoDatacenterForArray[deviceType]['status'] !== 0) {
                infoDatacenterForArray['status'] = 2;
                isEnlaceCritical = true;
              }
            }
          });
        }
      }

      if (Object.hasOwnProperty.call(linkDetails, country)) {
        const dtCountry = linkDetails[country];
        if (Object.hasOwnProperty.call(dtCountry, dataCenter)) {
          const element = dtCountry[dataCenter];
          infoDatacenterForArray.enlaces.itemsProviders = element;
          for (const eachEnlace in element) {
            if (Object.hasOwnProperty.call(element, eachEnlace)) {
              const valueEnlace = element[eachEnlace];
              if (valueEnlace.interface && valueEnlace.neighbor) {
                if (valueEnlace.interface) {
                  if (valueEnlace.interface.service_state !== 'OK') {
                    problemsWithEnlaces.push({ ...valueEnlace.interface });
                    // allProblemsServ.push(valueEnlace.interface);
                    let isForPush = true;
                    allProblemsServ.forEach((prbServ) => {
                      if (
                        prbServ.host_name === valueEnlace.interface.host_name &&
                        prbServ.description === valueEnlace.interface.description
                      ) {
                        isForPush = false;
                      }
                    });
                    if (isForPush) {
                      allProblemsServ.push({ ...valueEnlace.interface });
                    }
                  }
                }
                if (valueEnlace.neighbor) {
                  if (valueEnlace.neighbor.service_state !== 'OK') {
                    problemsWithEnlaces.push({ ...valueEnlace.neighbor });
                    // allProblemsServ.push(valueEnlace.neighbor);
                    let isForPush = true;
                    allProblemsServ.forEach((prbServ) => {
                      if (
                        prbServ.host_name === valueEnlace.interface.host_name &&
                        prbServ.description === valueEnlace.interface.description
                      ) {
                        isForPush = false;
                      }
                    });
                    if (isForPush) {
                      allProblemsServ.push({ ...valueEnlace.interface });
                    }
                  }
                }
              }
            }
          }
        }
      }

      const readed_hosts = [];
      linksServices.forEach((linkService) => {
        if (countryInfo[dataCenter]['all_hosts'][linkService['host_name']]) {
          if (!readed_hosts.includes(linkService['host_name'])) {
            alertsByCountry[country][dataCenter]['enlaces'].push(
              countryInfo[dataCenter]['all_hosts'][linkService['host_name']]
            );
            if (countryInfo[dataCenter]['all_hosts'][linkService['host_name']].state === 0) {
              infoDatacenterForArray['enlaces']['ok'] += 1;
            }
            infoDatacenterForArray['enlaces']['total'] += 1;
            readed_hosts.push(linkService['host_name']);
          }
          infoDatacenterForArray['enlaces']['totalServices'] += 1;
          if (linkService.state == 0) {
            infoDatacenterForArray['enlaces']['okServices'] += 1;
          } else {
            // const isHostEnlace = arrayCountryEnlaces.some((countryEnlace) => countryEnlace === link.name);
            let isForPush = true;
            linkService.deviceType = 'enlace';
            allProblemsServ.forEach((prbServ) => {
              if (prbServ.host_name === linkService.host_name && prbServ.description === linkService.description) {
                isForPush = false;
              }
            });
            if (isForPush) {
              allProblemsServ.push({ ...linkService });
            }

            infoDatacenterForArray['alertsDt'] += 1;
            if (linkService.state === 1) {
              infoDatacenterForArray['enlaces']['warningServices'] += 1;
              if (infoDatacenterForArray['enlaces']['status'] !== 2) {
                infoDatacenterForArray['enlaces']['status'] = 1;
              }
            } else if (linkService.state === 2) {
              infoDatacenterForArray['enlaces']['criticalServices'] += 1;
              infoDatacenterForArray['enlaces']['status'] = 2;
            } else if (linkService.state === 3) {
              infoDatacenterForArray['enlaces']['unknownServices'] += 1;
              if (infoDatacenterForArray['enlaces']['status'] === 0) {
                infoDatacenterForArray['enlaces']['status'] = 3;
              }
            }
          }
        }
      });
      infoToDatacenter.arrayDatacenter.push(infoDatacenterForArray);
      arrayInfoDatacentersByCountry.push(infoToDatacenter);
    }
  }
  return { arrayInfoDatacentersByCountry, problemsWithEnlaces, allProblemsServ };
}

export function getHostDetail(hosts, hostsServices) {
  const hostWithDetails = [];
  hosts.forEach((host) => {
    const tmp = { ...host };
    tmp.serviceTotalCritical = 0;
    tmp.serviceTotalWarning = 0;
    if (hostsServices[host.name]) {
      tmp['serviceProblems'] = hostsServices[host.name].length;
      hostsServices[host.name].forEach((service) => {
        if (service.state === 2) {
          tmp.serviceTotalCritical += 1;
        } else if (service.state === 1) {
          tmp.serviceTotalWarning += 1;
        }
      });
    } else {
      tmp['serviceProblems'] = 0;
    }
    if (tmp.uptime) {
      tmp.uptime = extractUptimeFromPerfData(tmp.uptime);
    }
    tmp.tags.region = extractRegionFromHostname(host.name);

    if (host.tags.SITE === 'REDES_BR') {
      tmp.tags.region = region_dictionary_brasil[host.tags.CODIGO_DE_TIENDA];
    } else if (host.tags.SITE === 'REDES_CO') {
      tmp.tags.region = region_dictionary_colombia[host.tags.CODIGO_DE_TIENDA];
    }
    hostWithDetails.add(tmp);
  });
  return hostWithDetails;
}

export function getEnlacesInfo(hosts, links, arrayInterfaces, arrayNeighbors) {
  const infoEnlaces = {};
  hosts.forEach((host) => {
    if (!infoEnlaces[host.tags['SITE']]) {
      infoEnlaces[host.tags['SITE']] = {};
    }
    if (!infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']]) {
      infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']] = {};
    }
    links.forEach((link) => {
      if (host.name === link.host) {
        const indexOfInt = arrayInterfaces.findIndex((element) => element === link.service_description);
        const indexOfNeigh = arrayNeighbors.findIndex((element) => element === link.service_description);
        if (indexOfInt !== -1 || indexOfNeigh !== -1) {
          if (indexOfInt !== -1) {
            if (!infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfInt]) {
              infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfInt] = {};
            }
            infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfInt]['interface'] = {
              host: link.host,
              service_description: link.service_description,
              service_state: link.service_state,
              svc_plugin_output: link.svc_plugin_output,
              svc_state_age: link.svc_state_age,
              ip: host.address,
              metrics: getSaturationFromServiceOutput(link.svc_plugin_output),
              site: host.tags.site,
            };
          }
          if (indexOfNeigh !== -1) {
            if (!infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfNeigh]) {
              infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfNeigh] = {};
            }
            infoEnlaces[host.tags['SITE']][host.tags['CODIGO_DE_TIENDA']][indexOfNeigh]['neighbor'] = {
              host: link.host,
              service_description: link.service_description,
              service_state: link.service_state,
              svc_plugin_output: link.svc_plugin_output,
              svc_state_age: link.svc_state_age,
              ip: host.address,
            };
          }
        }
      }
    });
  });
  return infoEnlaces;
}

export function addHostDetailDatacenter(hosts, hostsServices) {
  const hostWithDetails = [];
  hosts.forEach((host) => {
    const tmp = { ...host };
    const problems = [];
    let checkmkDiscovery = 0;
    if (hostsServices[host.name]) {
      let warning = 0;
      let critical = 0;
      let unknown = 0;
      let ok = 0;
      if (host.tags.snmp_ds !== 'no-snmp' && host.tags.agent === 'no-agent') {
        checkmkDiscovery = 1;
      }
      hostsServices[host.name].forEach((servProblem) => {
        const servicesToAlert = [
          'CPU',
          'Mem',
          'Uptime',
          'Temp',
          'Power',
          'Sessions',
          'FRU',
          'Cluster',
          'PING',
          'Interface',
          'OSPF',
          'BGP',
          'ENLACE',
          'VPN',
        ];

        if (servProblem.state !== 0 && !servProblem.description.includes('Check_MK Discovery')) {
          if (servicesToAlert.some((srvCheck) => servProblem.description.includes(srvCheck))) {
            problems.push({ ...servProblem, ip: host.address, site: host.tags.SITE, toActiveAlert: true });
          } else {
            problems.push({ ...servProblem, ip: host.address, site: host.tags.SITE, toActiveAlert: false });
          }
          if (servProblem.state === 1) {
            warning = warning + 1;
          } else if (servProblem.state === 2) {
            critical = critical + 1;
          } else if (servProblem.state === 3) {
            unknown = unknown + 1;
          }
        } else {
          ok = ok + 1;
        }
      });
      tmp['serviceProblems'] = problems.length;
      tmp['warningServices'] = warning;
      tmp['criticalServices'] = critical;
      tmp['unknownServices'] = unknown;
      tmp['okServices'] = host.num_services - warning - critical - unknown - checkmkDiscovery;
      tmp['arrayServiceProblems'] = problems;
    } else {
      if (host.tags.snmp_ds !== 'no-snmp' && host.tags.agent === 'no-agent') {
        checkmkDiscovery = 1;
      }
      tmp['serviceProblems'] = 0;
      tmp['arrayServiceProblems'] = [];
      tmp['warningServices'] = 0;
      tmp['criticalServices'] = 0;
      tmp['unknownServices'] = 0;
      tmp['okServices'] = host.num_services - checkmkDiscovery;
    }
    hostWithDetails.add(tmp);
  });
  return hostWithDetails;
}

function extractUptimeFromPerfData(perfData) {
  //'uptime=5697741;;;;'
  return parseInt(perfData.split(';')[0].split('=')[1]) / 60 / 60;
}

export function getHostGroupsByTag(hosts, tag) {
  const groups = {};
  hosts.forEach((host) => {
    const groupKey = host.tags[tag];
    if (groupKey) {
      if (!groups[groupKey]) {
        groups[groupKey] = {
          key: groupKey,
          hostTotal: 0,
          hostProblems: 0,
          serviceTotal: 0,
          serviceProblems: 0,
          serviceTotalCritical: 0,
          serviceTotalWarning: 0,
          status: 0,
          problems: [],
        };
      }
      groups[groupKey].hostTotal += 1;
      groups[groupKey].hostProblems += host.state !== 0 ? 1 : 0;
      groups[groupKey].serviceTotal += host.num_services;
      groups[groupKey].serviceProblems += host.serviceProblems;
      groups[groupKey].serviceTotalCritical += host.serviceTotalCritical;
      groups[groupKey].serviceTotalWarning += host.serviceTotalWarning;
      if (host.state !== 0) {
        groups[groupKey].problems.push({ name: host.name, codeStore: host?.tags?.CODIGO_DE_TIENDA });
      }
    }
  });
  return Object.values(groups);
}

export function getHostGroupsByLabel(hosts, label) {
  const groups = {};
  hosts.forEach((host) => {
    const groupKey = host.labels[label];
    if (groupKey) {
      if (!groups[groupKey]) {
        groups[groupKey] = {
          key: groupKey,
          hostTotal: 0,
          hostProblems: 0,
          serviceTotal: 0,
          serviceProblems: 0,
          serviceTotalCritical: 0,
          serviceTotalWarning: 0,
          status: 0,
          problems: [],
        };
      }
      groups[groupKey].hostTotal += 1;
      groups[groupKey].hostProblems += host.state !== 0 ? 1 : 0;
      groups[groupKey].serviceTotal += host.num_services;
      groups[groupKey].serviceProblems += host.serviceProblems;
      groups[groupKey].serviceTotalCritical += host.serviceTotalCritical;
      groups[groupKey].serviceTotalWarning += host.serviceTotalWarning;

      if (host.state !== 0) {
        groups[groupKey].problems.push({
          name: host.name,
          codeStore: host?.tags?.CODIGO_DE_TIENDA,
          last_state_change: host?.last_state_change,
          acknowledged: host?.acknowledged,
          state: host?.state,
          brand: host?.tags?.TIENDA,
          ip: host?.address,
          service: 'Ping',
          site: host?.tags?.SITE,
        });
      }
    }
  });
  return Object.values(groups);
}

export function getDictionaryHosts(hosts) {
  let filterByStoreOrDatacenter = { allHosts: {} };
  hosts.forEach((host) => {
    if (!filterByStoreOrDatacenter.allHosts[host.name]) {
      filterByStoreOrDatacenter.allHosts[host.name] = host;
    }
    const site = host.tags.site.toLowerCase();
    const siteName = dictNamesBySite[site];
    if (!filterByStoreOrDatacenter[siteName]) {
      filterByStoreOrDatacenter[siteName] = { datacenter: [], stores: [] };
    }
    if (host.tags.TIENDA !== 'DATACENTER') {
      filterByStoreOrDatacenter[siteName]['stores'].push(host);
    } else {
      filterByStoreOrDatacenter[siteName]['datacenter'].push(host);
    }
  });
  return filterByStoreOrDatacenter;
}

export function getDictionaryDatacenterHosts(hosts) {
  let filterByStoreOrDatacenter = {};
  hosts.forEach((host) => {
    if (host.tags.TIENDA === 'DATACENTER') {
      if (!filterByStoreOrDatacenter[host.name]) {
        filterByStoreOrDatacenter[host.name] = host;
      }
    }
  });
  return filterByStoreOrDatacenter;
}
