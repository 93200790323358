import React from 'react';

import './CardServiceCritical.css';

function CardServiceCritical({ serviceName, up = 0, down = 30, status, alertas = 0, lastFall = '10:38' }) {
  return (
    <section className="card-service-critical-cmpt">
      <div className="left-side">
        <div className="up-left-side">
          <div className="up-title">{serviceName}</div>
          <div className="up-label">DOWN / UP</div>
        </div>
        <div className="down-left-side">
          <div className="alerts">Alertas</div>
          <div className="last-fall">Ultimas caidas</div>
        </div>
      </div>
      <div className="right-side">
        <div className="up-right-side">
          <div className="wrap-up-status">
            <div style={{ position: 'absolute', top: '2px' }}>{`${down} / ${up}`}</div>
          </div>
        </div>
        <div className="down-right-side">
          <div className="alerts">{`${alertas}`}</div>
          <div className="last-fall">{`${lastFall}`}</div>
        </div>
      </div>
    </section>
  );
}

export default CardServiceCritical;
