import React from 'react';
import { getStatusColorByNumber, getFlags } from '../../utils/color';
import serverImg from '../../img/server_1.svg';
import './CardCountryCloud.css';

function CardCountryCloud({ title, label, country, status }) {
  return (
    <section className="card-country-cloud">
      <div className="header">
        <img src={getFlags(country)} style={{ width: '28px' }} />
        <div className="content">
          <div className="title-cc">{title}</div>
          <div className="label-cc">{label}</div>
        </div>
      </div>
      <div className="body">
        <div className="flag-card" style={{ background: getStatusColorByNumber(status), margin: '8px 12px', borderRadius: '4px' }}></div>
        <img src={serverImg} />
      </div>
    </section>
  );
}

export default CardCountryCloud;

// .card-country-cloud .header {

// }

// .card-country-cloud .header img {

// }

// .card-country-cloud .header .content {

// }

// .card-country-cloud .body {

// }

// .card-country-cloud .icon {

// }
