import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import CSVHocComponent from 'components/CSVHocComponent/CSVHocComponent';

import { getStatusColorByNumber, getStatusByNumber } from '../../../../utils/color';
import { parseToJSONInterface } from '../../utils/common';
import { dictNames, dictPriorityPercentageByState } from '../../utils/database';

import './PerformanceStores.css';

const sharedLinksStores = {
  EA503: 'JU503',
  PA781: 'EA781',
  EA507: 'JU507',
  EA508: 'JU508',
  EA510: 'JU510',
  EA512: 'JU512',
  EA517: 'N517',
  EA520: 'JU520',
  EA534: 'JU534',
  EA591: 'JU591',
  EA633: 'JU633',
  EA643: 'N643',
  EA843: 'JU843',
  JU760: 'PA760',
  N590: 'PA590',
  N979: 'PA979',
  N756: 'PA756',
  PA804: 'N804',
};

function PerformanceStores({ selectedCountries, storeLinks }) {
  const [filterStoreLinks, setFilterStoreLinks] = useState({ brand: [], code: [], status: [] });
  const [toFilterStoreLinks, setToFilterStoreLinks] = useState({ brand: [], code: [], status: [] });
  const [storeLinksItems, setStoreLinksItems] = useState([]);

  useEffect(() => {
    const storesLinks = {};
    selectedCountries.forEach((country) => {
      storeLinks.forEach((link) => {
        if (!link.description.includes('BGP') && !link.description.includes('OSPF')) {
          if (
            link.host.tags.SITE.toLowerCase() === country.country.toLowerCase() ||
            dictNames[link.host.tags.SITE.toLowerCase()] === country.country.toLowerCase()
          ) {
            if (!storesLinks[link.host.tags.CODIGO_DE_TIENDA]) {
              storesLinks[link.host.tags.CODIGO_DE_TIENDA] = {
                brand: link.host.tags.TIENDA,
                code: link.host.tags.CODIGO_DE_TIENDA,
                links: [],
              };
            }
            storesLinks[link.host.tags.CODIGO_DE_TIENDA].links.push(link);
          }
        }
      });
    });
    const interfacesDict = {};

    storeLinks.forEach((eachLink) => {
      if (!eachLink.description.includes('BGP') && !eachLink.description.includes('OSPF')) {
        if (
          (eachLink.plugin_output.includes('CS:') &&
            eachLink.plugin_output.includes('TLCO:') &&
            eachLink.host.labels['cmk/device_type'] === 'firewall') ||
          (eachLink.description.includes('CS:') &&
            eachLink.description.includes('TLCO:') &&
            eachLink.host.labels['cmk/device_type'] === 'router')
        ) {
          let strWithInfoEnlace = '';
          if (eachLink.host.labels['cmk/device_type'] === 'router') {
            strWithInfoEnlace = eachLink.description;
          } else {
            strWithInfoEnlace = eachLink.plugin_output;
          }
          const jsonName = parseToJSONInterface(strWithInfoEnlace);
          let link_prov = '';
          let service_code = '';
          if (jsonName) {
            for (const key in jsonName) {
              if (key.includes('TLCO')) {
                link_prov = jsonName[key];
              }
            }
            service_code = jsonName['CS'];
          }

          if (jsonName && jsonName['CS']) {
            if (!interfacesDict[jsonName['CS']]) {
              interfacesDict[jsonName['CS']] = { ...eachLink, link_prov, service_code };
            }
          }
        }
      }
    });

    const statePerformanceStores = {
      brand: [],
      code: [],
      status: [],
    };
    const storeEnlaces = {};
    for (const storeName in storesLinks) {
      const storeInfo = storesLinks[storeName];
      storeInfo.links.forEach((link) => {
        let strWithInfoEnlace = '';
        if (link.host.labels['cmk/device_type'] === 'router') {
          strWithInfoEnlace = link.description;
        } else {
          strWithInfoEnlace = link.plugin_output;
        }
        const jsonName = parseToJSONInterface(strWithInfoEnlace);
        const interfaceByEnlace = interfacesDict[jsonName['CS']];
        if (interfaceByEnlace) {
          if (!storeEnlaces[storeName]) {
            storeEnlaces[storeName] = {
              brand: storeInfo.brand,
              code: storeInfo.code,
              status: 0,
              links: [],
            };
          }
          if (storeEnlaces[storeName]['status'] < link.state) {
            storeEnlaces[storeName]['status'] = link.state;
          }
          if (!interfaceByEnlace.plugin_output.toLowerCase().includes('down')) {
            storeEnlaces[storeName]['links'].push({
              ...interfaceByEnlace,
              state: link.state,
              enlace: link.description,
            });
          }
        }
      });
    }
    Object.values(storeEnlaces).forEach((store, index) => {
      if (!statePerformanceStores['brand'].some((br) => br.value === store.brand)) {
        statePerformanceStores.brand.push({ value: store.brand, label: store.brand });
      }
      if (!statePerformanceStores['code'].some((br) => br.value === store.code)) {
        statePerformanceStores.code.push({ value: store.code, label: store.code });
      }
      if (!statePerformanceStores['status'].some((br) => br.value === getStatusByNumber(store.status))) {
        statePerformanceStores.status.push({
          value: getStatusByNumber(store.status),
          label: getStatusByNumber(store.status),
        });
      }
    });
    setStoreLinksItems(storeEnlaces);
    setFilterStoreLinks(statePerformanceStores);
    setToFilterStoreLinks(statePerformanceStores);
  }, [storeLinks, selectedCountries]);

  function getDataToDownload() {
    const arrayStoreLinksCmpt = [];
    Object.values(storeLinksItems).forEach((store, index) => {
      if (store.links) {
        store.links.forEach((eachLink) => {
          arrayStoreLinksCmpt.push({
            brandStore: store.brand,
            codeStore: store.code,
            hostname: eachLink.host_name,
            interface: eachLink.description,
            interface_output: eachLink.plugin_output,
            enlaceStatus: eachLink.state,
            inUsagePercentage: eachLink.data?.inPercentage,
            outUsagePercentage: eachLink.data?.outPercentage,
          });
        });
      }
    });
    return arrayStoreLinksCmpt;
  }

  function getItemsStoreLinksItems() {
    const arrayStoreLinksCmpt = [];
    const simpleArrayFilterBrand = [];
    const simpleArrayFilterCode = [];
    const simpleArrayFilterStatus = [];

    filterStoreLinks['code'].forEach((filter) => {
      simpleArrayFilterCode.push(filter.value);
    });
    filterStoreLinks['brand'].forEach((filter) => {
      simpleArrayFilterBrand.push(filter.value);
    });
    filterStoreLinks['status'].forEach((filter) => {
      simpleArrayFilterStatus.push(filter.value);
    });

    Object.values(storeLinksItems).forEach((store, index) => {
      if (
        simpleArrayFilterBrand.some((filter) => filter === store.brand) &&
        simpleArrayFilterCode.some((filter) => filter === store.code || filter === sharedLinksStores[store.code]) &&
        simpleArrayFilterStatus.some((filter) => filter === getStatusByNumber(store.status))
      ) {
        arrayStoreLinksCmpt.push(store);
      }
    });

    const getMaxPercentage = (store) => {
      return Math.max(
        ...[0, 1, 2, 3, 4, 5].map((i) => {
          const link = store.links[i]?.data;
          const state = store.links[i]?.state;
          if (state === 2 || state === 1) {
            return dictPriorityPercentageByState[state];
          } else if (!link) {
            return 0;
          }
          return Math.max(link.inPercentage || 0, link.outPercentage || 0);
        })
      );
    };

    const dictStores = {};
    const sharedLinks = [];

    arrayStoreLinksCmpt.forEach((link) => {
      if (!dictStores[link.code]) {
        dictStores[link.code] = link;
      }
    });

    const copyDictStores = JSON.parse(JSON.stringify(dictStores));
    if (!_.isEmpty(copyDictStores)) {
      for (const firstStore in sharedLinksStores) {
        const secondStore = sharedLinksStores[firstStore];
        const store1 = copyDictStores[firstStore];
        const store2 = copyDictStores[secondStore];
        if (store1 && store2) {
          sharedLinks.push({
            ...store1,
            brand1: store2.brand,
            code1: store2.code,
            links: [...store1.links, ...store2.links],
          });
          delete dictStores[firstStore];
          delete dictStores[secondStore];
        }
      }

      for (const codeStore in dictStores) {
        const element = dictStores[codeStore];
        sharedLinks.push(element);
      }
    }

    sharedLinks.sort((a, b) => {
      const maxA = getMaxPercentage(a);
      const maxB = getMaxPercentage(b);
      return maxB - maxA;
    });

    const sortedRows = sharedLinks.map((store, index) => {
      const links = store.links.sort((a, b) => {
        const maxA = Math.max(a?.data?.inPercentage, a?.data?.outPercentage);
        const maxB = Math.max(b?.data?.inPercentage, b?.data?.outPercentage);
        return maxB - maxA;
      });

      return (
        <tr key={`${index}-item`} className="performance-interfaces">
          <td>
            <React.Fragment>{store.brand}</React.Fragment>
            {store.brand1 ? (
              <>
                <br />
                <React.Fragment>{store.brand1}</React.Fragment>
              </>
            ) : null}
          </td>
          <td>
            <React.Fragment>{store.code}</React.Fragment>
            {store.code1 ? (
              <>
                <br />
                <React.Fragment>{store.code1}</React.Fragment>
              </>
            ) : null}
          </td>
          {[0, 1, 2, 3, 4, 5].map((i) => (
            <td
              key={`${index}-link-${i}`}
              style={{
                fontSize: '10px',
                textAlign: 'left',
                color: getStatusColorByNumber(links[i]?.state),
              }}
            >
              {links[i] ? (
                <div className="interface-saturation">
                  <div>{`${links[i]?.data?.inPercentage || 0}% | ${links[i]?.data?.outPercentage || 0}%`}</div>
                  <span className="tooltiptext">{`Proveedor: ${links[i].link_prov}, CS: ${links[i].service_code}`}</span>
                </div>
              ) : (
                ''
              )}
            </td>
          ))}
        </tr>
      );
    });
    return sortedRows;
  }

  return (
    <CSVHocComponent personalizedClass={'performance-links-store'} data={getDataToDownload()}>
      <div className="title-active-alerts-wrap">
        <span>Performance enlaces en tiendas</span>
        <div className="filters-alert">
          <div className="filter-alert">
            <span className="filter-title">BRAND - UNIDAD DE NEGOCIO</span>
            <div className="filter-input">
              <Select
                options={toFilterStoreLinks['brand']}
                closeMenuOnSelect={false}
                isClearable
                isMulti
                onChange={(info, e) => {
                  const copyFilter = JSON.parse(JSON.stringify(filterStoreLinks));
                  copyFilter['brand'] = info.length ? info : toFilterStoreLinks['brand'];
                  setFilterStoreLinks(copyFilter);
                }}
              />
            </div>
          </div>
          <div className="filter-alert">
            <span className="filter-title">STORE - CÓDIGO</span>
            <div className="filter-input">
              <Select
                options={toFilterStoreLinks['code']}
                closeMenuOnSelect={false}
                isClearable
                isMulti
                onChange={(info, e) => {
                  const copyFilter = JSON.parse(JSON.stringify(filterStoreLinks));
                  copyFilter['code'] = info.length ? info : toFilterStoreLinks['code'];
                  setFilterStoreLinks(copyFilter);
                }}
              />
            </div>
          </div>
          <div className="filter-alert">
            <span className="filter-title">ESTADO</span>
            <div className="filter-input">
              <Select
                options={toFilterStoreLinks['status']}
                closeMenuOnSelect={false}
                isClearable
                isMulti
                onChange={(info, e) => {
                  const copyFilter = JSON.parse(JSON.stringify(filterStoreLinks));
                  copyFilter['status'] = info.length ? info : toFilterStoreLinks['status'];
                  setFilterStoreLinks(copyFilter);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="active-problems" style={{ height: '200px' }}>
        <table className="store-problems-table">
          <thead style={{ zIndex: 1 }}>
            <tr>
              <th style={{ width: '12%' }}>Brand</th>
              <th style={{ width: '12%' }}>Código</th>
              <th style={{ width: '18%' }}>TLCO</th>
              <th style={{ width: '18%', textAlign: 'left' }}>TLCO</th>
              <th style={{ width: '18%', textAlign: 'left' }}>TLCO</th>
              <th style={{ width: '18%', textAlign: 'left' }}>TLCO</th>
            </tr>
          </thead>
          <tbody>{getItemsStoreLinksItems()}</tbody>
        </table>
      </div>
    </CSVHocComponent>
  );
}

export default PerformanceStores;
