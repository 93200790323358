import React from 'react';

import EyeCloseBlack from '../../../../../../img/eyeCloseBlack.svg';

import './ResumeCard.css';

function ResumeCard({ cardName, warning, critical, onSelect, criticalName = '', warningName = '' }) {
  let nameOfCritical = 'C:';
  let nameOfWarning = 'W:';
  nameOfCritical = criticalName !== '' ? criticalName : nameOfCritical;
  nameOfWarning = warningName !== '' ? warningName : nameOfWarning;
  return (
    <section className="resume-card-vitales-cmpt">
      <div className="header-resume-card">
        <div className="title-card-vitales" onClick={() => onSelect(cardName, '')}>
          {cardName}
          <div className="icon-eye-problems" onClick={() => onSelect(cardName, '')}>
            {<img src={EyeCloseBlack} />}
          </div>
        </div>
        <div className="label-card-vitales">{'ESTADO DE IMPACTO'}</div>
      </div>
      <div className="body-resume-card">
        {criticalName === '' ? (
          <div
            className="warning-resume"
            style={{ backgroundColor: warning !== 0 ? '#E9B500' : '#a0a1a5' }}
            onClick={() => onSelect(cardName, 1)}
          >{`${nameOfWarning} ${warning}`}</div>
        ) : null}
        {warningName === '' ? (
          <div
            className="critical-resume"
            style={{ backgroundColor: critical !== 0 ? '#D93747' : '#a0a1a5' }}
            onClick={() => onSelect(cardName, 2)}
          >{`${nameOfCritical} ${critical}`}</div>
        ) : null}
      </div>
    </section>
  );
}

export default ResumeCard;
