// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.performance-links-store {
  height: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.performance-links-store:hover {
  background-color: #023154;
}

.performance-interfaces {
  position: relative;
}

.interface-saturation .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  bottom: -32px;
  padding: 8px;
  z-index: 1;
}

.interface-saturation:hover .tooltiptext {
  visibility: visible;
}`, "",{"version":3,"sources":["webpack://./views/NetworkPanel/components/PerformanceStores/PerformanceStores.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".performance-links-store {\n  height: 33%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.performance-links-store:hover {\n  background-color: #023154;\n}\n\n.performance-interfaces {\n  position: relative;\n}\n\n.interface-saturation .tooltiptext {\n  visibility: hidden;\n  background-color: black;\n  color: #fff;\n  text-align: center;\n  border-radius: 6px;\n  padding: 5px 0;\n  position: absolute;\n  bottom: -32px;\n  padding: 8px;\n  z-index: 1;\n}\n\n.interface-saturation:hover .tooltiptext {\n  visibility: visible;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
