import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { getStatusColorByNumber } from 'utils/color';
import { buildUrlEnlaceCodigoServicio, buildUrlStore } from '../../utils/common.js';

import ack from '../../img/icon_ack.png';

import './TableAlertEnlace.css';

function TableAlertEnlace({ title, arrayAlerts = [], arrayFilters = [], onClose, siteUrl, style }) {
  const [filters, setFilters] = useState({});
  const [alerts, setAlerts] = useState(arrayAlerts);
  const [allAlerts, setAllAlerts] = useState(arrayAlerts);
  const [cmptArrayFilters, setCmptArrayFilters] = useState(arrayFilters);
  const [filterStore, setFilterStore] = useState(false);

  useEffect(() => {
    setCmptArrayFilters(arrayFilters);
    setFilters({});
  }, [arrayAlerts, arrayFilters]);

  useEffect(() => {
    arrayAlerts.sort((a, b) => new Date(b.lastChange).getTime() - new Date(a.lastChange).getTime());
    setAlerts(arrayAlerts);
    setAllAlerts(arrayAlerts);
    setFilters({});
  }, [arrayAlerts, arrayFilters]);

  function onFilter(value, newFilter) {
    const newAlerts = [];
    const copyAlerts = JSON.parse(JSON.stringify(allAlerts));
    const copyFilter = JSON.parse(JSON.stringify(filters));
    copyFilter[newFilter] = value.label;

    copyAlerts.forEach((alert) => {
      let isAlertInFilter = true;
      for (const key in copyFilter) {
        const element = copyFilter[key];
        if (key === 'alertType') {
          if (element === 'Tienda') {
            setFilterStore(true);
            if (alert.typeProblem !== 'store') {
              isAlertInFilter = false;
            }
          } else if (element === 'Enlace') {
            setFilterStore(false);
            if (!alert.service.includes('CS:')) {
              isAlertInFilter = false;
            }
          }
        } else {
          setFilterStore(false);
          if (alert[key] !== element && element !== 'Todos') {
            isAlertInFilter = false;
          }
        }
      }
      if (isAlertInFilter) {
        newAlerts.push(alert);
      }
    });

    const optionsLocation = [];
    const optionsBrand = [];
    const optionsSuppliers = [];
    let optionsAlertType = [];
    newAlerts.forEach((eachAlert) => {
      if (!optionsLocation.some((loc) => loc.value === eachAlert.location)) {
        optionsLocation.push({ label: eachAlert.location, value: eachAlert.location });
      }
      if (!optionsBrand.some((brnd) => brnd.value === eachAlert.brand)) {
        optionsBrand.push({ label: eachAlert.brand, value: eachAlert.brand });
      }
      if (!optionsSuppliers.some((sup) => sup.value === eachAlert.supplier)) {
        optionsSuppliers.push({ label: eachAlert.supplier, value: eachAlert.supplier });
      }
    });
    optionsBrand.push({ label: 'Todos', value: 'Todos' });
    optionsLocation.push({ label: 'Todos', value: 'Todos' });
    optionsSuppliers.push({ label: 'Todos', value: 'Todos' });
    let newArrayFilters = [
      { filter: 'brand', placeholder: 'Tipo de tienda', options: optionsBrand },
      { filter: 'location', placeholder: 'Comuna', options: optionsLocation },
    ];
    if (arrayFilters.some((filter) => filter.filter === 'alertType')) {
      optionsAlertType = [
        { label: 'Tienda', value: 'Tienda' },
        { label: 'Enlace', value: 'Enlace' },
        { label: 'Todos', value: 'Todos' },
      ];
      newArrayFilters.push({ filter: 'alertType', placeholder: 'Tipo de dispositivo', options: optionsAlertType });
    }
    if (arrayFilters.some((filter) => filter.filter === 'supplier')) {
      optionsAlertType = [
        { label: 'Tienda', value: 'Tienda' },
        { label: 'Enlace', value: 'Enlace' },
        { label: 'Todos', value: 'Todos' },
      ];
      newArrayFilters.push({ filter: 'supplier', placeholder: 'Proveedor', options: optionsSuppliers });
    }
    setCmptArrayFilters(newArrayFilters);
    setFilters(copyFilter);
    setAlerts(newAlerts);
  }

  function orderAlerts(sortDirection, field) {
    const copyAlerts = [...alerts];
    copyAlerts.sort((a, b) => {
      let valA = Number(a[field]);
      let valB = Number(b[field]);
      if (!isNaN(valA) && !isNaN(valB)) {
        return sortDirection === 'up' ? valA - valB : valB - valA; // Orden ascendente o descendente
      }
      if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        return sortDirection === 'up' ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field]);
      }
      return typeof a[field] === 'string' ? (sortDirection === 'up' ? 1 : -1) : sortDirection === 'up' ? -1 : 1;
    });
    setAlerts(copyAlerts);
  }

  function timeElapsed(dateString) {
    const chileanDate = new Date(dateString.replace(' ', 'T') + '-03:00');
    const now = new Date();
    const difference = Math.floor((now - chileanDate) / 1000);

    if (difference < 60) {
      return `${difference} s`;
    } else if (difference < 3600) {
      const minutes = Math.floor(difference / 60);
      return `${minutes} m`;
    } else if (difference < 86400) {
      const hours = Math.floor(difference / 3600);
      const minutes = Math.floor((difference % 3600) / 60);
      return `${hours} h  ${minutes} m`;
    } else {
      const days = Math.floor(difference / 86400);
      const hours = Math.floor((difference % 86400) / 3600);
      const minutes = Math.floor((difference % 3600) / 60);
      return `${days} d ${hours} h ${minutes} m`;
    }
  }

  function convertToLocalTime(dateString) {
    const chileanDate = new Date(dateString.replace(' ', 'T') + '-03:00');
    const localYear = chileanDate.getFullYear();
    const localMonth = String(chileanDate.getMonth() + 1).padStart(2, '0');
    const localDay = String(chileanDate.getDate()).padStart(2, '0');
    const localHours = String(chileanDate.getHours()).padStart(2, '0');
    const localMinutes = String(chileanDate.getMinutes()).padStart(2, '0');
    const localSeconds = String(chileanDate.getSeconds()).padStart(2, '0');

    return `${localYear}-${localMonth}-${localDay} ${localHours}:${localMinutes}:${localSeconds}`;
  }

  return (
    <section className="table-alert-enlace-cmpt" style={style}>
      <div className="table-header">
        <div className="table-title">{title}</div>
        <div className="table-filters">
          <div className="filters">
            {cmptArrayFilters.length
              ? cmptArrayFilters.map((filter, index) => (
                  <div className="filter-item" key={`alert-enlace-item-${index}`}>
                    <Select
                      onChange={(value) => onFilter(value, filter.filter)}
                      options={filter.options}
                      placeholder={filter.placeholder}
                    />
                  </div>
                ))
              : null}
          </div>
          <div className="icon-close" onClick={onClose}></div>
        </div>
      </div>
      <div className="table-body">
        <div className="table-body-header">
          <div className="status">
            <div>ESTADO</div>
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'status')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'status')}>
                &#x25be;
              </div>
            </div>
          </div>
          <div className="hour">
            <div>FECHA / HORA</div>
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'lastChange')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'lastChange')}>
                &#x25be;
              </div>
            </div>
          </div>
          <div className="duration">
            <div>DURACION</div>
          </div>
          <div className="brand">BRAND</div>
          <div className="code">
            <div>CÓDIGO</div>
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'code')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'code')}>
                &#x25be;
              </div>
            </div>
          </div>
          <div className="location">
            <div>COMUNA</div>
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'location')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'location')}>
                &#x25be;
              </div>
            </div>
          </div>
          <div className="hostname">HOSTNAME</div>
          <div className="ip">IP</div>
          <div className="service">
            <div>SERVICIO</div>
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'service')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'service')}>
                &#x25be;
              </div>
            </div>
          </div>
          <div className="acknowledged">REVISIÓN</div>
          <div className="url">MONITOREO</div>
        </div>
        <div className="table-body-items">
          {alerts.map((alert, index) => (
            <div
              className="item"
              key={`body-item-${index}`}
              style={{
                border: alert.storeColor ? `1px solid ${alert.storeColor}` : '1px solid #c9d6f2',
                backgroundColor: alert.storeColor ? alert.storeColor : '',
              }}
            >
              <div className="status">
                <div className="icon-status" style={{ backgroundColor: getStatusColorByNumber(alert.status) }} />
              </div>
              <div className="hour">{convertToLocalTime(alert.lastChange)}</div>
              <div className="duration">{timeElapsed(alert.lastChange)}</div>
              <div className="brand">{alert.brand}</div>
              <div className="code">{alert.code}</div>
              <div className="location">{alert.location}</div>
              <div className="hostname">{alert.hostname}</div>
              <div className="ip">{alert.ip}</div>
              <div className="service">{alert.service}</div>
              <div className="acknowledged">{alert.acknowledged ? <img src={ack} /> : null}</div>
              <div className="url">
                <div
                  className="action-button"
                  onClick={() => {
                    if (alert.service === 'Tienda caida') {
                      window.open(buildUrlStore(alert.brand, alert.code, siteUrl));
                    } else if (alert.service === 'Ping') {
                      window.open(buildUrlEnlaceCodigoServicio(alert.hostname, 'Ping', siteUrl, alert.site));
                    } else {
                      const serviceCode = alert.service ? alert.service.split(':') : [];
                      window.open(buildUrlEnlaceCodigoServicio(alert.hostname, serviceCode[1], siteUrl, alert.site));
                    }
                  }}
                >
                  <div className="triangle"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default TableAlertEnlace;
