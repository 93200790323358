// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-info-basic {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 6px;
  border-radius: 10px;
  position: relative;
}

.card-info-basic .title-card {
  font-size: 12px;
  font-weight: bold;
}

.card-info-basic .device-type-card {
  font-size: 13px;
}

.card-info-basic .wrap-title-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.card-info-basic .services-info {
  width: 100%;
  position: absolute;
  height: 20px;
  display: flex;
  bottom: 0;
  left: 0;
}

.card-info-basic .services-info .services-info-item {
  color: white;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.card-info-basic .info-card {
  font-size: 8px;
}

.card-info-basic .problems-dt {
  font-size: 8px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./components/CardInfo/CardInfo.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,SAAS;EACT,OAAO;AACT;;AAEA;EACE,YAAY;EACZ,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".card-info-basic {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  /* justify-content: center; */\n  padding: 6px;\n  border-radius: 10px;\n  position: relative;\n}\n\n.card-info-basic .title-card {\n  font-size: 12px;\n  font-weight: bold;\n}\n\n.card-info-basic .device-type-card {\n  font-size: 13px;\n}\n\n.card-info-basic .wrap-title-card {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.card-info-basic .services-info {\n  width: 100%;\n  position: absolute;\n  height: 20px;\n  display: flex;\n  bottom: 0;\n  left: 0;\n}\n\n.card-info-basic .services-info .services-info-item {\n  color: white;\n  flex: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 10px;\n}\n\n.card-info-basic .info-card {\n  font-size: 8px;\n}\n\n.card-info-basic .problems-dt {\n  font-size: 8px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
