import React, { useEffect , useState} from 'react';
import './Network2.css';
import CardNetwork from './CardNetwork';
import { getStatusColorByNumber } from '../../utils/color';
import EnlaceTooltip from 'components/EnlaceTooltip/EnlaceTooltip';

function Network2({enlaces}) {
    console.log(enlaces);
    
    const status3Color = getStatusColorByNumber(3);
    const [lineStatus, setLineStatus] = useState({
        "CHL-PE01": [{ to: "COL-PE-01", status: status3Color }, { to: "ARG-PE01", status: status3Color }],
        "CHL-PE02": [{ to: "BRA-PE02", status: status3Color }, { to: "PER-PE-02", status: status3Color }, { to: "ARG-PE02", status: status3Color }],
        "ARG-PE01": [{ to: "CHL-PE01", status: status3Color }, { to: "BRA-PE01", status: status3Color }, { to: "PER-PE-01", status: status3Color }],
        "ARG-PE02": [{ to: "CHL-PE02", status: status3Color }, { to: "COL-PE-02", status: status3Color }],
        "PER-PE-01": [{ to: "ARG-PE01", status: status3Color }],
        "PER-PE-02": [{ to: "CHL-PE02", status: status3Color }],
        "COL-PE-01": [{ to: "CHL-PE01", status: status3Color }],
        "COL-PE-02": [{ to: "ARG-PE02", status: status3Color }],
        "BRA-PE01": [{ to: "ARG-PE01", status: status3Color }],
        "BRA-PE02": [{ to: "CHL-PE02", status: status3Color }]
    });
    
    const links = {
        "CHL-PE01": ["COL-PE-01", "ARG-PE01"],
        "CHL-PE02": ["BRA-PE02", "PER-PE-02", "ARG-PE02"],
        "ARG-PE01": ["CHL-PE01", "BRA-PE01", "PER-PE-01"],
        "ARG-PE02": ["CHL-PE02", "COL-PE-02"],
        "PER-PE-01": ["ARG-PE01"],
        "PER-PE-02": ["CHL-PE02"],
        "COL-PE-01": ["CHL-PE01"],
        "COL-PE-02": ["ARG-PE02"],
        "BRA-PE01": ["ARG-PE01"],
        "BRA-PE02": ["CHL-PE02"]
    }
      

    useEffect(() => {
        const linksnewdata = [];
        enlaces.forEach(enlace => {
            let from = enlace.from;
            let to = enlace.to;
            if(from in links){
                let linkArr = links[from];
                let link = linkArr.find(l => l === to);
                if(link){
                    linksnewdata.push(enlace)
                }
            }
            // if(to in lineStatus){
            //     let linkArr = lineStatus[to];
            // }
        });
        let lineStatusNew = {}
        Object.entries(links).forEach(([from, arrTo]) => {
            let temp = [];
            arrTo.forEach(to => {
                let item = linksnewdata.find(e => (e.from === from && e.to === to) || (e.from === to && e.to === from) );
                if(item){
                    temp.push({to: to, status: getStatusColorByNumber(item.status), problems: item.problems, spd: item.spd, label: item.label,usein: item.usein,useout: item.useout})
                }
                else{
                    temp.push({to: to, status: getStatusColorByNumber(3)})
                }
            })
            lineStatusNew[from] = temp;
        });

        setLineStatus(lineStatusNew)
    } , []);

    function getTooltip(link){
        return <EnlaceTooltip problems={link.problems} code={link.label} speed={link.spd} usein={link.usein} useout={link.useout} />
    }

    return (
    <div className='network-wrapper'>
        <div className='cirion'>
            <div className='cirion-title'>Cirion</div>
            <div className='cirion-label'>Red transporte WDM</div>
            <CardNetwork name={"DC - Chile"} location={"CHL"} interfaces={[{name: "CHL-PE01", status: lineStatus["CHL-PE01"]}, {name: "CHL-PE02", status: lineStatus["CHL-PE02"]}]} positions={{left: "40px", top: "-37px"}} top_bottom={"top"}></CardNetwork>
            <CardNetwork name={"DC - Argentina"} location={"ARG"} interfaces={[{name: "ARG-PE01", status: lineStatus["ARG-PE01"]}, {name: "ARG-PE02", status: lineStatus["ARG-PE02"]}]} positions={{right: "40px", top: "-37px"}} top_bottom={"top"}></CardNetwork>
            <CardNetwork name={"DC - Colombia"} location={"CO"} interfaces={[{name: "COL-PE-01", status: lineStatus["COL-PE-01"]}, {name: "COL-PE-02", status: lineStatus["COL-PE-02"]}]} positions={{ bottom: "-37px"}} top_bottom={"bottom"}></CardNetwork>
            <CardNetwork name={"DC - Perú"} location={"PE"} interfaces={[{name: "PER-PE-01", status: lineStatus["PER-PE-01"]}, {name: "PER-PE-02", status: lineStatus["PER-PE-02"]}]} positions={{left: "40px", bottom: "-37px"}} top_bottom={"bottom"}></CardNetwork>
            <CardNetwork name={"DC - Brasil"} location={"BR"} interfaces={[{name: "BRA-PE01", status: lineStatus["BRA-PE01"]}, {name: "BRA-PE02", status: lineStatus["BRA-PE02"]}]} positions={{right: "40px", bottom: "-37px"}} top_bottom={"bottom"}></CardNetwork>

            {/* ch1 - co1 */}
            <div className="line" style={{width: "3px", height: "161px", top: "92px", left: "77px", backgroundColor: lineStatus["CHL-PE01"][0].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE01"][0])}
                </span>
            </div>
            <div className="line" style={{left: "80px" , top: "50%", right: "calc(50% + 59px)" , backgroundColor: lineStatus["CHL-PE01"][0].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE01"][0])}
                </span>
            </div>
            <div className="line" style={{width: "3px", height: "141px", bottom: "106px", left: "calc(50% - 62px)" , backgroundColor: lineStatus["CHL-PE01"][0].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE01"][0])}
                </span>
            </div>

            {/* ch1 - arg1 */}
            <div className="line" style={{width: "3px", height: "40px", top: "92px", left: "117px", backgroundColor: lineStatus["CHL-PE01"][1].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE01"][1])}
                </span>
            </div>
            <div className="line" style={{left: "117px" , top: "132px", right: "239px" , backgroundColor: lineStatus["CHL-PE01"][1].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE01"][1])}
                </span>
            </div>
            <div className="line" style={{width: "3px", height: "40px", top: "92px", right: "239px" , backgroundColor: lineStatus["CHL-PE01"][1].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE01"][1])}
                </span>
            </div>
            

            {/* ch2 - br2 */}
            <div className="line" style={{width: "3px", height: "259px", top: "92px", left: "196px" , backgroundColor: lineStatus["CHL-PE02"][0].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE02"][0])}
                </span>
            </div>
            <div className="line" style={{left: "196px" , bottom: "146px", right: "100px" , backgroundColor: lineStatus["CHL-PE02"][0].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE02"][0])}
                </span>
            </div>
            <div className="line" style={{width: "3px", height: "40px", bottom: "106px", right: "100px" , backgroundColor: lineStatus["CHL-PE02"][0].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE02"][0])}
                </span>
            </div>

            {/* ch2 - pe2 */}

            <div className="line" style={{width: "3px", height: "288px", top: "106px", left: "216px" , backgroundColor: lineStatus["CHL-PE02"][1].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE02"][1])}
                </span>
            </div>

            {/* ch2 - ar2 */}
            <div className="line" style={{width: "3px", height: "60px", top: "92px", left: "236px" , backgroundColor: lineStatus["CHL-PE02"][2].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE02"][2])}
                </span>
            </div>
            <div className="line" style={{left: "236px" , top: "152px", right: "121px", backgroundColor: lineStatus["CHL-PE02"][2].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE02"][2])}
                </span>
            </div>
            <div className="line" style={{width: "3px", height: "60px", top: "92px", right: "121px", backgroundColor: lineStatus["CHL-PE02"][2].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["CHL-PE02"][2])}
                </span>
            </div>

            {/* ar1 - br1 */}

            <div className="line" style={{width: "3px", height: "288px", top: "106px", right: "219px", backgroundColor: lineStatus["ARG-PE01"][1].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["ARG-PE01"][1])}
                </span>
            </div>


            {/* ar1 - pe1 */}

            <div className="line" style={{width: "3px", height: "231px", top: "92px", right: "199px", backgroundColor: lineStatus["ARG-PE01"][2].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["ARG-PE01"][2])}
                </span>
            </div>
            <div className="line" style={{left: "98px" , bottom: "174px", right: "199px", backgroundColor: lineStatus["ARG-PE01"][2].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["ARG-PE01"][2])}
                </span>
            </div>
            <div className="line" style={{width: "3px", height: "71px", bottom: "106px", left: "98px", backgroundColor: lineStatus["ARG-PE01"][2].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["ARG-PE01"][2])}
                </span>
            </div>

            {/* ar2 - co2 */}

            <div className="line" style={{width: "3px", height: "141px", bottom: "106px", right: "calc(50% - 59px)", backgroundColor: lineStatus["ARG-PE02"][1].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["ARG-PE02"][1])}
                </span>
            </div>
            <div className="line" style={{right: "80px" , top: "50%", left: "calc(50% + 56px)", backgroundColor: lineStatus["ARG-PE02"][1].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["ARG-PE02"][1])}
                </span>
            </div>
            <div className="line" style={{width: "3px", height: "161px", top: "92px", right: "80px", backgroundColor: lineStatus["ARG-PE02"][1].status}}>
                <span className="tooltiptext">
                    {getTooltip(lineStatus["ARG-PE02"][1])}
                </span>
            </div>

        </div>
    </div>  
    )
}

export default Network2;