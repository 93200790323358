import React, { useEffect, useState } from 'react';

import CSVHocComponent from 'components/CSVHocComponent/CSVHocComponent';

import { getStatusColorByNumber } from '../../../../utils/color';
import { getSaturationForDatacenter } from '../../../../api/link';

import { dictServices } from '../../utils/database';
import { extractMetrics } from '../../utils/common';

import ResumeCard from './components/ResumeCard/ResumeCard';
import BackIcon from '../../../../img/backIcon.svg';

import './ChequeosVitales.css';

function ChequeosVitales({ allServices, allHosts, selectedCountries }) {
  const [selectedService, setSelectedService] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [cpuInfo, setCpuInfo] = useState([]);
  const [memoryInfo, setMemoryInfo] = useState([]);
  const [temperatureInfo, setTemperatureInfo] = useState([]);
  const [slaInfo, setSlaInfo] = useState([]);
  const [interfacesDownInfo, setInterfacesDownInfo] = useState([]);
  const [interfacesBandwith, setInterfacesBandwith] = useState([]);
  const [fanInfo, setFanInfo] = useState([]);
  const [psuInfo, setPsuInfo] = useState([]);
  const [bgpInfo, setBgpInfo] = useState([]);
  const [infoResume, setInfoResume] = useState({
    cpu: {},
    'Bgp Warning': {},
    fan: {},
    psu: {},
    memory: {},
    temperature: {},
    sla: {},
    interfacesDown: {},
    interfacesBandwith: {},
    'Interfaces Down': {},
    'Interfaces Bandwith': {},
  });
  const [chequeosVitalesSort, setChequeosVitalesSort] = useState({ direction: 'down', field: 'percentageUtilization' });

  function getTemperature(plugin_output) {
    const match = plugin_output.match(/Temperature:\s+(\d+(?:\.\d+)?)\s*°C/i);
    const temperature = match ? parseInt(match[1], 10) : '-';
    return temperature;
  }

  function cleanText(texto) {
    texto = texto
      .replace(/\[|\]/g, '')
      .replace(/Ethernet/g, 'Eth ')
      .replace(/gabit/gi, '');
    return texto.trim();
  }

  useEffect(() => {
    const objectProblemsTemperature = {};
    const objectProblemsCpu = {};
    const objectProblemsMemory = {};
    const objectProblemsSla = {};
    const objectProblemsInterfaceBandwith = {};
    const objectProblemsInterfaceDown = {};
    const objectProblemsPowersupply = {};
    const objectProblemsFan = {};
    const objectProblemsBgp = {};

    const infoResume = {
      cpu: { warning: 0, critical: 0, total: 0 },
      'Bgp Warning': { warning: 0, critical: 0, total: 0 },
      fan: { warning: 0, critical: 0, total: 0 },
      psu: { warning: 0, critical: 0, total: 0 },
      memory: { warning: 0, critical: 0, total: 0 },
      temperature: { warning: 0, critical: 0, total: 0 },
      sla: { warning: 0, critical: 0, total: 0 },
      'Interfaces Bandwith': { warning: 0, critical: 0, total: 0 },
      'Interfaces Down': { warning: 0, critical: 0, total: 0 },
    };
    allServices.forEach((srvProblem) => {
      if (
        allHosts[srvProblem.host_name] &&
        allHosts[srvProblem.host_name].tags.SITE.toLowerCase() === selectedCountries &&
        (srvProblem.state === 1 || srvProblem.state === 2)
      ) {
        let isForCount = false;
        let keyInfoResume = '';
        if (srvProblem.description.includes('Temperature')) {
          isForCount = true;
          keyInfoResume = 'temperature';
          if (!objectProblemsTemperature[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()]) {
            objectProblemsTemperature[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()] = [];
          }
          objectProblemsTemperature[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()].push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            percentageUtilization: getTemperature(srvProblem.plugin_output),
          });
        } else if (
          srvProblem.description.toLowerCase().includes('memory') ||
          srvProblem.description.toLowerCase().includes('mem used')
        ) {
          isForCount = true;
          keyInfoResume = 'memory';
          if (!objectProblemsMemory[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()]) {
            objectProblemsMemory[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()] = [];
          }
          objectProblemsMemory[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()].push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            percentageUtilization: getPercentage(srvProblem.plugin_output) || 0,
          });
        } else if (
          srvProblem.description.toLowerCase().startsWith('bgp') &&
          srvProblem.description.toLowerCase().includes('tlco:') &&
          srvProblem.description.toLowerCase().includes('cs:')
        ) {
          isForCount = true;
          keyInfoResume = 'Bgp Warning';
          if (!objectProblemsBgp[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()]) {
            objectProblemsBgp[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()] = [];
          }
          const bgpArrayName = srvProblem.description.split('BGP');
          objectProblemsBgp[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()].push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            percentageUtilization: bgpArrayName[1] || bgpArrayName[0],
          });
        } else if (
          srvProblem.description.toLowerCase().startsWith('power') &&
          (srvProblem.description.toLowerCase().includes('power supply') ||
            srvProblem.description.toLowerCase().includes('powersupply'))
        ) {
          isForCount = true;
          keyInfoResume = 'psu';
          if (!objectProblemsPowersupply[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()]) {
            objectProblemsPowersupply[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()] = [];
          }
          objectProblemsPowersupply[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()].push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            percentageUtilization: getPercentage(srvProblem.plugin_output) || 0,
          });
        } else if (srvProblem.description.toLowerCase().includes('fan')) {
          isForCount = true;
          keyInfoResume = 'fan';
          if (!objectProblemsFan[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()]) {
            objectProblemsFan[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()] = [];
          }
          objectProblemsFan[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()].push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            percentageUtilization: getPercentage(srvProblem.plugin_output) || 0,
          });
        } else if (srvProblem.description.toLowerCase().includes('cpu')) {
          isForCount = true;
          keyInfoResume = 'cpu';
          if (!objectProblemsCpu[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()]) {
            objectProblemsCpu[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()] = [];
          }
          objectProblemsCpu[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()].push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            percentageUtilization: getPercentage(srvProblem.plugin_output) || 0,
          });
        } else if (
          srvProblem.description.startsWith('SLA') &&
          allHosts[srvProblem.host_name].labels['cmk/device_type'] === 'firewall'
        ) {
          isForCount = true;
          keyInfoResume = 'sla';
          if (!objectProblemsSla[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()]) {
            objectProblemsSla[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()] = [];
          }
          let infoPluginOutput = extractMetrics(srvProblem.plugin_output);
          objectProblemsSla[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()].push({
            hostname: srvProblem.host_name,
            brand: infoPluginOutput['latency'],
            code: infoPluginOutput['packetLoss'],
            state: srvProblem.state,
            percentageUtilization: srvProblem.description.split('SLA')[1],
          });
        } else if (
          srvProblem.description.startsWith('Interface') &&
          !srvProblem.description.includes('TLCO') &&
          !srvProblem.plugin_output.includes('TLCO')
        ) {
          let interfaceName = '';
          if (!objectProblemsInterfaceBandwith[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()]) {
            objectProblemsInterfaceBandwith[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()] = [];
          }

          if (allHosts[srvProblem.host_name].labels['cmk/device_type'] === 'firewall') {
            interfaceName = srvProblem.description.split(' ');
            interfaceName = interfaceName[1];
          } else {
            let [interfaceCompleteName] = srvProblem.plugin_output.split(',');
            if (interfaceCompleteName === 'Item not found in monitoring data') {
              interfaceCompleteName = '---';
            }
            interfaceName = cleanText(interfaceCompleteName);
          }
          if (!srvProblem.plugin_output.toLowerCase().includes('down')) {
            if (
              !srvProblem.plugin_output.toLowerCase().includes('errors out') &&
              !srvProblem.plugin_output.toLowerCase().includes('errors in')
            ) {
              isForCount = true;
              keyInfoResume = 'Interfaces Bandwith';
              const extraDataLink = getSaturationForDatacenter(srvProblem.plugin_output);
              if (extraDataLink) {
                objectProblemsInterfaceBandwith[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()].push({
                  hostname: srvProblem.host_name,
                  brand: isNaN(extraDataLink['inPercentage']) ? '0%' : `${extraDataLink['inPercentage']}%`,
                  code: isNaN(extraDataLink['outPercentage']) ? '0%' : `${extraDataLink['outPercentage']}%`,
                  state: srvProblem.state,
                  percentageUtilization: interfaceName,
                });
              }
            }
          } else {
            isForCount = true;
            keyInfoResume = 'Interfaces Down';
            if (!objectProblemsInterfaceDown[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()]) {
              objectProblemsInterfaceDown[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()] = [];
            }
            objectProblemsInterfaceDown[allHosts[srvProblem.host_name].tags.SITE.toLowerCase()].push({
              hostname: srvProblem.host_name,
              brand: allHosts[srvProblem.host_name].tags.TIENDA,
              code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
              state: srvProblem.state,
              percentageUtilization: interfaceName,
            });
          }
        }

        if (isForCount) {
          srvProblem.state === 1
            ? infoResume[keyInfoResume]['warning']++
            : srvProblem.state === 2
            ? infoResume[keyInfoResume]['critical']++
            : infoResume[keyInfoResume]['total']++;
        }
      }
    });
    setTemperatureInfo(objectProblemsTemperature[selectedCountries]);
    setSlaInfo(objectProblemsSla[selectedCountries]);
    setInterfacesDownInfo(objectProblemsInterfaceDown[selectedCountries]);
    setInterfacesBandwith(objectProblemsInterfaceBandwith[selectedCountries]);
    setCpuInfo(objectProblemsCpu[selectedCountries]);
    setMemoryInfo(objectProblemsMemory[selectedCountries]);
    setPsuInfo(objectProblemsPowersupply[selectedCountries]);
    setFanInfo(objectProblemsFan[selectedCountries]);
    setBgpInfo(objectProblemsBgp[selectedCountries]);

    setInfoResume(infoResume);
    // eslint-disable-next-line
  }, [allServices, selectedCountries]);

  function getPercentage(output) {
    const regex = /([\d.]+)%/;
    const match = output.match(regex);
    if (match) {
      return parseFloat(match[1]);
    }
    return null;
  }

  function getDataToDownload() {
    const dataToDownload = [];
    allServices.forEach((srvProblem) => {
      if (
        allHosts[srvProblem.host_name] &&
        allHosts[srvProblem.host_name].tags.SITE.toLowerCase() === selectedCountries &&
        (srvProblem.state === 1 || srvProblem.state === 2)
      ) {
        if (srvProblem.description.includes('Temperature')) {
          dataToDownload.push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            typeService: 'temperature',
            service: srvProblem.description,
            output: srvProblem.plugin_output,
            percentageUtilization: getTemperature(srvProblem.plugin_output),
          });
        } else if (
          srvProblem.description.toLowerCase().startsWith('bgp') &&
          srvProblem.description.toLowerCase().includes('tlco:') &&
          srvProblem.description.toLowerCase().includes('cs:')
        ) {
          dataToDownload.push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            typeService: 'bgp',
            service: srvProblem.description,
            output: srvProblem.plugin_output,
            percentageUtilization: '',
          });
        } else if (
          srvProblem.description.toLowerCase().includes('memory') ||
          srvProblem.description.toLowerCase().includes('mem used')
        ) {
          dataToDownload.push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            typeService: 'memory',
            service: srvProblem.description,
            output: srvProblem.plugin_output,
            percentageUtilization: getPercentage(srvProblem.plugin_output) || 0,
          });
        } else if (
          srvProblem.description.toLowerCase().startsWith('power') &&
          (srvProblem.description.toLowerCase().includes('power supply') ||
            srvProblem.description.toLowerCase().includes('powersupply'))
        ) {
          dataToDownload.push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            typeService: 'powersupply',
            service: srvProblem.description,
            output: srvProblem.plugin_output,
            percentageUtilization: getPercentage(srvProblem.plugin_output) || 0,
          });
        } else if (srvProblem.description.toLowerCase().includes('fan')) {
          dataToDownload.push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            typeService: 'fan',
            service: srvProblem.description,
            output: srvProblem.plugin_output,
            percentageUtilization: getPercentage(srvProblem.plugin_output) || 0,
          });
        } else if (srvProblem.description.toLowerCase().includes('cpu')) {
          dataToDownload.push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            typeService: 'cpu',
            service: srvProblem.description,
            output: srvProblem.plugin_output,
            percentageUtilization: getPercentage(srvProblem.plugin_output) || 0,
          });
        } else if (
          srvProblem.description.startsWith('SLA') &&
          allHosts[srvProblem.host_name].labels['cmk/device_type'] === 'firewall'
        ) {
          dataToDownload.push({
            hostname: srvProblem.host_name,
            brand: allHosts[srvProblem.host_name].tags.TIENDA,
            code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
            state: srvProblem.state,
            typeService: 'sla',
            service: srvProblem.description,
            output: srvProblem.plugin_output,
            percentageUtilization: '',
          });
        }
        if (
          srvProblem.description.startsWith('Interface') &&
          !srvProblem.description.includes('TLCO') &&
          !srvProblem.plugin_output.includes('TLCO')
        ) {
          let interfaceName = '';
          if (allHosts[srvProblem.host_name].labels['cmk/device_type'] === 'firewall') {
            interfaceName = srvProblem.description.split(' ');
            interfaceName = interfaceName[1];
          } else {
            let [interfaceCompleteName] = srvProblem.plugin_output.split(',');
            if (interfaceCompleteName === 'Item not found in monitoring data') {
              interfaceCompleteName = '---';
            }
            interfaceName = cleanText(interfaceCompleteName);
          }
          if (!srvProblem.plugin_output.toLowerCase().includes('down')) {
            if (
              !srvProblem.plugin_output.toLowerCase().includes('errors out') &&
              !srvProblem.plugin_output.toLowerCase().includes('errors in')
            ) {
              const extraDataLink = getSaturationForDatacenter(srvProblem.plugin_output);
              if (extraDataLink) {
                dataToDownload.push({
                  hostname: srvProblem.host_name,
                  brand: allHosts[srvProblem.host_name].tags.TIENDA,
                  code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
                  state: srvProblem.state,
                  typeService: 'Interface_Bandwith',
                  service: srvProblem.description,
                  output: srvProblem.plugin_output,
                  percentageUtilization: '',
                });
              }
            }
          } else {
            dataToDownload.push({
              hostname: srvProblem.host_name,
              brand: allHosts[srvProblem.host_name].tags.TIENDA,
              code: allHosts[srvProblem.host_name].tags.CODIGO_DE_TIENDA,
              state: srvProblem.state,
              typeService: 'Interface_Down',
              service: srvProblem.description,
              output: srvProblem.plugin_output,
              percentageUtilization: '',
            });
          }
        }
      }
    });

    return dataToDownload;
  }

  function getServicesInfo() {
    if (selectedService === dictServices.cpu) {
      return cpuInfo;
    } else if (selectedService === dictServices.memory) {
      return memoryInfo;
    } else if (selectedService === dictServices['Bgp Warning']) {
      return bgpInfo;
    } else if (selectedService === dictServices.temperature) {
      return temperatureInfo;
    } else if (selectedService === dictServices.sla) {
      return slaInfo;
    } else if (selectedService === dictServices['Interfaces Down']) {
      return interfacesDownInfo;
    } else if (selectedService === dictServices['Interfaces Bandwith']) {
      return interfacesBandwith;
    } else if (selectedService === dictServices.psu) {
      return psuInfo;
    } else if (selectedService === dictServices.fan) {
      return fanInfo;
    }
  }

  function getSecondColumnName() {
    if (selectedService === dictServices.sla) {
      return 'LATENCY';
    } else if (selectedService === dictServices['Interfaces Bandwith']) {
      return 'In';
    } else {
      return 'BRAND';
    }
  }

  function getThirdColumnName() {
    if (selectedService === dictServices.sla) {
      return (
        <th style={{ width: '15%', textAlign: 'center' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            PACKETLOSS
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'code')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'code')}>
                &#x25be;
              </div>
            </div>
          </div>
        </th>
      );
    } else if (selectedService === dictServices['Interfaces Bandwith']) {
      return (
        <th style={{ width: '15%' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            Out
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'code')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'code')}>
                &#x25be;
              </div>
            </div>
          </div>
        </th>
      );
    } else {
      return (
        <th style={{ width: '15%', textAlign: 'center' }}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            CÓDIGO
            <div className="wrapper-sort">
              <div className="up" onClick={() => orderAlerts('up', 'code')}>
                &#x25b4;
              </div>
              <div className="down" onClick={() => orderAlerts('down', 'code')}>
                &#x25be;
              </div>
            </div>
          </div>
        </th>
      );
    }
  }

  function getUtilizationValue(utilization) {
    if (selectedService === dictServices.temperature) {
      return `${utilization} °C`;
    } else if (selectedService === dictServices.sla || selectedService === dictServices['Bgp Warning']) {
      return utilization;
    } else if (selectedService === dictServices['Interfaces Down']) {
      return utilization;
    } else if (selectedService === dictServices['Interfaces Bandwith']) {
      return utilization;
    }
    return `${utilization}%`;
  }

  function getImportantServicesTable() {
    let arrayInfo = [];
    arrayInfo = getServicesInfo();

    let filteredBySelectedState = [];
    if (selectedStatus) {
      filteredBySelectedState = arrayInfo.filter((srvProblem) => srvProblem.state === selectedStatus);
    } else {
      filteredBySelectedState = arrayInfo;
    }

    filteredBySelectedState.sort((a, b) => {
      let valA = Number(a[chequeosVitalesSort.field]);
      let valB = Number(b[chequeosVitalesSort.field]);
      if (!isNaN(valA) && !isNaN(valB)) {
        return chequeosVitalesSort.direction === 'up' ? valA - valB : valB - valA;
      }
      if (typeof a[chequeosVitalesSort.field] === 'string' && typeof b[chequeosVitalesSort.field] === 'string') {
        return chequeosVitalesSort.direction === 'up'
          ? a[chequeosVitalesSort.field].localeCompare(b[chequeosVitalesSort.field])
          : b[chequeosVitalesSort.field].localeCompare(a[chequeosVitalesSort.field]);
      }
      return typeof a[chequeosVitalesSort.field] === 'string'
        ? chequeosVitalesSort.direction === 'up'
          ? 1
          : -1
        : chequeosVitalesSort.direction === 'up'
        ? -1
        : 1;
    });

    return filteredBySelectedState.map((device, index) => (
      <tr key={`${index}-item`}>
        <td>{device.hostname}</td>
        <td>{device.brand}</td>
        <td style={{ textAlign: selectedService === dictServices.sla ? 'center' : 'left' }}>{device.code}</td>
        <td style={{ color: getStatusColorByNumber(device.state) }}>
          {getUtilizationValue(device.percentageUtilization)}
        </td>
        <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '2px' }}>
          <div
            style={{
              backgroundColor: getStatusColorByNumber(device.state),
              width: '14px',
              height: '14px',
              borderRadius: '2px',
              left: 'calc(50% - 7px)',
              top: '2px',
            }}
          ></div>
        </td>
      </tr>
    ));
  }

  function getServicesTable() {
    let arrayInfo = [];
    arrayInfo = selectedService === dictServices.fan ? fanInfo : psuInfo;
    let filteredBySelectedState = [];
    if (selectedStatus) {
      filteredBySelectedState = arrayInfo.filter((srvProblem) => srvProblem.state === selectedStatus);
    } else {
      filteredBySelectedState = arrayInfo;
    }
    let groupedInfo = {};
    let arrayGroupedInfo = [];
    filteredBySelectedState.forEach((element) => {
      if (!groupedInfo[element.hostname]) {
        groupedInfo[element.hostname] = {
          brand: element.brand,
          code: element.code,
          warning: 0,
          ok: 0,
          critical: 0,
          state: 0,
        };
      }
      if (element.state === 0) {
        groupedInfo[element.hostname]['ok'] += 1;
      }
      if (element.state === 1) {
        groupedInfo[element.hostname]['warning'] += 1;
      }
      if (element.state === 2) {
        groupedInfo[element.hostname]['critical'] += 1;
      }
      if (element.state > groupedInfo[element.hostname]['state']) {
        groupedInfo[element.hostname]['state'] = element.state;
      }
    });
    for (const host in groupedInfo) {
      const element = groupedInfo[host];
      arrayGroupedInfo.push({
        hostname: host,
        brand: element.brand,
        code: element.code,
        warning: element.warning,
        ok: element.ok,
        critical: element.critical,
        state: element.state,
      });
    }
    arrayGroupedInfo.sort((a, b) => b.state - a.state);

    return arrayGroupedInfo.map((device, index) => (
      <tr key={`${index}-item`}>
        <td>{device.hostname}</td>
        <td>{device.brand}</td>
        <td>{device.code}</td>
        <td style={{ color: device.warning ? getStatusColorByNumber(1) : 'gray' }}>
          {device.warning ? device.warning : '-'}
        </td>
        <td style={{ color: device.critical ? getStatusColorByNumber(2) : 'gray' }}>
          {device.critical ? device.critical : '-'}
        </td>
        <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '2px' }}>
          <div
            style={{
              backgroundColor: getStatusColorByNumber(device.state),
              width: '14px',
              height: '14px',
              borderRadius: '2px',
              left: 'calc(50% - 7px)',
              top: '2px',
            }}
          ></div>
        </td>
      </tr>
    ));
  }

  function onSelectCard(serviceType, status) {
    setSelectedService(serviceType);
    setSelectedStatus(status);
  }

  function orderAlerts(sortDirection, field) {
    setChequeosVitalesSort({ direction: sortDirection, field: field });
  }

  return (
    <CSVHocComponent personalizedClass={'vital-check'} data={getDataToDownload()}>
      <div className="title-active-alerts-wrap">
        <span>Chequeos Vitales</span>
      </div>
      {!selectedService ? (
        <div className="active-problems-chequeos-vitales">
          <ResumeCard
            cardName={'Cpu'}
            warning={infoResume['cpu']['warning']}
            critical={infoResume['cpu']['critical']}
            onSelect={onSelectCard}
          />
          <ResumeCard
            cardName={'Memory'}
            warning={infoResume['memory']['warning']}
            critical={infoResume['memory']['critical']}
            onSelect={onSelectCard}
          />
          <ResumeCard
            cardName={'Fan'}
            warning={infoResume['fan']['warning']}
            critical={infoResume['fan']['critical']}
            onSelect={onSelectCard}
          />
          <ResumeCard
            cardName={'Psu'}
            warning={infoResume['psu']['warning']}
            critical={infoResume['psu']['critical']}
            onSelect={onSelectCard}
          />
          <ResumeCard
            cardName={'Temperature'}
            warning={infoResume['temperature']['warning']}
            critical={infoResume['temperature']['critical']}
            onSelect={onSelectCard}
          />
          <ResumeCard
            cardName={'Sla'}
            warning={infoResume['sla']['warning']}
            critical={infoResume['sla']['critical']}
            onSelect={onSelectCard}
          />
          <ResumeCard
            cardName={'Interfaces Bandwith'}
            warning={infoResume['Interfaces Bandwith']['warning']}
            critical={infoResume['Interfaces Bandwith']['critical']}
            onSelect={onSelectCard}
          />
          <ResumeCard
            cardName={'Interfaces Down'}
            warning={infoResume['Interfaces Down']['warning']}
            critical={infoResume['Interfaces Down']['critical']}
            onSelect={onSelectCard}
            criticalName={'Down:'}
          />
          <ResumeCard
            cardName={'Bgp Warning'}
            warning={infoResume['Bgp Warning']['warning']}
            critical={infoResume['Bgp Warning']['critical']}
            onSelect={onSelectCard}
            // warningName={'Warning:'}
          />
        </div>
      ) : (
        <div className="active-problems-services" style={{ maxHeight: 'calc(100% - 67px)' }}>
          <div className="header-active-problems">
            <div className="label-ap">{`Estado ${selectedService.toUpperCase()}`}</div>
            <div className="icon-ap" onClick={() => setSelectedService('')}>
              <img src={BackIcon} />
            </div>
          </div>
          {
            <div className="wrapper-table-vitality-checks">
              <table className="store-problems-table">
                <thead>
                  {selectedService === dictServices.cpu ||
                  selectedService === dictServices.memory ||
                  selectedService === dictServices.temperature ||
                  selectedService === dictServices['Bgp Warning'] ||
                  selectedService === dictServices.sla ||
                  selectedService === dictServices['Interfaces Down'] ||
                  selectedService === dictServices['Interfaces Bandwith'] ? (
                    <tr>
                      <th style={{ width: '35%' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                          EQUIPO
                          <div className="wrapper-sort">
                            <div className="up" onClick={() => orderAlerts('up', 'hostname')}>
                              &#x25b4;
                            </div>
                            <div className="down" onClick={() => orderAlerts('down', 'hostname')}>
                              &#x25be;
                            </div>
                          </div>
                        </div>
                      </th>
                      <th style={{ width: '15%' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                          {getSecondColumnName()}
                          <div className="wrapper-sort">
                            <div className="up" onClick={() => orderAlerts('up', 'brand')}>
                              &#x25b4;
                            </div>
                            <div className="down" onClick={() => orderAlerts('down', 'brand')}>
                              &#x25be;
                            </div>
                          </div>
                        </div>
                      </th>
                      {getThirdColumnName()}
                      <th style={{ width: '25%' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {selectedService.startsWith('Interface') ? 'Nombre interface' : selectedService.toUpperCase()}
                          <div className="wrapper-sort">
                            <div className="up" onClick={() => orderAlerts('up', 'percentageUtilization')}>
                              &#x25b4;
                            </div>
                            <div className="down" onClick={() => orderAlerts('down', 'percentageUtilization')}>
                              &#x25be;
                            </div>
                          </div>
                        </div>
                      </th>
                      <th style={{ width: '10%' }}>ESTADO</th>
                      <th></th>
                    </tr>
                  ) : (
                    <tr>
                      <th style={{ width: '37%' }}>EQUIPO</th>
                      <th style={{ width: '15%' }}>BRAND</th>
                      <th style={{ width: '15%' }}>CÓDIGO</th>
                      <th style={{ width: '12%' }}>WARNING</th>
                      <th style={{ width: '12%' }}>CRITICAL</th>
                      <th style={{ width: '9%' }}>ESTADO</th>
                      <th></th>
                    </tr>
                  )}
                </thead>
                {selectedService === dictServices.cpu ||
                selectedService === dictServices.memory ||
                selectedService === dictServices.temperature ||
                selectedService === dictServices['Bgp Warning'] ||
                selectedService === dictServices.sla ||
                selectedService === dictServices['Interfaces Down'] ||
                selectedService === dictServices['Interfaces Bandwith'] ? (
                  <tbody>{getImportantServicesTable()}</tbody>
                ) : (
                  <tbody>{getServicesTable()}</tbody>
                )}
              </table>
            </div>
          }
        </div>
      )}
    </CSVHocComponent>
  );
}

export default ChequeosVitales;
