type Locations = {
  TAR: string;
  ANT: string;
  ATA: string;
  COQ: string;
  VAL: string;
  OHI: string;
  MAU: string;
  BIO: string;
  ARA: string;
  LAG: string;
  ARI: string;
  SGO: string;
  SCL: string;
  TEM: string;
  OSO: string;
  LLR: string;
  LRS: string;
  RIO: string;
  VAD: string;
  BUE: string;
  CAT: string;
  CHA: string;
  CHU: string;
  COR: string;
  ENR: string;
  JUJ: string;
  MZA: string;
  NQN: string;
  RNE: string;
  SAL: string;
  SJU: string;
  SLU: string;
  ROS: string;
  TUC: string;
};

const dict_region_name = {
  // BRASIL
  Sergipe: 'Sergipe',
  Bahia: 'Bahia',
  Alagoas: 'Alagoas',
  Sao_Paulo: 'Sao_Paulo',
  Pernambuco: 'Pernambuco',
  Ceara: 'Ceara',
  Espirito_Santo: 'Espirito_Santo',
  Minas_Gerais: 'Minas_Gerais',
  Goias: 'Goias',
  Rio_de_Janeiro: 'Rio_de_Janeiro',
  // COLOMBIA
  Magdalena: 'Magdalena',
  Casanare: 'Casanare',
  La_Guajira: 'La_Guajira',
  Tolima: 'Tolima',
  Norte_de_santander: 'Norte_de_santander',
  Boyaca: 'Boyaca',
  Nariño: 'Nariño',
  Huila: 'Huila',
  Atlantico: 'Atlantico',
  Valle_del_Cauca: 'Valle_del_Cauca',
  Risaralda: 'Risaralda',
  Caldas: 'Caldas',
  Cundinamarca: 'Cundinamarca',
  Antioquia: 'Antioquia',
  Santander: 'Santander',
};

// const region_name_colombia = {
//   Magdalena: 'Magdalena',
//   Casanare: 'Casanare',
//   La_Guajira: 'La_Guajira',
//   Tolima: 'Tolima',
//   Norte_de_santander: 'Norte_de_santander',
//   Boyaca: 'Boyaca',
//   Nariño: 'Nariño',
//   Huila: 'Huila',
//   Atlantico: 'Atlantico',
//   Valle_del_Cauca: 'Valle_del_Cauca',
//   Risaralda: 'Risaralda',
//   Caldas: 'Caldas',
//   Cundinamarca: 'Cundinamarca',
//   Antioquia: 'Antioquia',
//   Santander: 'Santander',
// };

const region_dictionary_colombia = {
  EA800: 'Cundinamarca',
  EA801: 'Cundinamarca',
  EA802: 'Cundinamarca',
  EA805: 'Cundinamarca',
  EA806: 'Antioquia',
  EA809: 'Cundinamarca',
  EA811: 'Cundinamarca',
  EA812: 'Cundinamarca',
  EA813: 'Cundinamarca',
  EA814: 'Antioquia',
  JU003: 'Cundinamarca',
  JU009: 'Risaralda',
  JU011: 'Cundinamarca',
  JU013: 'Valle_del_Cauca',
  JU014: 'Cundinamarca',
  JU015: 'Cundinamarca',
  JU016: 'Cundinamarca',
  JU017: 'Cundinamarca',
  JU018: 'Cundinamarca',
  JU019: 'Cundinamarca',
  JU022: 'Antioquia',
  JU023: 'Antioquia',
  JU024: 'Antioquia',
  JU027: 'Antioquia',
  JU030: 'Valle_del_Cauca',
  JU031: 'Valle_del_Cauca',
  JU036: 'Valle_del_Cauca',
  JU043: 'Atlantico',
  JU044: 'Cundinamarca',
  JU045: 'Magdalena',
  JU047: 'Valle_del_Cauca',
  JU048: 'Cundinamarca',
  JU070: 'Cundinamarca',
  JU072: 'Cundinamarca',
  JU076: 'Boyaca',
  JU083: 'Atlantico',
  JU084: 'Santander',
  JU086: 'Atlantico',
  JU087: 'Atlantico',
  JU088: 'Atlantico',
  JU103: 'Santander',
  JU108: 'Santander',
  JU126: 'Santander',
  JU128: 'Nariño',
  JU148: 'Antioquia',
  JU167: 'Cundinamarca',
  JU174: 'Atlantico',
  JU185: 'Casanare',
  JU191: 'Cundinamarca',
  JU193: 'Cundinamarca',
  JU194: 'Cundinamarca',
  JU249: 'Cundinamarca',
  JU253: 'Cundinamarca',
  MT006: 'Valle_del_Cauca',
  MT010: 'Cundinamarca',
  MT025: 'Antioquia',
  MT026: 'Cundinamarca',
  MT028: 'Boyaca',
  MT032: 'Valle_del_Cauca',
  MT033: 'Valle_del_Cauca',
  MT034: 'Santander',
  MT035: 'Valle_del_Cauca',
  MT038: 'Valle_del_Cauca',
  MT039: 'Santander',
  MT040: 'La_Guajira',
  MT042: 'Tolima',
  MT046: 'Antioquia',
  MT071: 'Cundinamarca',
  MT073: 'Cundinamarca',
  MT074: 'Cundinamarca',
  MT075: 'Valle_del_Cauca',
  MT077: 'Cundinamarca',
  MT078: 'Cundinamarca',
  MT079: 'Cundinamarca',
  MT080: 'Norte_de_santander',
  MT081: 'Norte_de_santander',
  MT082: 'Norte_de_santander',
  MT085: 'Atlantico',
  MT097: 'Boyaca',
  MT124: 'Cundinamarca',
  MT166: 'Risaralda',
  MT171: 'Nariño',
  MT179: 'Valle_del_Cauca',
  MT183: 'Santander',
  MT184: 'Atlantico',
  MT195: 'Huila',
  MT806: 'Antioquia',
  ME301: 'Cundinamarca',
  ME303: 'Cundinamarca',
  ME304: 'Cundinamarca',
  ME305: 'Cundinamarca',
  ME309: 'Cundinamarca',
  ME322: 'Santander',
  ME324: 'Cundinamarca',
  ME329: 'Cundinamarca',
  ME333: 'Cundinamarca',
  ME334: 'Cundinamarca',
  ME335: 'Cundinamarca',
  ME336: 'Cundinamarca',
  ME337: 'Cundinamarca',
  ES145: 'Cundinamarca',
  ES146: 'Cundinamarca',
  ES149: 'Cundinamarca',
  ES139: 'Cundinamarca',
  ES160: 'Cundinamarca',
  ES101: 'Cundinamarca',
  ES002: 'Cundinamarca',
  ES121: 'Cundinamarca',
  ES132: 'Cundinamarca',
  ES008: 'Cundinamarca',
  ES123: 'Atlantico',
  ES161: 'Cundinamarca',
  ES007: 'Antioquia',
  ES098: 'Antioquia',
  ES102: 'Valle_del_Cauca',
  ES125: 'Valle_del_Cauca',
  ES133: 'Risaralda',
  ES134: 'Valle_del_Cauca',
  ES136: 'Antioquia',
  ES158: 'Valle_del_Cauca',
  ES159: 'Risaralda',
  PL057: 'Caldas',
  PL060: 'Cundinamarca',
  PL067: 'Antioquia',
  PL069: 'Santander',
  PL095: 'Valle_del_Cauca',
  PL122: 'Atlantico',
  PL162: 'Cundinamarca',
  PL173: 'Antioquia',
  PL187: 'Valle_del_Cauca',
  PL233: 'Cundinamarca',
  PL892: 'Cundinamarca',
};

const region_dictionary_brasil = {
  GB001: 'Sergipe',
  GB002: 'Sergipe',
  GB003: 'Sergipe',
  GB004: 'Sergipe',
  GB005: 'Sergipe',
  GB006: 'Sergipe',
  GB007: 'Sergipe',
  GB008: 'Sergipe',
  GB009: 'Sergipe',
  GB010: 'Sergipe',
  GB011: 'Sergipe',
  GB012: 'Sergipe',
  GB013: 'Sergipe',
  GB014: 'Sergipe',
  GB015: 'Bahia',
  GB016: 'Bahia',
  GB017: 'Bahia',
  GB018: 'Bahia',
  GB019: 'Sergipe',
  GB020: 'Sergipe',
  GB021: 'Sergipe',
  GB022: 'Sergipe',
  GB023: 'Sergipe',
  GB024: 'Sergipe',
  GB025: 'Sergipe',
  GB026: 'Sergipe',
  GB027: 'Sergipe',
  GB028: 'Sergipe',
  GB030: 'Sergipe',
  GB031: 'Bahia',
  GB032: 'Bahia',
  GB033: 'Sergipe',
  GB034: 'Sergipe',
  GB035: 'Bahia',
  GB036: 'Bahia',
  GB037: 'Bahia',
  GB038: 'Bahia',
  GB039: 'Bahia',
  GB074: 'Bahia',
  GB076: 'Bahia',
  GB078: 'Sergipe',
  GB080: 'Alagoas',
  GB081: 'Alagoas',
  GB084: 'Alagoas',
  GB086: 'Alagoas',
  GB090: 'Sergipe',
  GB096: 'Alagoas',
  GB100: 'Sao_Paulo',
  GB102: 'Bahia',
  GB104: 'Bahia',
  GB106: 'Bahia',
  GB108: 'Bahia',
  GB112: 'Bahia',
  GB114: 'Sergipe',
  GB116: 'Alagoas',
  GB118: 'Sergipe',
  GB125: 'Bahia',
  GB131: 'Pernambuco',
  GB133: 'Bahia',
  GB139: 'Bahia',
  GB141: 'Ceara',
  GB149: 'Alagoas',
  GB151: 'Bahia',
  GB154: 'Bahia',
  GB156: 'Bahia',
  GB161: 'Bahia',
  GB162: 'Bahia',
  GB163: 'Bahia',
  GB164: 'Bahia',
  GB165: 'Bahia',
  GB166: 'Bahia',
  GB168: 'Alagoas',
  GB169: 'Bahia',
  GB170: 'Bahia',
  GB171: 'Bahia',
  GB172: 'Bahia',
  GB174: 'Bahia',
  GB175: 'Bahia',
  GB176: 'Bahia',
  GB177: 'Alagoas',
  GB185: 'Ceara',
  GB186: 'Ceara',
  GB196: 'Ceara',
  GB198: 'Ceara',
  GB199: 'Sergipe',
  GB200: 'Sergipe',
  GB201: 'Sergipe',
  GB202: 'Sergipe',
  GB203: 'Sergipe',
  GB204: 'Sergipe',
  GB205: 'Sergipe',
  GB206: 'Sergipe',
  GB207: 'Bahia',
  GB208: 'Bahia',
  GB209: 'Bahia',
  GB210: 'Bahia',
  GB211: 'Bahia',
  GB212: 'Bahia',
  GB213: 'Bahia',
  GB214: 'Bahia',
  GB216: 'Bahia',
  GB217: 'Bahia',
  GB218: 'Bahia',
  GB219: 'Bahia',
  GB220: 'Bahia',
  GB221: 'Bahia',
  GB222: 'Bahia',
  GB223: 'Bahia',
  GB224: 'Bahia',
  GB225: 'Sergipe',
  GB226: 'Sergipe',
  GB229: 'Sergipe',
  GB230: 'Bahia',
  GB231: 'Bahia',
  GB232: 'Sergipe',
  GB233: 'Sergipe',
  GB234: 'Bahia',
  GB235: 'Bahia',
  GB236: 'Bahia',
  GB237: 'Bahia',
  GB238: 'Bahia',
  GB239: 'Bahia',
  GB240: 'Bahia',
  GB241: 'Bahia',
  GB242: 'Bahia',
  GB243: 'Alagoas',
  GB244: 'Alagoas',
  GB245: 'Alagoas',
  GB246: 'Alagoas',
  GB247: 'Alagoas',
  GB248: 'Alagoas',
  GB249: 'Alagoas',
  GB250: 'Bahia',
  GB251: 'Bahia',
  GB252: 'Bahia',
  GB254: 'Bahia',
  GB255: 'Sergipe',
  GB256: 'Sergipe',
  GB257: 'Alagoas',
  GB259: 'Bahia',
  GB260: 'Bahia',
  GB261: 'Bahia',
  GB262: 'Bahia',
  GB263: 'Bahia',
  GB264: 'Alagoas',
  GB265: 'Bahia',
  GB266: 'Bahia',
  GB267: 'Alagoas',
  GB270: 'Bahia',
  GB271: 'Bahia',
  GB274: 'Bahia',
  GB276: 'Sergipe',
  GB278: 'Ceara',
  GB279: 'Bahia',
  GB280: 'Bahia',
  GB281: 'Bahia',
  GB283: 'Bahia',
  GB284: 'Bahia',
  GB289: 'Bahia',
  GB290: 'Sao_Paulo',
  GB302: 'Bahia',
  GB306: 'Bahia',
  GB311: 'Alagoas',
  GB315: 'Bahia',
  GB320: 'Sergipe',
  GB334: 'Ceara',
  GB338: 'Bahia',
  GB339: 'Espirito_Santo',
  BT501: 'Minas_Gerais',
  BT502: 'Minas_Gerais',
  BT503: 'Minas_Gerais',
  BT505: 'Minas_Gerais',
  BT506: 'Minas_Gerais',
  BT507: 'Minas_Gerais',
  BT508: 'Minas_Gerais',
  BT509: 'Minas_Gerais',
  BT510: 'Minas_Gerais',
  BT511: 'Minas_Gerais',
  BT512: 'Minas_Gerais',
  BT513: 'Minas_Gerais',
  BT514: 'Minas_Gerais',
  BT515: 'Minas_Gerais',
  BT516: 'Goias',
  BT518: 'Minas_Gerais',
  BT520: 'Minas_Gerais',
  BT522: 'Minas_Gerais',
  BT523: 'Minas_Gerais',
  BT524: 'Goias',
  BT525: 'Goias',
  BT527: 'Goias',
  BT530: 'Minas_Gerais',
  BT533: 'Minas_Gerais',
  BT534: 'Minas_Gerais',
  BT535: 'Minas_Gerais',
  BT537: 'Minas_Gerais',
  BT538: 'Goias',
  BT539: 'Minas_Gerais',
  BT540: 'Minas_Gerais',
  BT541: 'Goias',
  BT542: 'Minas_Gerais',
  BT544: 'Minas_Gerais',
  BT545: 'Goias',
  BT548: 'Minas_Gerais',
  BT550: 'Minas_Gerais',
  BT551: 'Goias',
  BT552: 'Goias',
  BT553: 'Minas_Gerais',
  BT554: 'Goias',
  BT557: 'Minas_Gerais',
  BT558: 'Minas_Gerais',
  BT559: 'Minas_Gerais',
  BT561: 'Minas_Gerais',
  BT562: 'Minas_Gerais',
  BT564: 'Minas_Gerais',
  BT569: 'Minas_Gerais',
  BT572: 'Minas_Gerais',
  BT575: 'Goias',
  BT579: 'Minas_Gerais',
  BT580: 'Minas_Gerais',
  BT607: 'Minas_Gerais',
  BT613: 'Minas_Gerais',
  BT614: 'Minas_Gerais',
  BT615: 'Goias',
  BT616: 'Minas_Gerais',
  BT617: 'Minas_Gerais',
  BT618: 'Minas_Gerais',
  BT619: 'Goias',
  BT620: 'Minas_Gerais',
  BT622: 'Minas_Gerais',
  BT623: 'Minas_Gerais',
  BT624: 'Minas_Gerais',
  BT625: 'Minas_Gerais',
  BT626: 'Goias',
  BT627: 'Minas_Gerais',
  BT628: 'Minas_Gerais',
  BT631: 'Goias',
  BT632: 'Goias',
  BT633: 'Goias',
  BT634: 'Goias',
  BT635: 'Goias',
  BT636: 'Minas_Gerais',
  BT638: 'Minas_Gerais',
  BT639: 'Minas_Gerais',
  BT640: 'Goias',
  BT643: 'Minas_Gerais',
  BT644: 'Goias',
  BT645: 'Goias',
  BT647: 'Minas_Gerais',
  BT648: 'Goias',
  BT649: 'Minas_Gerais',
  BT651: 'Goias',
  BT655: 'Goias',
  BT656: 'Goias',
  BT658: 'Goias',
  BT660: 'Minas_Gerais',
  BT662: 'Goias',
  PZ700: 'Rio_de_Janeiro',
  PZ702: 'Rio_de_Janeiro',
  PZ703: 'Rio_de_Janeiro',
  PZ704: 'Rio_de_Janeiro',
  PZ705: 'Rio_de_Janeiro',
  PZ706: 'Rio_de_Janeiro',
  PZ707: 'Rio_de_Janeiro',
  PZ708: 'Rio_de_Janeiro',
  PZ709: 'Rio_de_Janeiro',
  PZ710: 'Rio_de_Janeiro',
  PZ711: 'Rio_de_Janeiro',
  PZ712: 'Rio_de_Janeiro',
  PZ713: 'Rio_de_Janeiro',
  PZ714: 'Rio_de_Janeiro',
  PZ715: 'Rio_de_Janeiro',
  PZ716: 'Rio_de_Janeiro',
  PZ717: 'Rio_de_Janeiro',
  PZ718: 'Rio_de_Janeiro',
  PZ719: 'Rio_de_Janeiro',
  PZ720: 'Rio_de_Janeiro',
  PZ721: 'Rio_de_Janeiro',
  PZ722: 'Rio_de_Janeiro',
  PZ723: 'Rio_de_Janeiro',
  PZ724: 'Rio_de_Janeiro',
  PZ725: 'Rio_de_Janeiro',
  PZ726: 'Rio_de_Janeiro',
  PZ727: 'Rio_de_Janeiro',
  PZ728: 'Rio_de_Janeiro',
  PZ729: 'Rio_de_Janeiro',
  PZ730: 'Rio_de_Janeiro',
  PZ731: 'Rio_de_Janeiro',
  PZ733: 'Rio_de_Janeiro',
  PZ734: 'Rio_de_Janeiro',
  PZ736: 'Rio_de_Janeiro',
  PZ742: 'Rio_de_Janeiro',
  PZ743: 'Rio_de_Janeiro',
  PZ744: 'Rio_de_Janeiro',
  PZ745: 'Rio_de_Janeiro',
  PZ746: 'Rio_de_Janeiro',
  PZ747: 'Rio_de_Janeiro',
  PZ748: 'Rio_de_Janeiro',
  PZ772: 'Rio_de_Janeiro',
  PZ777: 'Rio_de_Janeiro',
  PZ779: 'Rio_de_Janeiro',
  PZ780: 'Rio_de_Janeiro',
  PZ781: 'Rio_de_Janeiro',
  PZ782: 'Rio_de_Janeiro',
  PZ783: 'Rio_de_Janeiro',
  PZ784: 'Rio_de_Janeiro',
  PZ785: 'Rio_de_Janeiro',
  PZ786: 'Rio_de_Janeiro',
  PZ787: 'Rio_de_Janeiro',
  PZ788: 'Rio_de_Janeiro',
  PZ789: 'Rio_de_Janeiro',
  MR801: 'Bahia',
  MR802: 'Bahia',
  MR806: 'Sergipe',
  MR807: 'Bahia',
  MR810: 'Bahia',
  MR813: 'Bahia',
  MR814: 'Bahia',
  MR815: 'Bahia',
  MR816: 'Bahia',
  MR817: 'Bahia',
  G901: 'Sao_Paulo',
  G902: 'Sao_Paulo',
  G903: 'Sao_Paulo',
  G904: 'Sao_Paulo',
  G905: 'Sao_Paulo',
  G906: 'Sao_Paulo',
  G907: 'Sao_Paulo',
  G908: 'Sao_Paulo',
  G909: 'Sao_Paulo',
  G910: 'Sao_Paulo',
  G911: 'Sao_Paulo',
  G913: 'Sao_Paulo',
  G915: 'Sao_Paulo',
  G950: 'Sao_Paulo',
};

const region_dictionary: Locations = {
  TAR: 'Tarapaca',
  ANT: 'Antofagasta',
  ATA: 'Atacama',
  COQ: 'Coquimbo',
  VAL: 'Valparaiso',
  OHI: 'Libertador_General_Bernardo_OHiggins',
  MAU: 'Maule',
  BIO: 'Bio-Bio',
  ARA: 'La_Araucania',
  LAG: 'Los_Lagos',
  ARI: 'Arica_y_Parinacota',
  SGO: 'Region_Metropolitana_de_Santiago',
  SCL: 'Region_Metropolitana_de_Santiago',
  TEM: 'La_Araucania',
  OSO: 'Los_Lagos',
  LLR: 'Los_Rios',
  LRS: 'Los_Rios',
  RIO: 'Los_Rios',
  VAD: 'Los_Rios',
  BUE: 'Buenos_Aires',
  CAT: 'Catamarca',
  CHA: 'Chaco',
  CHU: 'Chubut',
  COR: 'Cordoba',
  ENR: 'Entre_Rios',
  JUJ: 'Jujuy',
  // RIO: 'La Rioja', <= Para que funcion en el mapa esta como Los_Rios
  MZA: 'Mendoza',
  NQN: 'Neuquen',
  RNE: 'Rio_Negro',
  SAL: 'Salta',
  SJU: 'San_Juan',
  SLU: 'San_Luis',
  ROS: 'Santa_Fe',
  // SGO: 'Santiago_Del_Estero', <= Para que funcione en el mapa esta como Region_Metropolitana_de_Santiago
  TUC: 'Tucuman',
};
// CON: 'Concon',
// CUR: 'Curico',
// IND: 'Independencia',
// LAN: 'Lanco',
// LIN: 'Linares',
// NUB: 'Nuble',
// OSO: 'Osorno',
// QUI: 'Quilpue',
// RAN: 'Rancagua',
// TAL: 'Talca',
// TEM: 'Temuco',
// VAL: 'Valdivia',

const region_number = {
  Tarapaca: 'I',
  Antofagasta: 'II',
  Atacama: 'III',
  Coquimbo: 'IV',
  Valparaiso: 'V',
  Libertador_General_Bernardo_OHiggins: 'VI',
  Maule: 'VII',
  'Bio-Bio': 'VIII',
  La_Araucania: 'IX',
  Los_Lagos: 'X',
  Aisen_del_General_Carlos_Ibanez_del_Campo: 'XI',
  Magallanes_y_Antartica_Chilena: 'XII',
  Los_Rios: 'XIV',
  Arica_y_Parinacota: 'XV',
  Region_Metropolitana_de_Santiago: 'RM',
};

export {
  region_dictionary,
  region_number,
  region_dictionary_brasil,
  dict_region_name,
  region_dictionary_colombia,
  // region_name_colombia,
};
