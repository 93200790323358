import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './TableActiveAlerts.css';

type Props = {
  data: any;
};

const TableActiveAlerts: React.FC<Props> = ({ data }) => {
  return (
    <section className="tableactivealerts">
      <MDBDataTable
        data={data}
        scrollY
        striped
        small
        noBottomColumns={true}
        paging={true}
        searching={true}
        pagesAmount={4}
        entries={100}
        // responsive
        // autoWidth
        // className='text-center mt-5'
      />
      {/* <MDBTable scrollY order={['age', 'asc' ]} >
            <MDBTableHead columns={data.columns} />
            <MDBTableBody rows={data.rows} />
        </MDBTable> */}
    </section>
  );
};

export default TableActiveAlerts;
