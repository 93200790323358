import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
// import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';

import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/mira/theme.css';
import './TableActiveAlerts2.css';

type Props = {
  data: any;
  filters: any;
  globalFilterFields: any;
  showClearFilter: any;
  onClearAll: any;
};

const TableActiveAlerts2: React.FC<Props> = ({
  data,
  filters,
  showClearFilter = false,
  onClearAll = () => {},
  globalFilterFields,
}) => {
  // console.log(data);
  // console.log(filters);
  // const [globalFilterValue, setGlobalFilterValue] = useState('');
  // const [filters1, setFilters] = useState({});

  // useEffect(() => {}, []);

  const DeviceItemTemplate = (rowData: any, field: any) => {
    return <span style={{ fontSize: '11px' }}>{rowData[field]}</span>;
  };

  const ItemTemplate = (option: any) => {
    return <span>{option}</span>;
  };

  const Type2FilterTemplate = (options: any, arrayOptions: any, setActype: any, value: any) => {
    // console.log(options);
    // console.log(arrayOptions);
    // console.log(setActype);
    // console.log(value);

    return (
      <MultiSelect
        value={setActype ? value : options.value}
        options={arrayOptions}
        onChange={(e: any) => {
          // console.log(e.value);
          if (setActype) {
            setActype(e.value);
          } else {
            options.filterApplyCallback(e.value);
          }
        }}
        itemTemplate={ItemTemplate}
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  const columns = data.columns.map((column: any, index: any) => {
    if (column.filter === 'text') {
      return (
        <Column
          key={`${index}-table-active-alert-text`}
          field={column.field}
          filterField={column.field}
          filter
          filterPlaceholder={column.filterPlaceholder}
          header={column.label}
        ></Column>
      );
    } else if (column.filter === 'select') {
      console.log('entra al select');

      return (
        <Column
          key={`${index}-table-active-alert-select`}
          header={column.label}
          filterField={column.field}
          showFilterMatchModes={false}
          filterMenuStyle={{ width: '6rem' }}
          style={{ minWidth: '4rem', maxWidth: '10rem' }}
          body={(rowData: any) => DeviceItemTemplate(rowData, column.field)}
          filter
          filterElement={(options: any) =>
            Type2FilterTemplate(options, column.arrayOptions, column.setValue, column.currentValue)
          }
          onFilterClear={column.onClear}
        ></Column>
      );
    } else {
      return (
        <Column
          key={`${index}-table-active-alert`}
          field={column.field}
          header={column.label}
          body={column.body}
        ></Column>
      );
    }
  });

  return (
    <section className="tableactivealerts">
      {showClearFilter && (
        <div className="clear-filter" onClick={onClearAll}>
          Limpiar filtros
        </div>
      )}
      <DataTable
        filterClearIcon
        showGridlines
        value={data.rows}
        filterDisplay="menu"
        filters={filters}
        tableStyle={{ minWidth: '50rem', marginTop: '10px' }}
      >
        {columns}
      </DataTable>
    </section>
  );
};

export default TableActiveAlerts2;
