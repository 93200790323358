// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supplier-wrap {
  width: 254px;
  height: 100%;
  background-color: #10202b;
  border-right: 1px solid #023154;
  padding: 0 10px 10px 10px;
  overflow-y: auto;
}

.supplier-wrap:hover {
  background-color: #023154;
}

.supplier-wrap .title-supplier-wrap {
  height: 50px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  position: relative;
}

.supplier-wrap .title-supplier-wrap .icon-eye-problems {
  position: absolute;
  cursor: pointer;
  right: 32px;
}

.supplier-wrap .each-supplier {
  height: max-content;
  margin-bottom: 10px;
  border: 2px solid #ffffff;
  border-radius: 12px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./views/NetworkPanel/components/Suppliers/Suppliers.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,+BAA+B;EAC/B,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".supplier-wrap {\n  width: 254px;\n  height: 100%;\n  background-color: #10202b;\n  border-right: 1px solid #023154;\n  padding: 0 10px 10px 10px;\n  overflow-y: auto;\n}\n\n.supplier-wrap:hover {\n  background-color: #023154;\n}\n\n.supplier-wrap .title-supplier-wrap {\n  height: 50px;\n  width: 100%;\n  color: #ffffff;\n  font-size: 16px;\n  font-weight: 400;\n  padding-left: 10px;\n  display: flex;\n  align-items: center;\n  font-weight: bold;\n  position: relative;\n}\n\n.supplier-wrap .title-supplier-wrap .icon-eye-problems {\n  position: absolute;\n  cursor: pointer;\n  right: 32px;\n}\n\n.supplier-wrap .each-supplier {\n  height: max-content;\n  margin-bottom: 10px;\n  border: 2px solid #ffffff;\n  border-radius: 12px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
