//checkmk
import AxiosFactory from './AxiosCheckmk';
const pako = require('pako');

function recoverOriginalContent(compressedBase64) {
  try {
    const compressedData = atob(compressedBase64);
    const compressedArray = new Uint8Array(compressedData.length);
    for (let i = 0; i < compressedData.length; i++) {
      compressedArray[i] = compressedData.charCodeAt(i);
    }
    const pako = require('pako');
    const decompressedArray = pako.inflate(compressedArray);
    const originalString = new TextDecoder().decode(decompressedArray);
    return originalString;
  } catch (error) {
    console.error('Error recovering original content:', error);
    return null;
  }
}

export async function getHosts(baseURL) {
  let response;
  const checkmkApi = AxiosFactory(baseURL);
  response = await checkmkApi.get('cenr/NREDES_hosts');
  if (response.data && response.data.compressed) {
    const decoded = recoverOriginalContent(response.data.data);
    response = JSON.parse(decoded);
    return response;
  } else {
    return response.data;
  }
}

export async function getSniProblems(baseURL) {
  let response;
  const checkmkApi = AxiosFactory(baseURL);
  response = await checkmkApi.get('cenr/NREDES_sni_problems');
  if (response.data && response.data.compressed) {
    const decoded = recoverOriginalContent(response.data.data);
    response = JSON.parse(decoded);
    return response;
  } else {
    return response.data;
  }
}

export async function getCriticalServices(baseURL) {
  let response;
  const checkmkApi = AxiosFactory(baseURL);
  response = await checkmkApi.get('cenr/NREDES_critical_services');
  if (response.data && response.data.compressed) {
    const decoded = recoverOriginalContent(response.data.data);
    response = JSON.parse(decoded);
    return response;
  } else {
    return response.data;
  }
}

export async function getHostsWithProblems(baseURL) {
  let response;
  const checkmkApi = AxiosFactory(baseURL);
  response = await checkmkApi.get('cenr/NREDES_hosts_with_problems');
  if (response.data && response.data.compressed) {
    const decoded = recoverOriginalContent(response.data.data);
    response = JSON.parse(decoded);
    return response;
  } else {
    return response.data;
  }
}

export async function getServices(baseURL, site) {
  const checkmkApi = AxiosFactory(baseURL);
  let response = await checkmkApi.get(`cenr/NREDES_services`);
  if (response.data && response.data.compressed) {
    const decoded = recoverOriginalContent(response.data.data);
    response = JSON.parse(decoded);
    return response;
  } else {
    return response.data;
  }
}

export async function getServicesCloud(baseURL, site) {
  const checkmkApi = AxiosFactory(baseURL);
  let response = await checkmkApi.get(`cenr/NREDES_services_cloud`);
  if (response.data && response.data.compressed) {
    const decoded = recoverOriginalContent(response.data.data);
    response = JSON.parse(decoded);
    return response;
  } else {
    return response.data;
  }
}

export async function getLinks(baseURL, site) {
  const checkmkApi = AxiosFactory(baseURL);
  let response = await checkmkApi.get(`cenr/NREDES_links`);
  if (response.data && response.data.compressed) {
    const decoded = recoverOriginalContent(response.data.data);
    response = JSON.parse(decoded);
    return response;
  } else {
    return response.data;
  }
}

export async function getServicesCpuMemory(baseURL, site) {
  const checkmkApi = AxiosFactory(baseURL);
  let response = await checkmkApi.get(`cenr/NREDES_services_cpu_memory`);
  if (response.data && response.data.compressed) {
    const decoded = recoverOriginalContent(response.data.data);
    response = JSON.parse(decoded);
    return response;
  } else {
    return response.data;
  }
}

export async function getServicesLinks(baseURL, site) {
  const checkmkApi = AxiosFactory(baseURL);
  let response = await checkmkApi.get(`cenr/NREDES_services_links`);
  if (response.data && response.data.compressed) {
    const decoded = recoverOriginalContent(response.data.data);
    response = JSON.parse(decoded);
    return response;
  } else {
    return response.data;
  }
}
