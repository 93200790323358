const dictNamesBySite = {
  nredes_co: 'colombia',
  nredes_co2: 'colombia',
  nredes_co3: 'colombia',
  nredes_br: 'brasil',
  nredes_br2: 'brasil',
  nredes_br3: 'brasil',
  nredes_pe: 'peru',
  nredes_pe2: 'peru',
  nredes_pe3: 'peru',
  nredes_cl: 'chile',
  nredes_cl2: 'chile',
  nredes_cl3: 'chile',
  nredes_ar: 'argentina',
  nredes_ar2: 'argentina',
  nredes_ar3: 'argentina',
};

export { dictNamesBySite };
