import React, { useState } from 'react';

import CSVHocComponent from 'components/CSVHocComponent/CSVHocComponent';
import GeographicMap from 'components/GeographicMap/GeographicMap';

import './Geomap.css';

function Geomap({ storesCd, arrayRegionStatus, selectedCountries, handleShowSectionStoreStatus }) {
  const [scale, setScale] = useState(0);

  let infoStoreProblems =
    storesCd && storesCd['resumeStores']
      ? [...storesCd['resumeStores']['problems'], ...storesCd['resumeCD']['problems']]
      : [];

  function getDataToDownload() {
    const arrayDataToDownload = [];
    arrayRegionStatus.forEach((region) => {
      if (Object.keys(region.serviceArrayProblems).length !== 0) {
        for (const store in region.serviceArrayProblems) {
          const storeProblems = region.serviceArrayProblems[store];
          storeProblems.forEach((element) => {
            arrayDataToDownload.push({
              region: region.region,
              regionStatus: region.status,
              totalStores: region.storeTotal,
              problemStores: region.storeProblems,
              totalDevices: region.deviceTotal,
              totalEnlaces: region.enlacesTotal,
              // problems: region.problems,
              storeWithProblem: store,
              hostname: element.host_name,
              service: element.description === 'Ping' ? 'Tienda caida' : element.description,
              serviceStatus: element.state,
              acknowledged: element.acknowledged,
            });
          });
        }
      } else {
        arrayDataToDownload.push({
          region: region.region,
          regionStatus: region.status,
          totalStores: region.storeTotal,
          problemStores: region.storeProblems,
          totalDevices: region.deviceTotal,
          totalEnlaces: region.enlacesTotal,
          // problems: region.problems,
          storeWithProblem: '',
          hostname: '',
          service: '',
          serviceStatus: '',
          acknowledged: '',
        });
      }
    });
    return arrayDataToDownload;
  }

  function resizeSection(type) {
    let newScale = scale;
    if (type === 'in') {
      newScale += 0.5;
    } else if (type === 'out') {
      newScale -= 0.5;
    }
    setScale(newScale);
  }

  return (
    <CSVHocComponent personalizedClass={'geomap-wrap'} data={getDataToDownload()}>
      <div className="title-geomap">{'Geomap / Tiendas caidas'}</div>
      <div className="graphic-geomap">
        <div className="actions">
          <div className="click" onClick={() => resizeSection('in')}>
            +
          </div>
          <div className="click" onClick={() => resizeSection('out')}>
            -
          </div>
        </div>
        <div className="graphic-wrap">
          <GeographicMap
            chooseRegion={() => console.log()}
            regiones={arrayRegionStatus}
            scale={scale}
            country={selectedCountries[0].name.toLowerCase()}
            onSelectSectionStoreStatus={handleShowSectionStoreStatus}
            storeProblems={infoStoreProblems}
          />
        </div>
      </div>
    </CSVHocComponent>
  );
}

export default Geomap;
