import React, { useState, useEffect } from 'react';

import DatacenterWrap from 'components/DatacenterWrap/DatacenterWrap';
import Enlace from 'components/Enlace/Enlace';
//@ts-ignore
import Network2 from 'components/Network2/Network2';
import SdwanCard from 'components/SdwanCard/SdwanCard';
import TableActiveAlerts from 'components/TableActiveAlerts/TableActiveAlerts';
import TableActiveAlerts2 from 'components/TableActiveAlerts2/TableActiveAlerts2';
import CustomTooltip from 'components/Tooltip/Tooltip';
import CloudNetwork from 'components/CloudNetwork/CloudNetwork';
import AWS from '../../img/aws.svg';
import GCP from '../../img/gcp.png';
import AZURE from '../../img/azure.png';
import ORACLE from '../../img/oracle.png';
import ORACLE2 from '../../img/oracle-small.svg';
import ack from '../../img/icon_ack.png';

import { getStatusColorByType, getStatusColorByNumber } from '../../utils/color';
//@ts-ignore
import { buildHostUrl } from 'utils/common.js';

import { FilterMatchMode } from 'primereact/api';

import './DatacenterPanel.css';

type Props = {
  services: any;
  hosts: any;
  infoDatacenter: any;
  arraytoDraw: any;
  arrayToDrawList: any;
  enlacesProblems: any;
  allProblems: any;
  servicesCloud: any;
  siteUrl: any;
  alertSLAduration: any;
  // hostStatusInfo: any;
  hostsTagCloud: any;
  cloudUsage: any;
};

const DatacenterPanel: React.FC<Props> = ({
  hosts = [],
  services = [],
  infoDatacenter,
  // arraytoDraw,
  arrayToDrawList,
  enlacesProblems,
  allProblems,
  servicesCloud,
  siteUrl,
  // alertSLAduration,
  // hostStatusInfo,
  hostsTagCloud,
  cloudUsage,
}) => {
  const [selectedDashboard, setSelectedDashboard] = useState('main');
  const [dtCnt, setDtCnt] = useState(infoDatacenter);
  const [dtEnlaces, setDtEnlaces] = useState(arrayToDrawList);
  const [alertRowsToGraphic, setAlertRowsToGraphic] = useState<any[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [statusCloud, setStatusCloud] = useState({
    CHILE: { sla: { AZURE: 0, AWS: 0, GCP: 0, ORACLE: 0 }, vpn: { AZURE: 0, AWS: 0, GCP: 0, ORACLE: 0 } },
    ARGENTINA: { sla: { AZURE: 0, AWS: 0, GCP: 0, ORACLE: 0 }, vpn: { AZURE: 0, AWS: 0, GCP: 0, ORACLE: 0 } },
    BRASIL: { sla: { AZURE: 0, AWS: 0, GCP: 0, ORACLE: 0 }, vpn: { AZURE: 0, AWS: 0, GCP: 0, ORACLE: 0 } },
    PERU: { sla: { AZURE: 0, AWS: 0, GCP: 0, ORACLE: 0 }, vpn: { AZURE: 0, AWS: 0, GCP: 0, ORACLE: 0 } },
    COLOMBIA: { sla: { AZURE: 0, AWS: 0, GCP: 0, ORACLE: 0 }, vpn: { AZURE: 0, AWS: 0, GCP: 0, ORACLE: 0 } },
    '172.22.215.225': 3, //aws virginia
    '172.22.215.222': 3, //aws oregon
    '172.22.215.226': 3, //azure
    '172.22.215.223': 3, //gcp c del sur
    '172.22.215.227': 3, //gcp c del sur legacy
    '172.22.215.224': 3, //gcp santiago
    '172.22.215.228': 3, //oracle ashburn
    '172.22.215.229': 3, //oracle sao
    '172.22.215.221': 3, //oracle stgo
    '172.18.175.55': 3, //chile
    '172.18.209.6': 3, //chile 2
    '172.17.3.82': 3, //argentina
    '172.20.90.66': 3, //colombia
    '172.21.40.35': 3, //peru
    '172.20.160.162': 3, //brasil
  });
  const [cpuMemoryUtilization, setCpuMemoryUtilization] = useState({ AWS: [], AZURE: [], GCP: [], ORACLE: [] });
  const [devices] = useState(['Switch', 'Router', 'Firewall', 'Wireless', 'Telefonía', 'Enlace', 'Otros']);
  const [locations] = useState(['CHILE', 'ARGENTINA', 'BRASIL', 'COLOMBIA', 'PERU']);
  const [filters] = useState({
    device_type: { value: null, matchMode: FilterMatchMode.EQUALS },
    location: { value: null, matchMode: FilterMatchMode.EQUALS },
    global: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [filtersCloud] = useState({
    type2: { value: null, matchMode: FilterMatchMode.EQUALS },
    country: { value: null, matchMode: FilterMatchMode.EQUALS },
    cloud: { value: null, matchMode: FilterMatchMode.EQUALS },
    location: { value: null, matchMode: FilterMatchMode.EQUALS },
    global: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [actype, setActype] = useState(['SLA', 'VPN']);
  const [acCloud, setAcCloud] = useState(['AWS', 'AZURE', 'GCP', 'ORACLE']);
  const [acCountry, setAcCountry] = useState(['CHILE', 'ARGENTINA', 'BRASIL', 'COLOMBIA', 'PERU', '']);

  const country_prefixes = { cl: 'CHILE', ar: 'ARGENTINA', br: 'BRASIL', pe: 'PERU', co: 'COLOMBIA' } as any;

  useEffect(() => {
    setDtCnt(infoDatacenter);
  }, [hosts, services, infoDatacenter, arrayToDrawList]);

  useEffect(() => {
    setDtEnlaces(arrayToDrawList);
  }, [arrayToDrawList]);

  function getInfo() {
    const filteredItems = dtCnt.filter((item: any) => item.arrayDatacenter.length > 0);

    const sortedItems = filteredItems.sort((a: any, b: any) => {
      const valueA = a.arrayDatacenter[0]?.status;
      const valueB = b.arrayDatacenter[0]?.status;
      return valueB - valueA;
    });
    return sortedItems.map((dt: any, index: number) => (
      <DatacenterWrap
        key={`dt-inf-${index}`}
        name={dt.name}
        siteUrl={siteUrl}
        totalAlerts={dt.totalAlerts}
        arrayDatacenter={dt.arrayDatacenter}
        showAll={showAll}
      />
    ));
  }

  function compare(a: any, b: any) {
    if (a.last_state_change < b.last_state_change) {
      return -1;
    }
    if (a.last_state_change > b.last_state_change) {
      return 1;
    }
    return 0;
  }

  function getDate(date: any) {
    const currentDate = new Date(date * 1000);
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let seconds = currentDate.getSeconds();
    let formattedDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    let formattedTime =
      (hours < 10 ? '0' : '') +
      hours +
      ':' +
      (minutes < 10 ? '0' : '') +
      minutes +
      ':' +
      (seconds < 10 ? '0' : '') +
      seconds;
    return { formattedDate, formattedTime };
  }

  function getMain(enlaces: any) {
    return (
      <>
        <div className="enlaces">
          <div className="title">Red Internacional</div>
          <div className="action-button" onClick={() => setSelectedDashboard('enlaces')}>
            <div className="triangle"></div>
          </div>
          <div className="label-vecindades">VECINDADES</div>
          <div className="vecindades">
            {enlaces.map((dt: any, index: number) => {
              const [fromCountry] = dt.from.split('-');
              const [toCountry] = dt.to.split('-');
              if (!fromCountry && !toCountry) {
                return null;
              } else {
                return (
                  <div className="wrap-enlace" key={`enl-${index}`}>
                    <Enlace
                      from={dt.from}
                      fromStatus={dt.fromStatus}
                      fromIp={dt.fromIp}
                      to={dt.to}
                      toStatus={dt.toStatus}
                      status={dt.status}
                      label={dt.label}
                      problems={dt.problems}
                      speed={dt.speed}
                      siteUrl={siteUrl}
                      siteName={dt.site}
                      spd={dt.spd}
                      usein={dt.usein}
                      useout={dt.useout}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="sdwan">
          <div className="title">Cloud SDWAN</div>
          <div className="action-button" onClick={() => setSelectedDashboard('cloud')}>
            <div className="triangle"></div>
          </div>
          <div className="label-vecindades">ESTADO Y USO DE RECURSOS</div>
          <div className="content-sdwan-vertical">
            <SdwanCard
              title={'AWS'}
              keyCloud={'AWS'}
              image={AWS}
              statusCloud={statusCloud}
              cloudUsage={cpuMemoryUtilization}
              display={'vertical'}
              selectAlerts={selectAlerts}
            />
            <SdwanCard
              title={'Google cloud'}
              keyCloud={'GCP'}
              image={GCP}
              statusCloud={statusCloud}
              cloudUsage={cpuMemoryUtilization}
              display={'vertical'}
              selectAlerts={selectAlerts}
            />
            <SdwanCard
              title={'Azure'}
              keyCloud={'AZURE'}
              image={AZURE}
              statusCloud={statusCloud}
              cloudUsage={cpuMemoryUtilization}
              display={'vertical'}
              selectAlerts={selectAlerts}
            />
            <SdwanCard
              title={'Oracle'}
              keyCloud={'ORACLE'}
              image={ORACLE}
              statusCloud={statusCloud}
              cloudUsage={cpuMemoryUtilization}
              display={'vertical'}
              selectAlerts={selectAlerts}
            />
          </div>
        </div>
        <div className="info">
          <div className="title">Datacenter</div>
          <div className="action-button" onClick={() => setSelectedDashboard('general-status')}>
            <div className="triangle"></div>
          </div>
          <div className="label-vecindades">ESTADO GENERAL</div>
          <div className="show-all">
            {'Mostrar todos  '}
            <input type="checkbox" defaultChecked={showAll} onChange={() => setShowAll(!showAll)} />
          </div>
          <div className="info-content">{getInfo()}</div>
        </div>
      </>
    );
  }

  function getEnlaces(enlaces: any) {
    const alertRows = enlacesProblems.map((enlaceItem: any) => {
      console.log(enlaceItem);
      return {
        type: (
          <div
            className="icon-status"
            style={{ backgroundColor: getStatusColorByType(enlaceItem.service_state) }}
          ></div>
        ),
        host: (
          <a
            href={`${buildHostUrl(enlaceItem.host, enlaceItem.site, siteUrl[enlaceItem.site])}`}
            target="_blank"
            rel="noreferrer"
          >
            {enlaceItem.host}
          </a>
        ),
        ip: enlaceItem.ip,
        service: enlaceItem.service_description,
        hour: enlaceItem.svc_state_age,
      };
    });
    const data = {
      columns: [
        {
          label: 'TIPO',
          field: 'type',
          sort: 'asc',
          width: 80,
        },
        {
          label: 'HOST',
          field: 'host',
          sort: 'asc',
          width: 270,
        },
        {
          label: 'IP',
          field: 'ip',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'SERVICIO',
          field: 'service',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'HORA',
          field: 'hour',
          sort: 'asc',
          width: 100,
        },
      ],

      rows: alertRows,
    };
    return (
      <>
        <div className="main-enlaces" style={{ width: '20%' }}>
          <div className="title-main-enlaces">
            <div className="label-title-main-enlaces">Red internacional</div>
          </div>
          <div className="wrap-enlaces-main-enlaces">
            <div className="enlaces">
              <div className="label-vecindades">VECINDADES</div>
              <div className="vecindades">
                {enlaces &&
                  enlaces.map((dt: any, index: number) => {
                    const [fromCountry] = dt.from.split('-');
                    const [toCountry] = dt.to.split('-');
                    if (!fromCountry && !toCountry) {
                      return null;
                    } else {
                      return (
                        <div className="wrap-enlace" key={`enl-${index}`}>
                          <Enlace
                            from={dt.from}
                            fromStatus={dt.fromStatus}
                            to={dt.to}
                            toStatus={dt.toStatus}
                            status={dt.status}
                            label={dt.label}
                            problems={dt.problems}
                            speed={dt.speed}
                            siteUrl={siteUrl}
                            siteName={dt.site}
                            fromIp={dt.fromIp}
                            spd={dt.spd}
                            usein={dt.usein}
                            useout={dt.useout}
                          />
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="graphic-enlaces">
          <div className="title-graphic-enlaces">
            <div className="label-graphic-enlaces">DIAGRAMA DE RED INTERNACIONAL</div>
            <div
              className="action-title-main-enlaces"
              style={{ cursor: 'pointer' }}
              onClick={() => setSelectedDashboard('main')}
            >
              &larr; Volver
            </div>
          </div>
          <div className="body-graphic-enlaces">
            <Network2 enlaces={enlaces} />
          </div>
        </div>
        <div className="active-alerts" style={{ width: '30%' }}>
          <div className="title-active-alerts">ALERTAS ACTIVAS</div>
          <div className="body-active-alerts">
            <TableActiveAlerts data={data} />
          </div>
        </div>
      </>
    );
  }

  useEffect(() => {
    const alertRows: any[] = [];
    const cloudObjectStatus: any = {
      CHILE: {
        sla: {
          AWS: 3,
          GCP: 3,
          AZURE: 3,
          ORACLE: 3,
        },
        vpn: {
          AWS: 3,
          GCP: 3,
          AZURE: 3,
          ORACLE: 3,
        },
      },
      ARGENTINA: {
        sla: {
          AWS: 3,
          GCP: 3,
          AZURE: 3,
          ORACLE: 3,
        },
        vpn: {
          AWS: 3,
          GCP: 3,
          AZURE: 3,
          ORACLE: 3,
        },
      },
      BRASIL: {
        sla: {
          AWS: 3,
          GCP: 3,
          AZURE: 3,
          ORACLE: 3,
        },
        vpn: {
          AWS: 3,
          GCP: 3,
          AZURE: 3,
          ORACLE: 3,
        },
      },
      PERU: {
        sla: {
          AWS: 3,
          GCP: 3,
          AZURE: 3,
          ORACLE: 3,
        },
        vpn: {
          AWS: 3,
          GCP: 3,
          AZURE: 3,
          ORACLE: 3,
        },
      },
      COLOMBIA: {
        sla: {
          AWS: 3,
          GCP: 3,
          AZURE: 3,
          ORACLE: 3,
        },
        vpn: {
          AWS: 3,
          GCP: 3,
          AZURE: 3,
          ORACLE: 3,
        },
      },
      '172.22.215.225': 0,
      '172.22.215.222': 0,
      '172.22.215.226': 0, //azure
      '172.22.215.223': 0, //gcp c del sur
      '172.22.215.227': 0, //gcp c del sur legacy
      '172.22.215.224': 0, //gcp santiago
      '172.22.215.228': 0, //oracle ashburn
      '172.22.215.229': 0, //oracle sao
      '172.22.215.221': 0, //oracle stgo
      '172.18.175.55': 0, //chile
      '172.18.209.6': 0, //chile 2
      '172.17.3.82': 0, //argentina
      '172.20.90.66': 0, //colombia
      '172.21.40.35': 0, //peru
      '172.20.160.162': 0, //brasil
    };

    const keyCodes: any = { AWS: 'AWS', Azure: 'AZURE', GCP: 'GCP', OCI: 'ORACLE' };
    const cpu_memory_utilization: any = { AWS: [], AZURE: [], GCP: [], ORACLE: [] };

    //Cup and Memory utilization structure
    for (const key in cloudUsage) {
      if (cloudUsage.hasOwnProperty(key)) {
        let arrayKey = key.split('_');
        let keyCloud = arrayKey[1];
        let name = arrayKey.length > 2 ? arrayKey[2] : '';
        let keyCode = keyCodes[keyCloud as keyof typeof keyCodes];
        let memory_usage;
        let cpu_utilization;
        let address;
        cloudUsage[key].forEach((service: any) => {
          address = service.address;
          if (service.description === 'Memory usage') {
            memory_usage = service.plugin_output.split(' ')[1];
          }
          if (service.description === 'CPU utilization') {
            cpu_utilization = service.plugin_output.split(' ')[2];
          }
        });

        cpu_memory_utilization[keyCode as keyof typeof cpu_memory_utilization].push({
          name: name,
          address: address,
          memory: memory_usage,
          cpu: cpu_utilization,
        });
      }
    }
    setCpuMemoryUtilization(cpu_memory_utilization);
    function getAck(cloudItem: any) {
      let acknowledged = cloudItem.acknowledged;
      const comments = cloudItem.comments_with_extra_info;
      let ack_icon = <></>;
      if (acknowledged && comments && comments.length > 0) {
        let content = '';
        comments.forEach((comment: any) => {
          content += comment[2] + '\n';
        });
        ack_icon = (
          <CustomTooltip content={content} direction={'left'} show={false}>
            <img src={ack} />
          </CustomTooltip>
        );
      } else if (acknowledged) {
        ack_icon = <img src={ack} />;
      }
      return ack_icon;
    }
    const objProblemsSla: any = {
      AR: { AWS: {}, GCP: {}, AZURE: {}, ORACLE: {} },
      CL: { AWS: {}, GCP: {}, AZURE: {}, ORACLE: {} },
      CO: { AWS: {}, GCP: {}, AZURE: {}, ORACLE: {} },
      PE: { AWS: {}, GCP: {}, AZURE: {}, ORACLE: {} },
      BR: { AWS: {}, GCP: {}, AZURE: {}, ORACLE: {} },
    };
    for (const hostname in servicesCloud) {
      const servicesByHost = servicesCloud[hostname];

      const reg = /^(GCP|AWS|OCI|AZU).*?(AR-|BR-|CL-|CO-|PE-)/;
      const services_filtered_sla = servicesByHost.filter((srvFl: any) => reg.test(srvFl.description));
      services_filtered_sla.forEach((srvFl: any) => {
        const regexToProvider = /\b(AWS|GCP|AZU|OCI)\b/;
        const providerArray = srvFl.description.match(regexToProvider);
        let provider = providerArray ? providerArray[1] : null;
        if (provider === 'AZU') {
          provider = 'AZURE';
        } else if (provider === 'OCI') {
          provider = 'ORACLE';
        }
        const regexToCountry = /(PE|CO|CL|BR|AR)-/;
        const countryArray = srvFl.description.match(regexToCountry);

        const country: any = countryArray ? countryArray[1] : null;
        let { formattedDate, formattedTime }: any = getDate(srvFl.last_state_change);
        let arrayHost = srvFl.host_name.split('_');
        let location = arrayHost.length > 2 ? arrayHost[2] : '';
        const [name1, name2] = srvFl.description.split('_');
        if (srvFl.state !== 0) {
          if (objProblemsSla[country][provider][name1]) {
            cloudObjectStatus[country_prefixes[country.toLowerCase()]]['sla'][provider] = 2;
          } else {
            objProblemsSla[country][provider][name1] = true;
          }
        } else {
          if (cloudObjectStatus[country_prefixes[country.toLowerCase()]]['sla'][provider] !== 2) {
            cloudObjectStatus[country_prefixes[country.toLowerCase()]]['sla'][provider] = 0;
          }
        }
        if (srvFl.state !== 0) {
          if (objProblemsSla[country][provider][name2]) {
            cloudObjectStatus[country_prefixes[country.toLowerCase()]]['sla'][provider] = 2;
          } else {
            objProblemsSla[country][provider][name2] = true;
          }
        } else {
          if (cloudObjectStatus[country_prefixes[country.toLowerCase()]]['sla'][provider] !== 2) {
            cloudObjectStatus[country_prefixes[country.toLowerCase()]]['sla'][provider] = 0;
          }
        }

        if (srvFl.state !== 0) {
          alertRows.push({
            type: <div className="icon-status" style={{ backgroundColor: getStatusColorByNumber(srvFl.state) }}></div>,
            type2: 'SLA',
            cloud: provider,
            host: (
              <a
                href={`${buildHostUrl(srvFl.host_name, srvFl.site, siteUrl[srvFl.site])}`}
                target="_blank"
                rel="noreferrer"
              >
                {srvFl.host_name}
              </a>
            ),
            acknowledged: getAck(srvFl),
            ip: srvFl.address,
            service: srvFl.description,
            hour: `${formattedDate} ${formattedTime}`,
            time: srvFl.last_state_change,
            country: location,
            location: country_prefixes[country.toLowerCase()],
          });
        }
      });

      const regex = /^VPN\s.+\s(AR-|BR-|CL-|CO-|PE-)/;
      const services_filtered_vpn = servicesByHost.filter((srv: any) => regex.test(srv.description));

      services_filtered_vpn.forEach((srvFl: any) => {
        const regexToProvider = /\b(AWS|GCP|AZURE|OCI)\b/;
        const providerArray = srvFl.description.match(regexToProvider);
        let provider = providerArray ? providerArray[1] : null;
        if (provider === 'OCI') {
          provider = 'ORACLE';
        }
        const regexToCountry = /\b(PE|CO|CL|BR|AR)-/;
        const countryArray = srvFl.description.match(regexToCountry);
        const country: any = countryArray ? countryArray[1] : null;

        let { formattedDate, formattedTime }: any = getDate(srvFl.last_state_change);
        let arrayHost = srvFl.host_name.split('_');
        let location = arrayHost.length > 2 ? arrayHost[2] : '';
        if (srvFl.state !== 0) {
          alertRows.push({
            type: <div className="icon-status" style={{ backgroundColor: getStatusColorByNumber(srvFl.state) }}></div>,
            type2: 'VPN',
            cloud: provider,
            host: (
              <a
                href={`${buildHostUrl(srvFl.host_name, srvFl.site, siteUrl[srvFl.site])}`}
                target="_blank"
                rel="noreferrer"
              >
                {srvFl.host_name}
              </a>
            ),
            acknowledged: getAck(srvFl),
            ip: srvFl.address,
            service: srvFl.description,
            hour: `${formattedDate} ${formattedTime}`,
            time: srvFl.last_state_change,
            country: location,
            location: country_prefixes[country.toLowerCase()],
          });
          cloudObjectStatus[country_prefixes[country.toLowerCase()]]['vpn'][provider] = 2;
        } else {
          if (cloudObjectStatus[country_prefixes[country.toLowerCase()]]['vpn'][provider] !== 2) {
            cloudObjectStatus[country_prefixes[country.toLowerCase()]]['vpn'][provider] = 0;
          }
        }
      });
    }

    let htServiceCloudArray: any = [];
    for (const ht in servicesCloud) {
      htServiceCloudArray = [...servicesCloud[ht], ...htServiceCloudArray];
    }
    htServiceCloudArray.sort((a: any, b: any) => b.state - a.state);

    setAlertRowsToGraphic(alertRows);

    setStatusCloud(cloudObjectStatus);
    // eslint-disable-next-line
  }, [servicesCloud, hostsTagCloud, cloudUsage]);

  function capitalize(string: String) {
    if (!string) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  function selectAlerts(type: any, country: any, cloud: any) {
    if (cloud === 'ORACLE') {
      cloud = capitalize(cloud);
    }
    setActype([type]);
    setAcCloud([cloud]);
    setAcCountry([country]);
    setSelectedDashboard('cloud');
  }

  function clearCloudFilters() {
    setAcCloud(['AWS', 'AZURE', 'GCP', 'Oracle']);
    setAcCountry(['CHILE', 'ARGENTINA', 'BRASIL', 'COLOMBIA', 'PERU', '']);
    setActype(['SLA', 'VPN']);
  }

  function getCloud() {
    const alertReal = alertRowsToGraphic.sort(compare);
    const auxOptionsType2: any[] = [];
    const auxOptionsCloud: any[] = [];
    const auxOptionsCountry: any[] = [];
    alertReal.forEach((element) => {
      auxOptionsType2.push(element.type2);
      auxOptionsCloud.push(element.cloud);
      auxOptionsCountry.push(element.country);
    });
    const optionsType2 = [...new Set(auxOptionsType2)];
    const optionsCloud = [...new Set(auxOptionsCloud)];
    const optionsCountry = [...new Set(auxOptionsCountry)];

    alertReal.sort((a: any, b: any) => new Date(b.hour).getTime() - new Date(a.hour).getTime());
    const newDataAlertReal: any[] = [];
    alertReal.forEach((element: any) => {
      if (!actype.includes(element.type2)) {
        return;
      }
      if (!acCloud.includes(element.cloud)) {
        return;
      }
      if (![...acCountry, ''].includes(element.location)) {
        return;
      }
      newDataAlertReal.push(element);
    });

    const data = {
      columns: [
        {
          label: 'STAT',
          field: 'type',
          sort: 'asc',
        },
        {
          label: 'TIPO',
          field: 'type2',
          sort: 'asc',
          filter: 'select',
          arrayOptions: optionsType2,
          setValue: setActype,
          currentValue: actype,
          onClear: () => setActype(optionsType2),
        },
        {
          label: 'HORA',
          field: 'hour',
          sort: 'asc',
          filter: 'text',
        },
        {
          label: 'HOST',
          field: 'host',
          sort: 'asc',
          filter: 'text',
        },
        {
          label: 'IP',
          field: 'ip',
          sort: 'asc',
        },
        {
          label: 'SERVICIO',
          field: 'service',
          sort: 'asc',
          filter: 'text',
        },
        {
          label: 'Cloud',
          field: 'cloud',
          sort: 'asc',
          filter: 'select',
          arrayOptions: optionsCloud,
          setValue: setAcCloud,
          currentValue: acCloud,
          onClear: () => setAcCloud(optionsCloud),
        },
        {
          label: 'PAIS',
          field: 'location',
          sort: 'asc',
          filter: 'select',
          arrayOptions: locations,
          setValue: setAcCountry,
          currentValue: acCountry,
          onClear: () => setAcCountry(locations),
        },
        {
          label: 'Locación',
          field: 'country',
          sort: 'asc',
          filter: 'select',
          arrayOptions: optionsCountry,
        },
        {
          label: 'Atn',
          field: 'acknowledged',
          sort: 'asc',
        },
      ],
      rows: newDataAlertReal,
    };

    const NetworkData = [
      { status: 3, image: AWS, ip: '172.22.215.225', location: 'Virginia', position: [15, 500] },
      { status: 3, image: AWS, ip: '172.22.215.222', location: 'Oregon', position: [145, 500] },
      { status: 3, image: AZURE, ip: '172.22.215.226', location: 'East US', position: [275, 500] },
      { status: 3, image: GCP, ip: '172.22.215.223', location: 'C del Sur', position: [405, 500] },
      { status: 3, image: GCP, ip: '172.22.215.227', location: 'C del Sur', position: [535, 500] },
      { status: 3, image: GCP, ip: '172.22.215.224', location: 'Santiago', position: [665, 500] },
      { status: 3, image: ORACLE2, ip: '172.22.215.228', location: 'Ashburn', position: [795, 500] },
      { status: 3, image: ORACLE2, ip: '172.22.215.229', location: 'SAO', position: [925, 500] },
      { status: 3, image: ORACLE2, ip: '172.22.215.221', location: 'Santiago', position: [1055, 500] },
    ];

    const ServerData = [
      { status: 3, ip: '172.18.175.55', location: 'Chile', position: [100, 900] },
      { status: 3, ip: '172.18.209.6', location: 'Chile Backup', position: [280, 900] },
      { status: 3, ip: '172.17.3.82', location: 'Argentina', position: [460, 900] },
      { status: 3, ip: '172.20.90.66', location: 'Colombia', position: [640, 900] },
      { status: 3, ip: '172.21.40.35', location: 'Perú', position: [820, 900] },
      { status: 3, ip: '172.20.160.162', location: 'Brasil', position: [1000, 900] },
    ];

    return (
      <>
        <div style={{ width: 'calc(100% - 700px' }}>
          <div className="info-sdwan" id="info-sdwan">
            <div className="title">
              <div className="label-title-info">Cloud SDWAN</div>
              <div
                className="action-title-info"
                style={{ cursor: 'pointer' }}
                onClick={() => setSelectedDashboard('main')}
              >
                &larr; Volver
              </div>
            </div>
            <div className="label-vecindades">ESTADO GENERAL</div>
            <div style={{ width: '100%', height: '100%', display: 'flex', position: 'relative' }}>
              <div className="content-sdwan">
                <SdwanCard
                  title={'AWS'}
                  keyCloud={'AWS'}
                  image={AWS}
                  statusCloud={statusCloud}
                  cloudUsage={cpuMemoryUtilization}
                  display={'horizontal'}
                  selectAlerts={selectAlerts}
                />
                <SdwanCard
                  title={'Google cloud'}
                  keyCloud={'GCP'}
                  image={GCP}
                  statusCloud={statusCloud}
                  cloudUsage={cpuMemoryUtilization}
                  display={'horizontal'}
                  selectAlerts={selectAlerts}
                />
                <SdwanCard
                  title={'Azure'}
                  keyCloud={'AZURE'}
                  image={AZURE}
                  statusCloud={statusCloud}
                  cloudUsage={cpuMemoryUtilization}
                  display={'horizontal'}
                  selectAlerts={selectAlerts}
                />
                <SdwanCard
                  title={'Oracle'}
                  keyCloud={'ORACLE'}
                  image={ORACLE}
                  statusCloud={statusCloud}
                  cloudUsage={cpuMemoryUtilization}
                  display={'horizontal'}
                  selectAlerts={selectAlerts}
                />
              </div>
              <CloudNetwork CloudData={NetworkData} ServerData={ServerData} statusCloud={statusCloud}></CloudNetwork>
            </div>
          </div>
        </div>
        <div className="active-alerts generalStatus" id="active-alerts-cloud">
          <div className="title-active-alerts">
            <div>ALERTAS ACTIVAS</div>
          </div>
          <div className="body-active-alerts data-center-alerts">
            <TableActiveAlerts2
              data={data}
              filters={filtersCloud}
              showClearFilter={true}
              onClearAll={clearCloudFilters}
            />
          </div>
        </div>
      </>
    );
  }

  function getGeneralStatus(enlaces: any) {
    const alertRows: any = [];
    const deviceTypeNames: any = {
      switch: 'Switch',
      router: 'Router',
      firewall: 'Firewall',
      wireless: 'Wireless',
      telefonía: 'Telefonía',
      enlace: 'Enlace',
      others: 'Otros',
    };
    allProblems.forEach((item: any) => {
      let store = '';
      if (item.hasOwnProperty('store_code')) {
        store = item['store_code'];
      }
      const comments = item.comments_with_extra_info;
      let acknowledged = item.acknowledged;
      let ack_icon = <></>;
      if (acknowledged && comments && comments.length > 0) {
        const content: any = [];
        comments.forEach((comment: any) => {
          content.push(<p>{comment[2]}</p>);
        });
        ack_icon = (
          <CustomTooltip content={content} direction={'left'} show={false}>
            <img src={ack} />
          </CustomTooltip>
        );
      } else if (acknowledged) {
        ack_icon = <img src={ack} />;
      }
      let location = '';
      let arrayStore = store.split('_');
      location = arrayStore.length > 2 ? arrayStore[2] : '';
      let hour;
      let host = item.host ? item.host : item.host_name;
      let site = item.site;
      let device_type = '';
      if (deviceTypeNames.hasOwnProperty(item.deviceType)) {
        device_type = deviceTypeNames[item.deviceType];
      }
      let ip = item.ip ? item.ip : item.address;
      let service = item.service_description ? item.service_description : item.description;
      let color = item.service_state ? getStatusColorByType(item.service_state) : getStatusColorByNumber(item.state);
      if (item.svc_state_age) {
        hour = item.svc_state_age;
      } else {
        const currentDate = new Date(item.last_state_change * 1000);
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;
        let day = currentDate.getDate();
        let hours = currentDate.getHours();
        let minutes = currentDate.getMinutes();
        let seconds = currentDate.getSeconds();
        let formattedDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
        let formattedTime =
          (hours < 10 ? '0' : '') +
          hours +
          ':' +
          (minutes < 10 ? '0' : '') +
          minutes +
          ':' +
          (seconds < 10 ? '0' : '') +
          seconds;
        hour = `${formattedDate} ${formattedTime}`;
      }
      alertRows.sort((a: any, b: any) => new Date(b.hour).getTime() - new Date(a.hour).getTime());
      alertRows.push({
        type: <div className="icon-status" style={{ backgroundColor: color }}></div>,
        host: (
          <a href={`${buildHostUrl(host, site, siteUrl[site])}`} target="_blank" rel="noreferrer">
            {host}
          </a>
        ),
        ip: ip,
        acknowledged: ack_icon,
        device_type: device_type,
        location: location,
        store: store,
        service: service,
        hour: hour,
      });
    });
    const data = {
      columns: [
        {
          label: 'TIPO',
          field: 'type',
          sort: 'asc',
          width: 80,
        },

        {
          label: 'HORA',
          field: 'hour',
          sort: 'asc',
          width: 100,
          filter: 'text',
        },
        {
          label: 'HOST',
          field: 'host',
          sort: 'asc',
          width: 150,
          filter: 'text',
        },
        {
          label: 'IP',
          field: 'ip',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'DISPOSITIVO',
          field: 'device_type',
          sort: 'asc',
          width: 50,
          filter: 'select',
          arrayOptions: devices,
        },
        {
          label: 'SERVICIO',
          field: 'service',
          sort: 'asc',
          width: 150,
          filter: 'text',
        },
        {
          label: 'Locación',
          field: 'location',
          sort: 'asc',
          width: 120,
          filter: 'select',
          arrayOptions: locations,
        },
        {
          label: 'Ack',
          field: 'acknowledged',
          sort: 'asc',
          width: 30,
        },
        {
          label: 'Tienda',
          field: 'store',
          sort: 'asc',
          width: 150,
          filter: 'text',
        },
      ],
      rows: alertRows,
    };
    return (
      <>
        <div className="info" style={{ width: '50%' }}>
          <div className="title">
            <div className="label-title-info">Datacenter</div>
            <div
              className="action-title-info"
              style={{ cursor: 'pointer' }}
              onClick={() => setSelectedDashboard('main')}
            >
              &larr; Volver
            </div>
          </div>
          <div className="label-vecindades">ESTADO GENERAL</div>
          <div className="info-content">{getInfo()}</div>
        </div>
        <div className="active-alerts generalStatus">
          <div className="title-active-alerts">ALERTAS ACTIVAS</div>
          <div className="body-active-alerts">
            <TableActiveAlerts2 data={data} filters={filters} showClearFilter={false} />
          </div>
        </div>
      </>
    );
  }
  function getDashboard(type: any, enlaces: any) {
    if (type === 'main') {
      return getMain(enlaces);
    } else if (type === 'enlaces') {
      return getEnlaces(enlaces);
    } else if (type === 'general-status') {
      return getGeneralStatus(enlaces);
    } else if (type === 'cloud') {
      return getCloud();
    } else {
      return getMain(enlaces);
    }
  }
  return <section className="datacenter">{getDashboard(selectedDashboard, dtEnlaces)}</section>;
};

export default DatacenterPanel;
