import React from 'react';

import './DatacenterTableItem.css';

function DatacenterTableItem({ name, status, ip, saturation, crc, prov, type, hostname, codigoServicio }) {
  const colorStatus = {
    0: '#10D884',
    1: '#E9B500',
    2: '#ED4C5C',
  };
  const labelStatusDict = {
    troncal: {
      0: 'Up',
      2: 'Down',
    },
    internet: {
      0: 'OK',
      1: 'WARN',
      2: 'CRIT',
    },
  };
  return (
    <section className="datacenter-table-item-cmpt">
      <div className="name">{name}</div>
      <div className="prov">{prov}</div>
      <div className="status" style={{ backgroundColor: colorStatus[status] }}>
        {labelStatusDict[type][status]}
      </div>
      <div className="ip">{ip}</div>
      <div className="saturation">{saturation}</div>
      <div className="crc">{crc.toFixed(2)}</div>
      <span className="tooltiptext">{`Hostname: ${hostname}\nCS: ${codigoServicio}`}</span>
    </section>
  );
}

export default DatacenterTableItem;
