import { region_dictionary, dict_region_name } from '../../../constants/country_map_info.ts';
import { parseToJSONInterface } from './common';

function getServicesHostDetail(services, hosts) {
  const hostsServices = [];
  services.forEach((service) => {
    const key = service.host_name;
    if (hosts[key]) {
      service.address = hosts[key].address;
      service.site = hosts[key].tags.site;
      service.country = hosts[key].tags.SITE;
      service.tienda = hosts[key].tags.TIENDA;
      service.codigo_de_tienda = hosts[key].tags.CODIGO_DE_TIENDA;
      service.deviceType = hosts[key].labels['cmk/device_type'];
      hostsServices.push(service);
    }
  });
  return hostsServices;
}

export function getLinksByProviders(hosts, link_services, enlacesProblems) {
  let links_provider_detail_by_country = { CHILE: {}, ARGENTINA: {}, BRASIL: {}, PERU: {}, COLOMBIA: {} };
  const links_provider_detail = getServicesHostDetail(link_services, hosts);
  const dictServiceCode = {};
  links_provider_detail.forEach((link_service) => {
    if (
      link_service.description.includes('TLCO:') &&
      link_service.description.includes('CS:') &&
      link_service.deviceType === 'firewall' &&
      (link_service.description.includes('BGP') || link_service.description.includes('OSPF'))
    ) {
      if (!links_provider_detail_by_country[link_service.country]) {
        links_provider_detail_by_country[link_service.country] = {};
      }
      const match = link_service.description.match(/TLCO:([^_]+)/);
      const provider = match ? match[1] : null;
      if (provider) {
        if (!links_provider_detail_by_country[link_service.country][provider.toUpperCase()]) {
          links_provider_detail_by_country[link_service.country][provider.toUpperCase()] = [];
        }
        const linkInfo = parseToJSONInterface(link_service.description);
        if (!dictServiceCode[linkInfo['CS']]) {
          dictServiceCode[linkInfo['CS']] = { ...linkInfo, ...link_service, provider: provider.toUpperCase() };
        } else {
          if (link_service.state === 0) {
            dictServiceCode[linkInfo['CS']] = { ...linkInfo, ...link_service, provider: provider.toUpperCase() };
          }
        }
      }
    }
  });
  const sniEnlacesProblemsDict = {};
  enlacesProblems.forEach((enlPrb) => {
    if (!sniEnlacesProblemsDict[enlPrb.service_code]) {
      sniEnlacesProblemsDict[enlPrb.service_code] = enlPrb;
    }
  });
  for (const serviceCode in dictServiceCode) {
    let state = 0;
    let last_state_change = '';
    if (sniEnlacesProblemsDict[serviceCode]) {
      state = 2;
      last_state_change = sniEnlacesProblemsDict[serviceCode]['last_state_change'];
    }
    const infoServiceCode = dictServiceCode[serviceCode];
    links_provider_detail_by_country[infoServiceCode.country][infoServiceCode.provider].push({
      ...infoServiceCode,
      description: `CS:${serviceCode}`,
      state,
      last_state_change,
    });
  }
  return links_provider_detail_by_country;
}

export function getSlaByProviders(hosts, link_services) {
  let links_provider_detail_by_country = { CHILE: {}, ARGENTINA: {}, BRASIL: {}, PERU: {}, COLOMBIA: {} };
  const links_provider_detail = getServicesHostDetail(link_services, hosts);
  links_provider_detail.forEach((link_service) => {
    if (link_service.tienda === 'DATACENTER' && link_service.deviceType === 'firewall') {
      if (!links_provider_detail_by_country[link_service.country]) {
        links_provider_detail_by_country[link_service.country] = {};
      }
      const match = link_service.description.match(/TLCO:([^_]+)/);
      const provider = match ? match[1] : null;
      if (provider) {
        if (!links_provider_detail_by_country[link_service.country][provider.toUpperCase()]) {
          links_provider_detail_by_country[link_service.country][provider.toUpperCase()] = [];
        }
        links_provider_detail_by_country[link_service.country][provider.toUpperCase()].push(link_service);
      }
    }
  });
  return links_provider_detail_by_country;
}

export function getInterfacesByProviders(hosts, link_services) {
  let links_provider_detail_by_country = { CHILE: {}, ARGENTINA: {}, BRASIL: {}, PERU: {}, COLOMBIA: {} };
  const links_provider_detail = getServicesHostDetail(link_services, hosts);
  links_provider_detail.forEach((link_service) => {
    if (!links_provider_detail_by_country[link_service.country]) {
      links_provider_detail_by_country[link_service.country] = {};
    }
    const match = link_service.description.match(/TLCO:([^_]+)/);
    const provider = match ? match[1] : null;
    if (provider) {
      if (!links_provider_detail_by_country[link_service.country][provider.toUpperCase()]) {
        links_provider_detail_by_country[link_service.country][provider.toUpperCase()] = [];
      }
      links_provider_detail_by_country[link_service.country][provider.toUpperCase()].push(link_service);
    }
  });
  return links_provider_detail_by_country;
}

export function getRegionStatusByStores(stores) {
  const regions = {};
  stores.forEach((store) => {
    if (store.region) {
      if (!regions[store.region]) {
        regions[store.region] = {
          //@ts-ignore
          region: region_dictionary[store.region] ? region_dictionary[store.region] : dict_region_name[store.region],
          status: store.state,
          storeTotal: 0,
          storeProblems: 0,
          problems: store.serviceProblems,
          percentage: {
            problem: 10,
            all: 100,
          },
        };
      }
      regions[store.region]['storeTotal'] += 1;
      if (store.state !== 0) {
        regions[store.region]['storeProblems'] += 1;
      }

      if (store.state > regions[store.region].status) {
        regions[store.region].status = store.state;
      }
      regions[store.region].problems += store.serviceProblems;
    }
  });
  return Object.values(regions);
}

function hexToRgba(hex, opacity) {
  hex = hex.replace(/^#/, '');
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getRegionStatusByStoresForGraphic(stores, enlacesProblems, storesProblems, linkServices, hosts) {
  const arrayEnlace = [];
  linkServices.forEach((sl) => {
    if (
      sl.description.includes('TLCO:') &&
      sl.description.includes('CS:') &&
      (sl.description.includes('OSPF') || sl.description.includes('BGP'))
    ) {
      arrayEnlace.push({ ...sl });
    }
  });
  const links_provider_detail = getServicesHostDetail(arrayEnlace, hosts);

  const dictServiceCode = {};
  links_provider_detail.forEach((link_service) => {
    if (link_service.deviceType === 'firewall') {
      const match = link_service.description.match(/TLCO:([^_]+)/);
      const provider = match ? match[1] : null;
      if (provider) {
        const linkInfo = parseToJSONInterface(link_service.description);
        if (!dictServiceCode[linkInfo['CS']]) {
          dictServiceCode[linkInfo['CS']] = { ...linkInfo, ...link_service, provider: provider.toUpperCase() };
        } else {
          if (link_service.state === 0) {
            dictServiceCode[linkInfo['CS']] = { ...linkInfo, ...link_service, provider: provider.toUpperCase() };
          }
        }
      }
    }
  });
  const arrayAuxServiceCode = [];
  for (const key in dictServiceCode) {
    const element = dictServiceCode[key];
    arrayAuxServiceCode.push({
      brand: element.tienda,
      code: element.codigo_de_tienda,
      provider: element.provider,
      host: element.host_name,
    });
  }

  const regions = {};
  stores.forEach((store) => {
    const arrayEnlPrb = [];
    let auxTotalEnlaces = arrayAuxServiceCode.filter(
      (enlace) => enlace.brand === store.name && enlace.code === store.code
    );
    enlacesProblems.forEach((enlPrb) => {
      if (enlPrb.brand === store.name && enlPrb.code === store.code) {
        store.state = 1;
        if (!arrayEnlPrb.some((eachEnl) => eachEnl.service_code === enlPrb.service_code)) {
          arrayEnlPrb.push({
            host_name: enlPrb.host,
            service: enlPrb.service,
            serviceStatus: 2,
            last_state_change: enlPrb.last_state_change,
            ip: enlPrb.ip,
            state: 2,
            description: `CS:${enlPrb.service_code}`,
            service_code: enlPrb.service_code,
          });
        }
      }
    });
    const storeObjectProblem = storesProblems.find(
      (strPrb) => strPrb.brand === store.name && strPrb.code === store.code
    );
    if (storeObjectProblem) {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      store.state = 2;
      arrayEnlPrb.push({
        host_name: storeObjectProblem.host,
        description: 'Ping',
        state: 2,
        plugin_output: 'No ping',
        ip: storeObjectProblem.ip,
        typeProblem: 'store',
        storeColor: `${hexToRgba(`#${randomColor}`, 0.3)}`,
        last_state_change: storeObjectProblem.last_state_change,
      });
    }
    if (store.region) {
      let regionName = store.region;
      if (regionName === 'SCL') {
        regionName = 'SGO';
      } else if (regionName === 'OSO') {
        regionName = 'LAG';
      } else if (regionName === 'TEM') {
        regionName = 'ARA';
      } else if (regionName === 'LLR' || regionName === 'LRS' || regionName === 'VAD') {
        regionName = 'RIO';
      }
      if (!regions[regionName]) {
        regions[regionName] = {
          //@ts-ignore
          region: region_dictionary[regionName] ? region_dictionary[regionName] : dict_region_name[regionName],
          status: store.state,
          storeTotal: 0,
          storeProblems: 0,
          deviceTotal: 0,
          enlacesTotal: 0,
          problems: store.serviceProblems,
          serviceArrayProblems: {},
        };
      }
      regions[regionName]['storeTotal'] += 1;
      regions[regionName]['enlacesTotal'] += auxTotalEnlaces.length;
      if (store.state !== 0) {
        regions[regionName]['storeProblems'] += 1;
        regions[regionName]['serviceArrayProblems'][`${store.name}-${store.code}`] = arrayEnlPrb;
      }

      if (store.state > regions[regionName].status) {
        regions[regionName].status = store.state;
      }
      regions[regionName].problems += store.serviceProblems;
    }
  });
  return Object.values(regions);
}
