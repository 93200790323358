function inBOCDADM(host) {
  if (
    (host.labels['cmk/tienda'] && host.labels['cmk/tienda'] === 'centro_distribucion') ||
    (host.labels['cmk/tienda'] && host.labels['cmk/tienda'] === 'administracion') ||
    (host.labels['cmk/tienda'] && host.labels['cmk/tienda'] === 'bodega') ||
    (host.labels['cmk/tienda'] && host.labels['cmk/tienda'] === 'administracion_central')
  ) {
    return true;
  }
  return false;
}

function extractMetrics(text) {
  const latencyMatch = text.match(/Latency:\s*([\d.]+)/);
  const packetLossMatch = text.match(/PacketLoss:\s*([\d.]+)/);

  const latency = latencyMatch ? parseFloat(latencyMatch[1]) : 0;
  const packetLoss = packetLossMatch ? parseFloat(packetLossMatch[1]) : 0;

  return { latency, packetLoss };
}

function parseToJSONInterface(text) {
  const cleanText = text.replace(/[\*\*]/g, '');
  const parts = cleanText.split('_');
  const result = { service: text };
  parts.forEach((part) => {
    const [key, ...valueParts] = part.split(':');
    result[key] = valueParts.join(':');
  });
  return result;
}

export function getStoresForGraphic(hosts) {
  const stores = {};
  hosts.forEach((host) => {
    const store_key = host.tags.CODIGO_DE_TIENDA;
    if (host.tags.TIENDA === 'NO_ASIGNADA' && host.tags.CODIGO_DE_TIENDA === 'CAAR') {
      return;
    }
    if (store_key) {
      if (!stores[store_key]) {
        stores[store_key] = {
          code: host.tags.CODIGO_DE_TIENDA,
          name: host.tags.TIENDA,
          region: host.tags.region,
          bocdadm: inBOCDADM(host),
          saturation: 0,
          allEnlaces: 0,

          links: {
            items: [],
          },
        };
      }
      stores[store_key].allEnlaces += host.allEnlaces;
      if (stores[store_key].region === '') {
        stores[store_key].region = host.tags.region;
      }
    }
  });

  const storeList = Object.values(stores);
  storeList.forEach((store) => {
    store.state = 0;
  });
  return storeList;
}

export { inBOCDADM, parseToJSONInterface, extractMetrics };
