function percentageOfSpeed(infoSpeed1: string, infoSpeed2: string) {
  const conversion: { [key: string]: number } = {
    'Bit/s': 1,
    'kBit/s': 1000,
    'MBit/s': 1000000,
    'GBit/s': 1000000000,

    'B/s': 8,
    'kB/s': 8000,
    'MB/s': 8000000,
    'GB/s': 8000000000,
  };

  const infoSpeed1_tmp = infoSpeed1.split(' ');
  const infoSpeed2_tmp = infoSpeed2.split(' ');

  const speed1 = parseFloat(infoSpeed1_tmp[0]);
  const unit1 = infoSpeed1_tmp[1];
  const convertedSpeed1 = speed1 * conversion[unit1];

  const speed2 = parseFloat(infoSpeed2_tmp[0]);
  const unit2 = infoSpeed2_tmp[1];
  const convertedSpeed2 = speed2 * conversion[unit2];
  const percentage = (convertedSpeed2 / convertedSpeed1) * 100;

  return percentage.toFixed(2);
}
function processSpeedMetric(data: string, speed: string) {
  const regex = [
    /: (\d+ \w+\/s) \((\d+)%\/([\d.]+ \w+\/s)\)/,
    /: (.*) \(<(\d+.+\d)\%+\/(.+)\)/,
    /: (.*) \((\d+.+\d)\%+\/(.+)\)/,
    /: (.*) \((\d+.?\d*)\%\)/,
    /: (.*) \(\&lt;(\d+.?\d*)\%\)/,
    /: (.*) \(<(\d+.?\d*)\%\)/,
  ];
  let regex_index = 0;
  let matches = undefined;
  while (regex_index < regex.length) {
    matches = data.match(regex[regex_index]);
    if (matches) {
      break;
    }
    regex_index += 1;
  }
  let value, percentage;

  if (matches) {
    if (regex_index === 0) {
      value = matches[1];
      percentage = parseFloat(matches[2]);
    } else if (regex_index === 1 || regex_index === 2) {
      value = matches[1];
      percentage = percentageOfSpeed(speed, matches[1]);
    } else {
      value = matches[1];
      percentage = parseFloat(matches[2]);
    }
  }
  return [value, percentage];
}

export function getSaturationFromServiceOutput(service_output: string) {
  if (service_output == null) {
    return null;
  }
  const service_array = service_output.split(', ');

  const data: any = {
    speed: '',
    inPercentage: 0,
    outPercentage: 0,
    in: '',
    inState: 0,
    out: '',
    outState: 0,
    errorsIn: 0,
    errorsInState: 0,
    errorsOut: 0,
    errorsOutState: 0,
    priority: 0,
  };
  service_array.forEach((metric) => {
    if (metric.startsWith('Speed: ')) {
      data.speed = metric.substring(7);
    } else if (metric.startsWith('In: ')) {
      //@ts-ignore
      const tmp: [string, number] = processSpeedMetric(metric.substring(2), data.speed);
      data.in = transformToBits(tmp[0]);
      data.inState = metric.includes('!!') ? 2 : metric.includes('!') ? 1 : 0;
      //@ts-ignore
      data.inPercentage = parseFloat(tmp[1]);
    } else if (metric.startsWith('Out: ')) {
      //@ts-ignore
      const tmp: [string, number] = processSpeedMetric(metric.substring(3), data.speed);
      data.out = transformToBits(tmp[0]);
      data.outState = metric.includes('!!') ? 2 : metric.includes('!') ? 1 : 0;
      //@ts-ignore
      data.outPercentage = parseFloat(tmp[1]);
    } else if (metric.startsWith('Errors in: ')) {
      const index = metric.indexOf('%');
      data.errorsIn = parseFloat(metric.substring(11, index));
      data.errorsInState = metric.includes('!!') ? 2 : metric.includes('!') ? 1 : 0;
    } else if (metric.startsWith('Errors out: ')) {
      const index = metric.indexOf('%');
      data.errorsOut = parseFloat(metric.substring(12, index));
      data.errorsOutState = metric.includes('!!') ? 2 : metric.includes('!') ? 1 : 0;
    }
  });
  data.priority = Math.max(...[data.inPercentage, data.outPercentage, data.errorsIn, data.errorsOut]);
  return data;
}

function transformToBits(infoSpeed1: string) {
  const conversion: { [key: string]: number } = {
    'Bit/s': 1,
    'kBit/s': 1000,
    'MBit/s': 1000000,
    'GBit/s': 1000000000,

    'B/s': 8,
    'kB/s': 8000,
    'MB/s': 8000000,
    'GB/s': 8000000000,
  };

  const data = infoSpeed1.split(' ');

  const speed1 = parseFloat(data[0]);
  const unit1 = data[1];
  const convertedSpeed1 = speed1 * conversion[unit1];

  return convertBits(convertedSpeed1);
}
function convertBits(bits: number) {
  const units = ['Bit/s', 'kBit/s', 'MBit/s', 'GBit/s', 'TBit/s', 'PBit/s', 'EBit/s', 'ZBit/s', 'YBit/s'];
  let i = 0;

  while (bits >= 1000 && i < units.length - 1) {
    bits /= 1000;
    i++;
  }

  return `${bits.toFixed(2)} ${units[i]}`;
}

export function analizeLink(link: any) {
  const regex = /([\w]+)[\s_]CS:(\w+\d+)[\s_-]/;

  const matches = link.description.match(regex);
  if (matches) {
    link.provider = matches[1];
    link.cs = matches[2];
  }
  const data = getSaturationFromServiceOutput(link.plugin_output);
  let priority = data?.priority;
  if (link.state === 2) {
    priority = 100;
  }
  link.priority = priority;
  return data;
}

function removeSymbols(text: string) {
  return text.replace(/[<>]/g, '');
}

function getErrorsInPercentage(output: string) {
  const match = output.match(/Errors in:\s*([\d.]+)%/);
  return match ? parseFloat(match[1]) : 0;
}

function getErrorsOutPercentage(output: string) {
  const match = output.match(/Errors out:\s*([\d.]+)%/);
  return match ? parseFloat(match[1]) : 0;
}

export function getSaturationForDatacenter(service_output: string) {
  if (!service_output) {
    return null;
  }
  const data = {
    speed: '',
    inPercentage: 0,
    outPercentage: 0,
    in: '',
    out: '',
    errors: 0,
    inState: 0,
    outState: 0,
    priority: 0,
  };
  const auxServiceOutput = removeSymbols(service_output);

  const inIndex = auxServiceOutput.indexOf('In:');
  if (inIndex === -1) {
    return null;
  }

  const relevantPart = auxServiceOutput.substring(inIndex);

  let [inPart, outPart] = relevantPart.split(', Out:').map((part) => part.trim());

  if (!inPart) {
    inPart = 'In: 0 Bit/s (0.00%)';
  }
  if (!outPart) {
    outPart = ' 0 Bit/s (0.00%)';
  }

  data.inState = inPart ? (inPart.includes('!!') ? 2 : inPart.includes('!') ? 1 : 0) : 0;
  data.outState = outPart ? (outPart.includes('!!') ? 2 : outPart.includes('!') ? 1 : 0) : 0;

  const inParts = inPart.split(' ');
  if (inParts.length > 5) {
    inParts.splice(3, 5);
  }
  const inValueNumber = inParts[1];
  const inUnit = inParts[2];
  const inPercentAndTotal = inParts.slice(3).join(' ');
  const [inPercentage, inTotal] = inPercentAndTotal
    .replace(/[()]/g, '')
    .split('%/')
    .map((item) => item.trim());

  const outParts = outPart.split(' ');
  if (outParts.length > 4) {
    outParts.splice(2, 5);
  }
  const outValueNumber = outParts[0];
  const outUnit = outParts[1];
  const outPercentAndTotal = outParts.slice(2).join(' ');
  const [outPercentage] = outPercentAndTotal
    .replace(/[()]/g, '')
    .split('%/')
    .map((item) => item.trim());

  data.speed = inTotal;
  const [numberInPercentage] = inPercentage.split('%');
  data.inPercentage = parseFloat(numberInPercentage);
  data.in = `${inValueNumber} ${inUnit}`;
  data.out = `${outValueNumber} ${outUnit}`;
  const [numberOutPercentage] = outPercentage.split('%');
  data.outPercentage = parseFloat(numberOutPercentage);
  data.errors =
    getErrorsInPercentage(service_output) > getErrorsOutPercentage(service_output)
      ? getErrorsInPercentage(service_output)
      : getErrorsOutPercentage(service_output);
  data.priority = Math.max(...[data.inPercentage, data.outPercentage]);
  return data;
}
