import React from 'react';
import CopySvg from '../../img/copy.svg';
import './EnlaceTooltip.css';

type Props = {
  problems: any;
  code: any;
  speed: any;
  usein: any,
  useout: any,
};

const EnlaceTooltip: React.FC<Props> = ({ problems = 0, code, speed, usein, useout }) => {
  function copyToClipboard() {
    // console.log('text', text)
    let textField = document.createElement('textarea');
    textField.innerText = code;
    document.body.appendChild(textField);
    textField.select();
    //@ts-ignore
    document.execCommand('copy');
    textField.remove();
  }

  return (
    <section className="enlace-tooltip-cmpt">
      <div className="copy" onClick={copyToClipboard}>
        <img src={CopySvg} />
      </div>
      {/* <div className="item">{`Problemas detectados: ${problems}`}</div> */}
      {/* <div className="item">{`COD: ${code}`}</div> */}
      <div className="item">{`Velocidad: ${speed}`}</div>
      <div className="item">{`Trafico entrada: ${usein}`}</div>
      <div className="item">{`Trafico salida: ${useout}`}</div>
    </section>
  );
};

export default EnlaceTooltip;
