// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  width: 100%;
  height: 100%;
  background-color: #10202b;
  border-right: 1px solid #023154;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar:hover {
  background-color: #023154;
}

.sidebar .country-status {
  margin: 15px 0;
  position: relative;
  cursor: pointer;
}

.sidebar .country-status .icon-status {
  position: absolute;
  width: 14px;
  height: 14px;
  /* background-color: lawngreen; */
  border-radius: 4px;
  border: 1px solid white;
  top: -2px;
  right: -2px;
}

.sidebar .country-status img {
  object-fit: cover;
}

.sidebar .country-status .logo {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  filter: grayscale(100%);
}

.sidebar .logo.active {
  filter: grayscale(0%);
  border: 2px solid white;
}
`, "",{"version":3,"sources":["webpack://./components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,+BAA+B;EAC/B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,iCAAiC;EACjC,kBAAkB;EAClB,uBAAuB;EACvB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":[".sidebar {\n  width: 100%;\n  height: 100%;\n  background-color: #10202b;\n  border-right: 1px solid #023154;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.sidebar:hover {\n  background-color: #023154;\n}\n\n.sidebar .country-status {\n  margin: 15px 0;\n  position: relative;\n  cursor: pointer;\n}\n\n.sidebar .country-status .icon-status {\n  position: absolute;\n  width: 14px;\n  height: 14px;\n  /* background-color: lawngreen; */\n  border-radius: 4px;\n  border: 1px solid white;\n  top: -2px;\n  right: -2px;\n}\n\n.sidebar .country-status img {\n  object-fit: cover;\n}\n\n.sidebar .country-status .logo {\n  height: 40px;\n  width: 40px;\n  border-radius: 50%;\n  filter: grayscale(100%);\n}\n\n.sidebar .logo.active {\n  filter: grayscale(0%);\n  border: 2px solid white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
